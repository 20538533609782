// Created By ND

// Helper Imports
import AppUtils from "../../Utils/AppUtils";

let urlMode = "production"
// let urlMode = "developer";
// let urlMode = "local"

// for server url
let serverUrl = "";

if (urlMode === "production") {
  // for production
  serverUrl = "https://menuonline.com/";
  // serverUrl = "https://mycircle.net/";
} else if (urlMode === "developer") {
  // for developer
  serverUrl = AppUtils.isFromScheduledown()
    ? "https://beta.scheduledown.com/partners/reputation/"
    : "https://menspal.com/";
} else if (urlMode === "local") {
  // for local
  serverUrl = "http://localhost:5001/";
}

let salesUrl = "";

if (urlMode === "production") {
  // for production
  salesUrl = "https://sales.mycircle.net/";
} else if (urlMode === "developer") {
  // for developer
  salesUrl = "https://sales.menspal.com/";
} else if (urlMode === "local") {
  // for local
  salesUrl = "http://localhost:5001/";
}

const sentryServerUrl = ""; //"https://849fe9cc756847e3b7e0e83fcede890e@o1054969.ingest.sentry.io/6536258";

// const serverUrl = (process.env.REACT_APP_DEVELOPER_MODE === 'production') ? process.env.REACT_APP_PRODUCTION_SERVER_URL : ((process.env.REACT_APP_DEVELOPER_MODE === 'local') ? process.env.REACT_APP_LOCAL_SERVER_URL : process.env.REACT_APP_SERVER_URL);
// const sentryServerUrl = process.env.REACT_APP_SENETRY_SERVER_URL

const urlsHelper = {
  urlMode: urlMode,
  serverUrl: serverUrl,
  salesUrl: salesUrl,
  sentryServerUrl: sentryServerUrl,
  serverGraphQlUrl: serverUrl + "graphql",
  serverApiUrl: serverUrl + "api/",
  dsServerApiUrl: serverUrl + "api/ds/switchToDsPlatform/",
  publicRoutesUrl: "https://beta.scheduledown.com/sdcust",
};

export default urlsHelper;
