// Created By MK
import ColorsHelper from "../../../../Helper/Colors";

const dialogStyle = {
  dlgMain: {
    maxHeight: "100%",
    width: "100%",
    borderRadius: '1.5rem',
  },
  dlgIconBtn: {
    p: '4px',
    position: "absolute",
    right: '1rem',
    top: '1rem',
    width: '3rem',
  },
  titleTxt: {
    fontWeight: "600",
    color: '#F8851E',
    fontFamily: 'Basier Circle',
    fontSize: { xs: "30px", md: "40px" },
    lineHeight: { xs: "35px", md: "45px" },
    my: { xs: "8px", md: "12px" },
  },
  formText: {
    fontWeight: "600",
    fontFamily: 'Basier Circle',
    fontSize: { xs: "16px", md: "22px" },
    lineHeight: { xs: "20px", md: "28px" },
    mt: { xs: "4px", md: "8px" },
    mb: { xs: "2px", md: "4px" },
  },
  descTxt: {
    fontSize: { xs: "14px", md: "18px" },
    lineHeight: { xs: "16px", md: "20px" },
    fontWeight: 400,
    fontFamily: 'Basier Circle',
    color: '#5F5B58',
  },
  dlgDescTxt: {
    fontWeight: "400",
    fontFamily: 'Basier Circle',
    fontSize: { xs: "12px", md: "18px" },
    lineHeight: { xs: "14px", md: "22px" },
    maxWidth: '30rem',
  },
  textField: {
      background: '#fff',
      fontWeight: 400,
      fontFamily: 'Basier Circle',
      fontSize: "1rem",
      div: {
        marginRight: "0rem",
      },
      input: {
        fontWeight: 400,
        fontFamily: 'Basier Circle',
        fontSize: "1rem",
        padding: {xs: "0.5rem 0.75rem",md: "0.75rem 1rem"},
        color: ColorsHelper.blackClr,
      },
      fieldset: {
        borderRadius: "0.625rem",
        borderColor: "#D0D5DD !important",
      },
      textarea: {
        fontWeight: 400,
        fontFamily: 'Basier Circle',
        fontSize: "1rem",
        color: ColorsHelper.blackClr,
      },
  },
  fieldTitle: {
    fontWeight: "600",
    fontSize: { xs: "14px", sm: "16px" },
    color: ColorsHelper.blackClr,
    ml: '0.25rem',
    mt: '1rem',
    mb: '0.25rem',
  },
  orangeBtn: {
    padding: {xs: "0.5rem 0.5rem", sm: "0.5rem 0.75rem"},
    textTransform: "capitalize",
    background:  '#F87B1E',
    borderRadius: "0.5rem",
    fontFamily: 'Basier Circle',
    fontSize: {xs: '0.9rem', md: '1rem'},
    lineHeight: {xs: '0.938rem', md: '1.25rem'},
    color: ColorsHelper.whiteClr,
    "&:hover": {
      backgroundColor: '#F87B1E',
    },
    height: 'max-content',
  },
  dlgRBx: {
    width: "100%",
    maxWidth: { xs: "31.25rem", md: "auto" },
  },
  dlgTxt: {
    fontWeight: "700",
    fontSize: { xs: "1.875rem", sm: "2.188rem" },
    lineHeight: "3.125rem",
  },
  dlgTxt2: {
    fontWeight: "400",
    fontSize: "0.938rem",
    mb: 2,
  },
};
export default dialogStyle;
