// Created By ND
import * as React from 'react';
// // import { usePromiseTracker } from "react-promise-tracker";
import { promiseTrackerHoc } from "react-promise-tracker";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

//local files
// import './progressbar.css';

// for progressbar
export function LinearDeterminate(props: any) {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 200);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Box sx={{ width: '100%', position: 'absolute', zIndex: 3 }}>
            <LinearProgress variant="determinate" value={progress} sx={{
                height: "3px", backgroundColor: '#00000030',
                span: {
                    backgroundColor: '#000000'
                }
            }} />
        </Box>
    );
}

function ProgressbarV(props: any) {
    const { promiseInProgress } = props;
    // const { promiseInProgress } = usePromiseTracker();
    const [checkSpinner, setCheckSpinner] = React.useState<boolean>(false);

    // update user token data
    React.useEffect(() => {
        setCheckSpinner(promiseInProgress)
    }, [promiseInProgress]);

    return (
        ((promiseInProgress === true) && checkSpinner) ?
            (
                <React.Fragment>
                    {/* <progress className="top-pure-material-progress-linear" /> */}
                    <LinearDeterminate promiseInProgress={promiseInProgress} />
                </React.Fragment>
            )
            :
            null
    )
}

export default promiseTrackerHoc(ProgressbarV);
// export default React.memo(ProgressbarV);