// Created By MK

// React Imports
import React, { useEffect, useState, useRef } from 'react';

// MUI Imports
import Select from 'react-select';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { OutlinedInput } from '@mui/material';

// Helper Files
import countryCodes from '../../../../Assets/CountryCodes';
import ColorsHelper from '../../../../Helper/Colors';
import AppUtils from '../../../../Pages/MenuonlineWeb/Helpers/AppUtils';

const CustomMobileInput = (props: any) => {
    const [imgUrl, setImgUrl] = useState('');
    const prevStdCodeRef = useRef(props.stdcode);

    const colourStyles = {
        option: (styles: any) => ({
            ...styles,
            display: 'flex',
            backgroundColor: 'transparent',
            color: 'black',
        }),
        menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999,
            textAlign: 'center',
            maxHeight: '10rem',
            boxShadow: '0 0.125rem 0.25rem 0.0625rem $appDarkGryClr !important',
            backgroundColor: 'transparent',
            width: '6rem',
        }),
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formattedNum = AppUtils.mobileNumberFormate(event.currentTarget.value);
        event.currentTarget.value = formattedNum;
        if (props?.onChange) {
            props.onChange(event);
        }
    };

    const handleClickCodeItem = (val: { value: string; label: string; country_code_initials: string }) => {
        props.stdCodeChange(val)
    }

    useEffect(() => {
        if (props.stdcode !== prevStdCodeRef.current) {
            const newCountry = countryCodes.find(
                (val) => val?.value === props.stdcode?.value && val?.key === props.stdcode?.key
            );
            if (newCountry) {
                setImgUrl(newCountry.image);
                prevStdCodeRef.current = props.stdcode;
            }
        }
    }, [props.stdcode]);

    return (
        <FormControl fullWidth error={props.error} sx={props?.formSx}>
            <OutlinedInput
                fullWidth
                autoFocus={props.autoFocus}
                label={props.label}
                id={props.id}
                name={props.name}
                value={props.value}
                onChange={handleChange}
                placeholder={props.placeholder}
                disabled={props.disabled}
                inputProps={props.inputProps}
                sx={[{
                    borderRadius: '0.313rem',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: ColorsHelper.lightClr4,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: ColorsHelper.orangeClr,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: ColorsHelper.orangeClr,
                        borderWidth: 2,
                    },
                    fontFamily: 'Roobert, sans-serif !important',
                    fontWeight: 400,
                    fontSize: '1rem',
                }, props?.sx]}
                startAdornment={
                    <InputAdornment position='start'>
                        <Box
                            sx={{
                                backgroundColor: 'inherit',
                                '& >div': {
                                    '& >div': {
                                        width: '8.25rem',
                                        border: 'none',
                                        boxShadow: 'none !important',
                                        backgroundColor: 'inherit',
                                        '& >div': {
                                            '& >div': {
                                                display: 'flex',
                                                color: 'inherit',
                                                '& >input': {
                                                    color: 'inherit',
                                                },
                                            },
                                        },
                                    },
                                },
                            }}
                        >
                            <Select
                                isDisabled={props?.disabled}
                                menuPortalTarget={document.body}
                                id={props?.extraid ? props?.extraid : 'cus-mob-std-select'}
                                placeholder=''
                                value={props.stdcode}
                                formatOptionLabel={(option) => (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {
                                            (option?.image || imgUrl) &&
                                            <img
                                                src={option?.image ? option?.image : imgUrl}
                                                style={{ width: '1.5rem', height: '1.5rem' }}
                                                alt='Country Flags'
                                            />
                                        }
                                        <Box sx={{ ml: 0.5 }}>{option?.label}</Box>
                                    </Box>
                                )}
                                onChange={handleClickCodeItem}
                                options={countryCodes}
                                styles={colourStyles}
                                maxMenuHeight={120}
                            />
                        </Box>
                    </InputAdornment>
                }
            />
            {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
        </FormControl>
    );
};

export default CustomMobileInput;