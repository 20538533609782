// Created By ND
import React from "react";
import { ApolloClient, ApolloProvider } from "@apollo/client";
// InMemoryCache, ApolloLink
// import { createHttpLink } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

// custom functions
import { cache } from "./ApolloCache";

// custom functions
import AppUtils from "../../Utils/AppUtils";

// helper functions
import urlsHelper from "../../Helper/Urls";

export interface ApolloProps {
  children: React.ReactNode;
  pIP: string;
  updateToken: (val: boolean) => void;
}

const ApolloClientConfig = (props: ApolloProps) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  // only http url
  // const httpLink = createHttpLink({
  //     uri: urlsHelper.serverGraphQlUrl,
  // });

  // file upload url
  // const uploadLink = createUploadLink({
  //     uri: process.env.SERVER_URL,
  // });
  const uploadLink = createUploadLink({
    uri: urlsHelper.serverGraphQlUrl,
  });

  // const authMiddleware = new ApolloLink((operation, forward) => {
  //   // add the authorization to the headers
  //   operation.setContext(({ headers = {} }) => ({
  //     headers: {
  //       ...headers,
  //       authorization: localStorage.getItem('token') || null,
  //     }
  //   }));
  //   return forward(operation);
  // })

  // header authorization
  const authLink = setContext(async (_, { headers }) => {
    // _.variables = { ...(_.variables), ac_call: Math.random() }
    // get the authentication token from local storage if it exists
    const isApiName: string =
      typeof _.operationName !== "undefined" ? _.operationName : "";
    const token = !AppUtils.checkAuthorizationApi(isApiName)
      ? AppUtils.getUserToken()
      : "";
    const isPlatformObj = AppUtils.checkRouteMenus(location.pathname);
    const isPlatform = isPlatformObj?.currentPlatform;
    // if (!token) {
    //     token = ''
    // }

    //for timezone
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        pip: typeof props?.pIP !== "undefined" ? props?.pIP : "",
        plp: isPlatform,
        tz: timeZone,
      },
    };
  });

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    // console.log("graphQLErrors ::: ", graphQLErrors);
    // console.log("networkError ::: ", networkError);
    let errorCode = 0;
    if (networkError && typeof networkError !== "undefined") {
      const nEStr =
        typeof networkError !== "string"
          ? networkError?.toString()
          : networkError;
      const onlyCode = nEStr.replace(/[^0-9]/g, "");
      errorCode = Number(onlyCode);
    }
    // console.log("errorCode ::: ", errorCode);
    if (errorCode === 400) {
      // enqueueSnackbar(
      //   "Your current location is disabled. Please log in to access other locations.",
      //   {
      //     variant: "error",
      //   }
      // );

      // setTimeout(() => {
      //   localStorage.removeItem("MYCIRCLE");
      // }, 500);
      // const userData = AppUtils.getSessionItemData("MYCIRCLE");
      // if (AppUtils.checkFieldValue(userData, "id") === false) {
      //   if (
      //     AppUtils.hasPublicLayoutRoutesPermission(location.pathname) === false
      //   ) {
      //     if (window.location.pathname !== "/") {
      //       window.location.href = "/";
      //       return;
      //     }
      //   }
      // }
    }
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }: any) => {
        // message, locations, path
        if (
          AppUtils.checkDirectValue(extensions) &&
          extensions.code === "UNAUTHENTICATED"
        ) {
          if (window.location.pathname !== "/") {
            if (props?.updateToken) {
              props.updateToken(true);
              setTimeout(() => {
                props.updateToken(false);
              }, 100);
            }
          }
          return;
        } else if (
          AppUtils.checkDirectValue(extensions) &&
          extensions.code === "INTERNAL_SERVER_ERROR"
        ) {
          if (errorCode === 500) {
            if (window.location.pathname !== "/") {
              window.location.href = "/error";
              AppUtils.removeItemData("LSTATE");
            }
          }
          return;
        }
        // else if (extensions.code === "BAD_USER_INPUT") {
        //   return;
        // }
        else if (
          AppUtils.checkDirectValue(extensions) &&
          extensions.code === "PlatFormPermission"
        ) {
          if (
            AppUtils.hasPublicLayoutRoutesPermission(location.pathname) ===
            false
          ) {
            if (window.location.pathname !== "/") {
              window.location.href = "/";
            }
          }
          return;
        }
      });
    }
    // if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  //with header and handle cache
  const client = new ApolloClient({
    // link: authLink.concat(httpLink),
    // link: authLink.concat(authMiddleware).concat(errorLink).concat(uploadLink),
    link: authLink.concat(errorLink).concat(uploadLink),
    cache: cache,
    // cache: new InMemoryCache(),
    // defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' } },
    defaultOptions: {
      watchQuery: {
        notifyOnNetworkStatusChange: true,
        // fetchPolicy: "network-only",
        fetchPolicy: "cache-and-network",
        errorPolicy: "all",
        nextFetchPolicy(currentFetchPolicy) {
          if (
            currentFetchPolicy === "network-only" ||
            currentFetchPolicy === "cache-and-network"
          ) {
            // Demote the network policies (except "no-cache") to "cache-first"
            // after the first request.
            // return 'no-cache';
            // return 'cache-first';
            return "network-only";
          }
          // Leave all other fetch policies unchanged.
          return currentFetchPolicy;
        },
      },
      // watchQuery: {
      //   fetchPolicy: 'cache-and-network',
      //   errorPolicy: 'ignore',
      // },
      query: {
        fetchPolicy: "network-only",
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "all",
      },
    },
  });

  // without header
  // const client = new ApolloClient({
  //     uri: urlsHelper.serverGraphQlUrl,
  //     cache: new InMemoryCache()
  // });
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export default ApolloClientConfig;
