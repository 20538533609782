import React, { useEffect, useState, useContext } from 'react';
import { Audio, TelnyxRTCContext, useNotification } from '@telnyx/react-client';

function Phone() {
    const client = useContext(TelnyxRTCContext);
    const notification = useNotification();
    const [callerNo, setCallerNo] = useState('+18257976706');
    const [destination, setDestination] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();

        try {
            const call = client?.newCall({
                destinationNumber: destination,
                callerName: callerNo,
                callerNumber: callerNo,
                audio: true,
                video: false,
            });

            console.log('newCall: ', call);
        } catch (err) {
            console.error(err);
        }
    };

    const call = notification?.call;
    const callState = call?.state;

    useEffect(() => {
        console.log('phone callState:', callState);
    }, [callState]);

    return (
        <div style={{ marginTop: 10 }}>
            <strong>Voice call</strong>
            <form onSubmit={handleSubmit}>
                <>Caller  </>
                <input
                    name='caller_phone_number'
                    type='tel'
                    placeholder='+1-825-797-6706'
                    value={callerNo}
                    title="caller"
                    style={{ borderRadius: 5, borderWidth: 2, margin: 5, padding: 5 }}
                    onChange={(e) => setCallerNo(e.target.value)}
                />
                <br></br>

                <>Receiver  </>
                <input
                    name='destination_phone_number'
                    type='tel'
                    placeholder='+1-555-123-4567'
                    value={destination}
                    title="destination"
                    style={{ borderRadius: 5, borderWidth: 2, margin: 5, padding: 5 }}
                    onChange={(e) => setDestination(e.target.value)}
                />
                <button type='submit' style={{ borderRadius: 5, borderWidth: 2, margin: 5, padding: 5, backgroundColor: 'black', color: 'white' }}>Call</button>
                {call && call.state !== 'destroy' && (
                    <button type='button' style={{ borderRadius: 5, borderWidth: 2, margin: 5, padding: 5, backgroundColor: 'black', color: 'white' }}
                        onClick={() => call.hangup()}>
                        Hangup
                    </button>
                )}
            </form>

            <Audio stream={call?.remoteStream} />
        </div>
    );
}

export default Phone;
