import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import AppUtils from '../../../../Utils/AppUtils'
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Custom style
import opStyle from './OtherProducts.style'
import '../../index.css'

// Components Imports
import { HomeContext } from "../../Contexts";

const OtherProducts = (props: any) => {
    const [activeMenu, setActiveMenu] = useState(0)

    const theme = useTheme();

    //media query
    const sm = useMediaQuery(theme.breakpoints.down("md"));

    const { staticIcons } = props;

    const { setOpenGetFreeMenu } = useContext(HomeContext)

    useEffect(() => {
        window.onbeforeunload = () => null;

        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    const Menus = [
        {
            menuName: "Menu Online",
            menuImg: staticIcons.InstantMenuMSImg,
            icon: staticIcons.MenuOnlineModuleIcon,
            isFree: true,
            url: "/menu",
            isLocked: false,
            Description: "Digitalize your menu for easy online access by customers.",
            Benefits: "Enhance customer convenience and boost engagement by allowing them to view your menu anytime, anywhere."
        },
        {
            menuName: 'Digital Signage',
            menuImg: staticIcons.DigitalSignageMSImg,
            icon: staticIcons.DigitalSigange,
            isPro: true,
            url: "/",
            isLocked: false,
            Description: "Showcase offers and promotions on dynamic in-store screens.",
            Benefits: "Captivate customers’ attention with engaging visuals, promoting upsells and real-time offers effortlessly."
        },
        {
            menuName: 'Loyalty Punch Card',
            menuImg: staticIcons.LoyaltyMSImg,
            icon: staticIcons.Loyalty,
            isPro: true,
            url: "/loyalty-program",
            isLocked: false,
            Description: "Reward repeat customers with a digital loyalty program.",
            Benefits: "Drive customer retention and increase sales by incentivizing loyalty with redeemable rewards for frequent visits."

        },
        {
            menuName: "Marketing",
            menuImg: staticIcons.AutoResponderMSGif,
            icon: staticIcons.Marketing,
            isPro: true,
            url: `/dashboard`,
            isLocked: false,
            Description: "Reach more customers with targeted digital marketing campaigns.",
            Benefits: "Expand your restaurant’s reach and drive foot traffic with custom promotions tailored to attract new and repeat customers."
        },
        {
            menuName: 'Get More Reviews',
            menuImg: staticIcons.ReputationMSImg,
            icon: staticIcons.GetMoreReviews,
            isPro: true,
            url: `${AppUtils.isFromScheduledown() ? "/partners/reputation" : ""
                }/rep-dashboard`,
            isLocked: false,
            Description: "Encourage satisfied customers to leave positive online reviews.",
            Benefits: "Boost your restaurant's credibility and attract new diners by enhancing your online reputation with more positive reviews."

        },
        {
            menuName: "Guest Smart Wifi",
            menuImg: staticIcons.WifiMSImg,
            icon: staticIcons.SmartWifi,
            isPro: true,
            url: `${AppUtils.isFromScheduledown() ? "/partners/wifi" : ""
                }/wifi-dashboard`,
            isLocked: false,
            Description: "Offer free Wi-Fi to capture guest data for marketing.",
            Benefits: "Increase customer satisfaction while collecting valuable customer data to personalize future promotions."

        },
        {
            menuName: 'Reservation',
            menuImg: staticIcons.ReservationMSImg,
            icon: staticIcons.Reservations,
            isPro: true,
            url: "/reservation",
            isLocked: false,
            Description: "Allow customers to reserve tables online with ease.",
            Benefits: "Improve dining experience by minimizing wait times, and streamline reservations to better manage seating."

        },
        {
            menuName: 'POS Integration',
            menuImg: staticIcons.POSMSImg,
            icon: staticIcons.POS,
            isCommingSoon: true,
            url: "/main",
            isLocked: true,
            Description: "Integrate our system with your POS system.",
            Benefits: "Simplify order processing and reduce errors by keeping your online and in-store systems in sync."
        },
        {
            menuName: 'Online Ordering',
            menuImg: staticIcons.OnlineOrderMSImg,
            icon: staticIcons.OnlineOrder,
            isCommingSoon: true,
            url: "/main",
            isLocked: true,
            Description: "Enable customers to place orders directly from your website or app.",
            Benefits: "Increase sales and reduce reliance on third-party platforms by offering a direct ordering option that saves fees."
        },
    ];

    const onMenuClick = (menu: any) => {
        setOpenGetFreeMenu(true)
    }

    return (
        <Box sx={{ position: 'relative', height: '100%' }}>
            {!sm && <img style={{ width: '60%', position: 'absolute', left: 0, bottom: 0 }} src={staticIcons.OtherProductsBG} alt="" />}
            <Box sx={{ overflow: 'auto', height: '100%' }}>
                <Grid container sx={{ position: 'relative', zIndex: 10 }}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ p: { xs: '0 1rem', md: '1rem 0rem 1rem 3rem' }, maxWidth: '41rem', }}>
                            <Typography sx={[opStyle.greetingText, sm && { textAlign: 'center', fontSize: '1rem' }]}>{`Welcome 👋 `}</Typography>
                            {!sm && <Typography sx={[opStyle.descText]}>Let’s Boost up your customer satisfaction!</Typography>}
                            {sm && <Typography sx={[opStyle.descText, { textAlign: 'center', maxWidth: '100%', fontSize: '0.8rem' }]}>🚀 All-in-One Features to Elevate Your Restaurant</Typography>}
                            {Menus.map((menu, index) =>
                                <Box sx={[opStyle.imgCon, { display: activeMenu === index ? 'flex' : 'none' }]}>
                                    {index !== 0 && <img style={{ height: '100%', margin: 'auto', objectFit: 'contain' }} src={menu.menuImg} alt="" />}
                                    {index === 0 && <Box sx={{ position: 'relative', height: '88%', maxWidth: '100%', aspectRatio: '187/174' }}>
                                        <img style={{ height: '100%', position: 'absolute', left: '-3%' }} src={staticIcons.InstantMenuMSImg} alt="" />
                                        <img className="menuOnlineImg" style={{ height: '80%', position: 'absolute', bottom: '-6%', right: 0 }} src={staticIcons.InstantMenuGif} alt="" />
                                    </Box>}
                                </Box>)}
                            {!sm && <>
                                <Typography sx={[opStyle.bottomDescText, { mt: '2rem' }]}>{Menus[activeMenu]?.Benefits}</Typography>
                                {/* <Box sx={opStyle.bottomBox}>
                <Typography sx={[opStyle.otherText, { maxWidth: '18rem' }]}>See your company’s future with MyCircle Growth Plan.</Typography>
                <Button sx={opStyle.whiteBtn}>View Demo Account</Button>
            </Box> */}
                            </>}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ p: { xs: '0rem 0rem 0rem 1rem', md: '1rem 0rem 0rem 0rem' } }}>
                            {!sm && <Typography sx={opStyle.headText}> 🚀 All-in-One Features to Elevate Your Restaurant</Typography>}
                            <Box sx={{ ml: '-1rem', pl: '1rem', pt: { xs: '1rem', md: '2rem' }, height: { xs: 'calc(100vh - 26.2rem)', md: 'calc(100vh - 8rem)', lg: 'calc(100vh - 7rem)' }, overflow: 'auto', pr: { xs: '1rem', md: '3rem' } }}>
                                {Menus.map((menu, index) =>
                                    <Box
                                        onMouseEnter={() => {
                                            setActiveMenu(index)
                                        }}
                                        onClick={(ev) => {
                                            if (!sm) {
                                                onMenuClick(menu)
                                            }
                                        }}
                                        sx={[opStyle.menuCon, index === activeMenu ? opStyle.activeMenu : {}]} key={index}>
                                        <Box sx={{ display: 'flex', alignItems: 'start' }}>
                                            <img style={{ width: sm ? '1.5rem' : '2rem' }} src={menu.icon} alt="" />
                                            <Box sx={{ ml: sm ? '6px' : '14px' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography sx={[opStyle.menuTitle, sm && { lineHeight: '1rem' }]}>{menu.menuName}</Typography>
                                                    {menu?.isFree && <img style={{ height: '2rem', marginLeft: '0.5rem' }} src={staticIcons.Forever} alt="" />}
                                                    {menu?.isPro && <Box sx={opStyle.proBtn}>
                                                        <img src={staticIcons.Crown} alt="*" style={{ width: sm ? '10px' : '12px' }} />
                                                        <span style={{ marginLeft: sm ? '2px' : '4px' }}>{"Pro"}</span>
                                                    </Box>}
                                                    {menu?.isLocked && !menu.isCommingSoon && <img style={{ height: '1.4rem', marginLeft: '0.5rem' }} src={staticIcons.LockIcon} alt="" />}
                                                    {menu?.isCommingSoon && <img style={{ height: '1.2rem', marginLeft: '0.5rem' }} src={staticIcons.New} alt="" />}
                                                    {menu?.isCommingSoon && <img style={{ height: '1.2rem', marginLeft: '0.5rem' }} src={staticIcons.CommingSoon} alt="" />}
                                                </Box>
                                                <Typography sx={[opStyle.otherText, { mt: '0.25rem' }]}>{menu?.Description}</Typography>
                                            </Box>
                                        </Box>
                                        {!menu?.isLocked && <Button
                                            onClick={(event) => {
                                                event.stopPropagation()
                                                if (index === activeMenu)
                                                    onMenuClick(menu)
                                            }}
                                            sx={[opStyle.blackBtn, { ml: '0.5rem', opacity: index === activeMenu ? 1 : 0 }]}>
                                            Try Now <img style={{ width: sm ? '10px' : '12px', marginLeft: sm ? '4px' : '6px' }} src={staticIcons.ArrowRightWhite} alt="" />
                                        </Button>}
                                    </Box>)}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default OtherProducts