// Created By MK

const AppUtils = {
    mobileNumberFormate: (value: string) => {
        let mNumString = value;
        let formatted = "";
        if (typeof mNumString !== "undefined" && mNumString !== null) {
            mNumString = mNumString.replace(/[^0-9]/g, "");
            let block1 = "";
            let block2 = "";
            let block3 = "";

            //for set number formate 3-3-4
            block1 = mNumString.substring(0, 3);
            if (block1?.length === 3 && mNumString?.length > 3) {
                block1 = "(" + block1 + ")-";
            }
            block2 = mNumString.substring(3, 6);
            if (block2?.length === 3 && mNumString?.length > 6) {
                block2 = block2 + "-";
            }
            block3 = mNumString.substring(6, 12);
            if (block3?.length === 4) {
                block3 = block3 + "";
            }

            formatted = block1 + block2 + block3;
        }

        return formatted;
    }
};

export default AppUtils;