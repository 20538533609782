import React, { createContext, useRef, useState } from "react";

export const HomeContext = createContext<any>({});

type HomeContextProviderProps = {
  children: React.ReactNode
}

const HomeContextProvider = ({ children }: HomeContextProviderProps) => {

    const homeRef = useRef<null | HTMLElement>(null);
    const aboutRef = useRef<null | HTMLElement>(null);
    const faqRef = useRef<null | HTMLElement>(null);
    const [openGetFreeMenu, setOpenGetFreeMenu] = useState(false)
  
  return (
    <HomeContext.Provider value={{
        homeRef, aboutRef, faqRef,
        openGetFreeMenu, setOpenGetFreeMenu
    }}>
      {children}
    </HomeContext.Provider>
  )
}

export default HomeContextProvider;