// created by Israil Gulzar
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// custom styles
import splashPageStyle from "./SplashPage.style";

const MandatoryBox = (props: any) => {
  const change = (opt: boolean) => {
    props.handleChange(opt);
  };

  const setSwitchType = (switchType: string) => {
    const activeType = switchType === (props?.value ? "Yes" : "No");
    return (
      <Box
        key={switchType}
        sx={[
          activeType ? splashPageStyle.spASwi : splashPageStyle.spInAwi,
          props?.disabled && {
            cursor: "default",
            bgcolor: activeType ? "#AAAAAA" : "white",
          },
        ]}
        onClick={() => {
          if (props?.disabled) {
            return;
          } else {
            if (props?.data?.is_checked) {
              change(!props.value);
            }
          }
        }}
      >
        <Typography
          variant="caption"
          sx={[
            activeType
              ? splashPageStyle.spASwiTxt
              : splashPageStyle.spInASwiTxt,
            props?.disabled && { cursor: "default" },
          ]}
        >
          {switchType}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={[
          splashPageStyle.spSwitch,
          props.sx,
          props?.disabled && { cursor: "default" },
        ]}
      >
        {["Yes", "No"].map((btnText: string) => setSwitchType(btnText))}
      </Box>
    </>
  );
};

export default MandatoryBox;
