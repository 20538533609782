// Created By ND
import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

// custom style
import custTFStyles from "./CusTextField.styles";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    componentsProps={{
      tooltip: {
        sx: {
          fontFamily: "Montserrat",
          fontWeight: "500",
        },
      },
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function CustTextField(props: any) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props?.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div style={props.rootdivclass ? props.rootdivclass : {}}>
      {/* moded if no label passed then removes extra span from structure */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom:
            props.txttitle != "" ||
            (typeof props.showI !== "undefined" &&
              props?.showI !== null &&
              props?.showI !== "") ||
            props.required === true ||
            (typeof props.showIT !== "undefined" &&
              props?.showIT !== null &&
              props?.showIT !== "")
              ? "0.25rem"
              : "0rem",
        }}
      >
        {props.txttitle != "" && (
          <Typography variant="caption" sx={custTFStyles.fieldTitle}>
            {props.txttitle}
          </Typography>
        )}
        {typeof props.showI !== "undefined" &&
        props?.showI !== null &&
        props?.showI !== "" ? (
          <img style={custTFStyles.iIcon} src={props?.showI} alt="i" />
        ) : null}

        {typeof props.showIT !== "undefined" &&
        props?.showIT !== null &&
        props?.showIT !== "" ? (
          <BootstrapTooltip title={props?.showITx}>
            <IconButton sx={[custTFStyles.tooltip]}>
              <img style={custTFStyles.iIcon} src={props?.showIT} alt="i" />
            </IconButton>
          </BootstrapTooltip>
        ) : null}

        {/* Checking if reaquired symbol needs to rendered or not */}
        <Typography variant="caption" sx={custTFStyles.requiredTx}>
          {props.required === true ? "*" : ""}
        </Typography>
      </div>

      {/* Checking if optional text rendered or not */}
      {/* {props.optional != "" && (
        <Typography variant="caption" sx={custTFStyles.fieldTitle}>
          {props.optional}
        </Typography>
      )} */}

      {(typeof props.isshow === "undefined" ||
        (typeof props.isshow !== "undefined" && props.isshow === true)) && (
        <TextField
          // {...props}
          id={props.id}
          margin={props.margin}
          fullWidth={props.fullWidth}
          placeholder={props.placeholder}
          type={props.type}
          size={props.size}
          value={props.value}
          onBlur={props.onBlur}
          onFocus={props?.onFocus}
          error={props.error}
          helperText={props.helperText}
          inputProps={props.inputProps}
          InputProps={props.InputProps}
          inputRef={props.inputRef}
          className={props.className}
          disabled={props?.disabled}
          multiline={props?.multiline}
          minRows={props?.minRows}
          rows={props?.rows}
          autoFocus={
            props?.autoFocus && props?.autoFocus !== undefined ? true : false
          }
          sx={{
            ...(typeof props?.isMob !== "undefined" && props?.isMob === true
              ? custTFStyles.fieldMobInput
              : custTFStyles.fieldInput),
            ...props.sx,
            ...(props?.isReseller && {
              "& .MuiInput-underline:after": {
                borderBottomColor: "black",
              },
              "& .MuiInputLabel-root": {
                color: "black",
              },
              "& .MuiFormLabel-root": {
                color: "black",
              },
              "& .MuiInputBase-input": {
                padding: "0 !important",
              },
            }),
          }}
          required={
            typeof props.required !== "undefined" ? props.required : false
          }
          onChange={handleChange}
          autoComplete={props?.autoComplete}
          variant={props?.variant ?? "outlined"}
        />
      )}
    </div>
  );
}
export default CustTextField;
