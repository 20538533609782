// Created By ND

// helper functions
import ColorsHelper from "../../Helper/Colors";
import FontsHelper from "../../Helper/Fonts";

const custTFComman = {
  fieldDiv: {
    div: {
      marginRight: "0rem",
    },
    input: {
      ...FontsHelper.font400,
      fontSize: "1rem",
      padding: "1rem 1rem",
      color: ColorsHelper.blackClr,
    },
    fieldset: {
      borderRadius: "0.625rem",
      borderColor: ColorsHelper.blackClr + "!important",
    },
    textarea: {
      ...FontsHelper.font400,
      fontSize: "1rem",
      color: ColorsHelper.blackClr,
    },
  },
};

const custTFStyles = {
  fieldTitle: {
    ...FontsHelper.font500,
    fontSize: "0.938rem",
    marginLeft: "0.5rem",
    cursor: "default",
  },
  requiredTx: {
    ...FontsHelper.font600,
    fontSize: "1.5rem",
    color: "red",
    position: "relative",
    top: "0.1rem",
    left: "0",
    cursor: "default",
    lineHeight: "1",
  },
  fieldMobInput: {
    div: {
      paddingLeft: "0rem",
      ...custTFComman.fieldDiv,
    },
  },
  fieldInput: {
    div: {
      ...custTFComman.fieldDiv,
    },
  },
  iIcon: {
    marginLeft: "0.625rem",
    width: "0.625rem",
  },
  tooltip: {
    p: "0 !important",
  },
};

export default custTFStyles;
