// Created By ND
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

//helper files
import custCheckBoxStyle from "./CustCheckBox.style";

function CustCheckBox(props: any) {
  const handleChange = (event: React.SyntheticEvent, checked: boolean) => {
    if (props?.onChange) {
      props.onChange(event, checked);
    }
  };

  return (
    <Box sx={custCheckBoxStyle.container}>
      <Checkbox
        {...props}
        checked={props.value}
        indeterminate={false}
        onChange={handleChange}
        defaultChecked={props.defaultChecked}
        disabled={props?.disabled}
        sx={[
          custCheckBoxStyle.checkBox,
          props?.disabled && custCheckBoxStyle.checkBoxDisabled,
          props?.sx,
        ]}
        label={props?.label}
        inputRef={props?.inputRef}
      />
      <Typography
        variant="caption"
        role="button"
        sx={[custCheckBoxStyle.label, props?.lblSx && props?.lblSx]}
        onClick={(e: any) => {
          e = { ...e, ...{ target: { checked: !props.value } } };
          handleChange(e, !props.value);
        }}
      >
        {props?.label}
        <Typography component="span" sx={custCheckBoxStyle.redStart}>
          {props?.required === true ? "*" : ""}
        </Typography>
      </Typography>
    </Box>
  );
}
export default CustCheckBox;
