// Created By ND
import React from "react";
import { useLocation } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Box from "@mui/system/Box";

// custom component
import Spinner from "../Components/Spinner/spinner.component";

// custom functions
import AppUtils from "../Utils/AppUtils";
import RoutesConfig from "../Routes/routesConfig";

//custom style
import ltsStyles from "./Layout.styles";

// lazy loading components
const Sidebar = React.lazy(() => import("./Sidebar/Sidebar.component"));

// props interface
interface ObjectProps {[key: string]: string}
interface ChildrenProps {
  sdvRef: any;
  ConstantsHelper: ObjectProps;
  ColorsHelper: ObjectProps;
  staticIcons: ObjectProps;
}

function Layout(props: any) {
  const location = useLocation();
  const RouteConfiguFC = (routeProps: ChildrenProps) => { return (<RoutesConfig {...routeProps} />) }

  return (
    <SnackbarProvider
      maxSnack={1}
      autoHideDuration={2000}
      preventDuplicate={true}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box id="layout" sx={ltsStyles.rootDiv}>
        <Box
          sx={
            AppUtils.hasPublicLayoutRoutesPermission(location.pathname)
              ? ltsStyles.layoutDiv
              : ltsStyles.layoutAuthDiv
          }
        >
          <React.Suspense fallback={<Spinner />}>
            <Sidebar {...props} pathLocation={location.pathname} Children={RouteConfiguFC} />
            {/* <Sidebar pathLocation={location.pathname} >
              <RoutesConfig />
            </Sidebar> */}
          </React.Suspense>
        </Box>
      </Box>
    </SnackbarProvider>
  );
}

export default React.memo(Layout);