// Helper Functions
import ColorsHelper from "../../../../Helper/Colors";
import CommanStyles from "../../../../Helper/CommanStyles";
import FontsHelper from "../../../../Helper/Fonts";

const opStyle = {
    headCon: {
        p: {xs: '0.5rem', md: '0.5rem 3rem'}, 
        borderBottom: {xs: 'none', md: '1px solid #D4D4D4'},
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative', 
        zIndex: 10
    },
    btnsCon: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    text: {
        ...FontsHelper.font500,
        color: ColorsHelper.secondaryColor,
        fontSize: '0.938rem'
    },
    iconButtons: {
        borderRadius: {xs: '0.35rem', md: '0.5rem'},
        border: '1px solid #BDC9C9',
        p: '4px',
        height: {xs: '2rem',md: '2.25rem'}, 
        width: {xs: '2rem',md: '2.25rem'}
    },
    orangeBtn: {
        padding: "0.45rem 0.75rem",
        textTransform: "capitalize",
        background:  'linear-gradient(270deg, #F86A1E 0%,  #F8931E 100%)',
        borderRadius: "0.5rem",
        fontFamily: FontsHelper.TitleFontFamily,
        fontSize: '0.938rem',
        color: ColorsHelper.whiteClr,
        "&:hover": {
          backgroundColor: 'linear-gradient(270deg, #F86A1E 0%,  #F8931E 100%)',
        },
      },
    blackBtn: {
        padding: "0.35rem 0.75rem",
        textTransform: "capitalize",
        background:  ColorsHelper.blackClr,
        borderRadius: {xs: '0.35rem',md: "0.5rem"},
        fontFamily: FontsHelper.TitleFontFamily,
        fontSize: {xs: '0.6rem',md: "0.8rem"},
        color: ColorsHelper.whiteClr,
        "&:hover": {
          backgroundColor: ColorsHelper.blackClr,
        },
        whiteSpace: 'nowrap',
        height: 'max-content'
      },
    borderBtn: {
        padding: "0.45rem 0.75rem",
        textTransform: "capitalize",
        background:  'transparent',
        border: '1px solid #BDC9C9',
        borderRadius: "0.5rem",
        fontFamily: FontsHelper.TitleFontFamily,
        fontSize: '0.938rem',
        color: ColorsHelper.blackClr,
        "&:hover": {
          backgroundColor: 'transparent',
          border: '1px solid #BDC9C9'
        },
      },
      greetingText: {
        ...FontsHelper.font600,
        fontSize: '1.15rem',
        lineHeight: '1.25rem',
      },
      headText: {
        ...FontsHelper.font600,
        fontSize: '1.2rem',
        // lineHeight: '1.25rem',
      },
      descText: {
        ...FontsHelper.font400,
        fontSize: '0.9rem',
        color: '#1B140E',
        maxWidth: '18rem',
      },
      bottomDescText: {
        ...FontsHelper.font400,
        fontSize: '0.9rem',
        textAlign: 'center',
        maxWidth: '30rem',
        margin: 'auto',
      },
      otherText: {
        ...FontsHelper.font400,
        fontSize: {xs: '0.625rem', md:'0.85rem'},
        lineHeight: {xs: '0.8rem', md:'1rem'},
      },
      bottomBox: {
        background:  'linear-gradient(270deg, #FFE0CA 0%,  #FFDCB9 100%)',
        width: '100%',
        // maxWidth:'31rem',
        borderRadius: '0.625rem',
        display: 'flex',
        justifyContent: 'space-between',
        p: '1rem 1rem',
        mt: '2rem'
      },
      whiteBtn: {
        padding: "0.35rem 0.7rem",
        textTransform: "capitalize",
        background:  ColorsHelper.whiteClr,
        borderRadius: "0.5rem",
        fontFamily: FontsHelper.TitleFontFamily,
        fontSize: '0.75rem',
        color: ColorsHelper.blackClr,
        "&:hover": {
          backgroundColor: ColorsHelper.whiteClr,
        },
      },
      menuTitle: {
        ...FontsHelper.font600,
        fontSize: {xs: '0.825rem',sm: '0.9rem', md:'1.1rem'},
      },
      menuCon: {
        p: '0.65rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '0.5rem',
        border: {xs: '0.1rem solid #DFDFDF', md: 'none'},
        mb: {xs: '0.5rem', md: '0'},
      },
      activeMenu: {
        boxShadow: '0 2px 15px rgba(0, 0, 0, 0.2)',
        border: {xs: '0.1rem solid #F8851E', md: 'none'},
      },
      proBtn: {
        ml: '0.5rem',
        background: '#F8931E', 
        borderRadius: '4px', 
        display: 'flex', 
        alignItems: 'center', 
        p: '3px 6px',
        ...FontsHelper.font600,
        fontSize: {xs: '0.65rem', sm: '0.75rem'},
        color: ColorsHelper.whiteClr
      }, 
      imgCon:{
        height: { xs: 'calc(100vh - 25rem)', md: 'calc(100vh - 18rem)' }, width: '100%', position: 'relative', my: '1rem', justifyContent: 'center', overflow: 'hidden' 
      }
};

export default opStyle;
