import React from 'react';

// ** Mui Imports
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

interface Product {
    id: number;
    image: string;
    src: string;
    title: string;
}

const OfficeSupplies: React.FC = (props: any) => {
    // Props
    const { staticIcons } = props

    const products: Product[] = [
        {
            id: 1,
            image: staticIcons.officeSupplies1,
            src: 'https://www.amazon.com/NTAG215-Compatible-TimesKey-Android-Enabled/dp/B075CL5HGP/ref=sr_1_6?crid=1RUPKQILYFNF0&dib=eyJ2IjoiMSJ9.g-KNxUTuD09ZKCtndBXKo8zSdSZ9ivaR6tT1FufuQfTLFu462X_NFz-U8M4KqL-FTOzL7vah20RxYk5j2L0jPAu05ldGiWjNWidfCEn4A6EkHMYRhhwdCEwSUquZoKckukyhrR90f9w-iEsClHj_xtFUoABn0ZNfTmdcGZ9dCKewNT0S2G-RPkX-1jlYsyL5fRq_SJAELxLzPzP3wEd7vYVaWnUtyAzOwpwYbZ-rXdPaKhwwVLDCwKexWevw1zhEE5msLAFNyV28lRv-Nr1ICAz83QbRCgwhcIkr_cpRqgE.VZNNOuLrtZgLzSHDGWy6LADMHgVRbgqYjn0PRJHCKEQ&dib_tag=se&keywords=nfc&qid=1705691058&sprefix=nfc%2Caps%2C137&sr=8-6&th=1',
            title: 'NFC Cards',
        },
        {
            id: 2,
            image: staticIcons.officeSupplies2,
            src: 'https://www.amazon.com/BUTUNITA-Adjustable-Supermarket-Horizontal-Displayed/dp/B0BQMV9DXD/ref=sr_1_13_sspa?crid=1B3QUQFNWMU5W&dib=eyJ2IjoiMSJ9.jrW1SglwhKtON_gtlPFmLNrV5XY-p4rOZkWTKTtcf7hpeGXMNL-Jjpk9BDWmkgR2FCCxbf28EPfDo6IPCgNUKzt8E2T5TsqJfl9YQ2htsoUAxNEogvoh4FVffH-J2gLPWFwXXvzONqOZnjxhzalJ-RnbqZnQsQxDmCZXqklTb-f9mshoJKbT265VLO40brwsCLSOjeWWkBQ874PcW7tOMsKPIiUWuEK2DwNdpxxq9ZvGwbW3OHu4xxOuRYTc62BHJwnmXdSJDtmyR3FgMBIV1vbFEWBA9mraIx7X1TibG6c.FL_F0Qp7YnYdBXmdBHPBRvHrREHxJ1J6Wj8C2EpQHEg&dib_tag=se&keywords=table%2Bsmall%2Bsign%2Bholder&qid=1705690786&sprefix=table%2Bsmall%2Bsign%2Bholder%2Caps%2C123&sr=8-13-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9tdGY&th=1',
            title: 'Sign Holder Stand',

        },
        {
            id: 3,
            image: staticIcons.officeSupplies3,
            src: 'https://www.amazon.com/Acrylic-Sign-Holder-Inch-Pack/dp/B0CCHMD9TC/ref=sr_1_8?crid=NI18JAI5IJL5&dib=eyJ2IjoiMSJ9.M-1oiTsy6s0r1d_AtXN5H0imz4ZaHyBP3HqtFetNZw7IcaTeVkC6desj7VbC1MEU8mYVyNI13ms1cVGOVSrYzFOkfoWKk9r_SagRXJ2W0a446qqfHOX-49lUK7OFbpK2An11QmoAXsTDWJoipV0UnGbuHZRzY5pE_6QW1xnAPO6tWkcTuyvaKYZjgwPR4hKwzhywfxIloS3lD69WaOeNx534ZCVZf2wJB0YknRWWyLuGYqUC_VjH490xA5WOuJEvS1wASZGjgpA6m-bSMJ5u0cKlMaCvRAslJdxlFQC6i54.53Gm15VKZ8Wt5ek5pCP1hl13jpRkRii45fkdUoE0qX4&dib_tag=se&keywords=plastic%2Bsign%2Bholder%2B8.5%2Bx%2B11%2Bstanding&qid=1705693507&sprefix=sign%2Bholder%2B8.5%2Caps%2C128&sr=8-8&th=1',
            title: 'Store Sign Holders',

        },
    ];

    return (
        <Grid container spacing={3} sx={{ p: '1rem' }}>
            {Array.isArray(products) && products?.length > 0 && products?.map((product: Product, index: number) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                    <Card>
                        <Box
                            sx={{
                                width: '100%',
                                height: '17.75rem',
                                borderRadius: '.625rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                overflow: 'hidden'
                            }}
                        >
                            <Link href={product?.src} target="_blank">
                                <CardMedia
                                    component="img"
                                    height="140"
                                    alt={`Product ${index + 1}`}
                                    image={product?.image}
                                    sx={{ height: 'auto', width: '100%', objectFit: 'cover' }}
                                />
                            </Link>
                        </Box><Divider />
                        <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: '500', px: '1rem', py: '0.5rem' }}>{`${product?.title}`}</Typography>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default OfficeSupplies;
