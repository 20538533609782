import React, { useState } from 'react';
import { TelnyxRTCProvider } from '@telnyx/react-client';
import ClientStatus from './ClientStatus';
import CallLog from './CallLog';
import Phone from './Phone';
import VideoCall from './VideoCall';

const AudioVideoCall = () => {
  const [isLoginToken, setIsLoginToken] = useState(true);
  const [credential, setCredential] = useState({
    login_token: 'KEY017F631211CE6BE75EFECB1E7E67B44E_OWzvLvpldglPVogP8EVe6s',
    login: 'noshin72985',
    password: 'hjyzgDML',
  });
  const [loginTokenValue, setLoginTokenValue] = useState(credential.login_token);
  const [loginValue, setLoginValue] = useState(credential.login);
  const [passValue, setPassValue] = useState(credential.password);
  const [isAudioOnly, setIsAudioOnly] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isLoginToken) {
      setCredential({ login_token: loginTokenValue });
    }
    else {
      setCredential({ login: loginValue, password: passValue });
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <TelnyxRTCProvider credential={credential}>
        <div>
          <label>
            <input
              type='checkbox'
              checked={isLoginToken}
              onChange={(e) => setIsLoginToken(e.target.checked)}
              style={{ borderRadius: 5, borderWidth: 2, margin: 5, padding: 5, marginTop: 10 }}
            />
            Login with Token
          </label>
          <div style={{ marginTop: 10, marginBottom: 10 }}>

            {isLoginToken ?
              <form onSubmit={handleSubmit}>
                <label>
                  Login token:
                  <input
                    style={{ borderRadius: 5, borderWidth: 2, margin: 5, padding: 5 }}
                    name='telnyx_login_token'
                    type='password'
                    value={loginTokenValue}
                    onChange={(e) => setLoginTokenValue(e.target.value)}
                  />
                </label>
                <button type='submit' style={{ borderRadius: 10, borderWidth: 2, margin: 5, padding: 5, backgroundColor: 'black', color: 'white' }}>Login with token</button>
              </form>
              :
              <form onSubmit={handleSubmit}>
                <label>
                  Login Username and password:
                  <br></br>
                  <input
                    style={{ borderRadius: 5, borderWidth: 2, margin: 5, padding: 5 }}
                    name='telnyx_login'
                    placeholder='Username'
                    type='password'
                    value={loginValue}
                    onChange={(e) => setLoginValue(e.target.value)}
                  />
                  <br></br>
                  <input
                    style={{ borderRadius: 5, borderWidth: 2, margin: 5, padding: 5 }}
                    name='telnyx_password'
                    placeholder='Password'
                    type='password'
                    value={passValue}
                    onChange={(e) => setPassValue(e.target.value)}
                  />
                </label>
                <br></br>
                <button type='submit' style={{ borderRadius: 10, borderWidth: 2, margin: 5, padding: 5, backgroundColor: 'black', color: 'white' }}>Login with user</button>
              </form>
            }
          </div>
        </div>

        <ClientStatus />
        <CallLog />

        <div>
          <label>
            <input
              type='checkbox'
              checked={isAudioOnly}
              onChange={(e) => setIsAudioOnly(e.target.checked)}
              style={{ borderRadius: 5, borderWidth: 2, margin: 5, padding: 5, marginTop: 10 }}
            />
            Audio-only call
          </label>

          {isAudioOnly ? <Phone /> : <VideoCall />}
        </div>
      </TelnyxRTCProvider>
    </div>
  );
};

export default AudioVideoCall;
