// Updated By ND
import FontsHelper from "../Fonts";

const commanMultiStyles = {
  btnStyle: {
    padding: "0.825rem 0.875rem",
    textTransform: "capitalize",
    borderRadius: "0.625rem",
    ...FontsHelper.font500,
    fontSize: "1rem",
  },
};

const CommanStyles = {
  dblock: {
    display: "block",
  },
  dnone: {
    display: "none",
  },
  dflex: {
    display: "flex",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
  },
  flexAlignContainer: {
    display: "flex",
    alignItems: "center",
  },
  flexColunmContainer: {
    display: "flex",
    flexDirection: "column",
  },
  flexRowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  flexCenterItColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  flexCenterItRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  centerIt: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  centerItColumn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  centerItRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  flexStartIt: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },
  flexStartColumnIt: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    flexDirection: "column",
  },
  flexEndIt: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  flexEndItColumn: {
    display: "flex",
    justifuContent: "center",
    alignItems: "end",
    flexDirection: "column",
  },
  flexRowsBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  flexRowsAround: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row",
  },
  flexColAround: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
  },
  flexColBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
  },
  actionBtn: {
    ...commanMultiStyles.btnStyle,
    width: { xs: "100%", sm: "100%", md: "8rem", xl: "8rem" },
  },
  actionFullWBtn: {
    ...commanMultiStyles.btnStyle,
    width: "100%",
  },
  flexRowsEvenly: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
};

export default CommanStyles;
