import ColorsHelper from "../../Helper/Colors";
import CommanStyles from "../../Helper/CommanStyles";
import FontsHelper from "../../Helper/Fonts";

const custCheckBoxStyle = {
  container: {
    ...CommanStyles.flexStartIt,
    alignItems: "center",
  },
  checkBox: {
    color: ColorsHelper.blackClr,
    "&.Mui-checked": {
      color: ColorsHelper.blackClr,
    },
  },
  checkBoxDisabled: {
    color: ColorsHelper.blackClr,
    "&.Mui-checked": {
      color: ColorsHelper.grey3,
    },
  },
  label: {
    ...FontsHelper.font500,
    fontSize: "0.938rem",
    lineHeight: "1rem",
    wordBreak: "break-word",
  },
  redStart: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: "red",
    lineHeight: "0",
    fontSize: "1.5rem",
    position: "relative",
    top: "0.1rem",
    left: "0",
    cursor: "default",
  },
};

export default custCheckBoxStyle;
