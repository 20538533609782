// Created By ND
import React, { Dispatch, SetStateAction } from "react";

// custom functions
import { AlertMsg, User } from "../global.d";
import AppUtils from "../Utils/AppUtils";
import routes from "../Routes/routesConfig";

// interface
type AppContextProvideProps = {
  children: React.ReactNode;
};

interface AuthState {
  routes?: any;
  isAuthenticated?: Boolean | null;
  setIsAuthenticated: Dispatch<SetStateAction<Boolean | null>>;
  auth?: User | null;
  setAuth: Dispatch<SetStateAction<User | null>>;
  login?: (User: User) => void;
  logout?: () => void;
  snackbarObj?: AlertMsg;
  setSnackbarObj?: any;
  showmsg?: (snackbarObj: AlertMsg) => void;
  updateCustOptionKey: string;
  setUpdateCustOptionKey: React.Dispatch<React.SetStateAction<string>>;
}

export const AppContext = React.createContext({} as AuthState);

export const AppContextProvider = ({ children }: AppContextProvideProps) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<Boolean | null>(
    AppUtils.checkUserToken()
  );
  const [auth, setAuth] = React.useState<User | null>({
    email_id: "test@gmail.com",
    password: "1234",
  });
  const [snackbarObj, setSnackbarObj] = React.useState<AlertMsg>({
    open: false,
    message: "",
    type: "error",
  });
  const [updateCustOptionKey, setUpdateCustOptionKey] = React.useState<string>(
    AppUtils.generateRandomString()
  );

  // login events
  const login = (User: User) => {
    setAuth(User);
  };
  const logout = () => {
    setAuth(null);
  };

  // show and hide snackbar
  const showmsg = (snackbar: AlertMsg) => {
    setSnackbarObj(snackbar);
  };

  return (
    <AppContext.Provider
      value={{
        routes,
        isAuthenticated,
        setIsAuthenticated,
        auth,
        setAuth,
        login,
        logout,
        snackbarObj,
        showmsg,
        setSnackbarObj,
        updateCustOptionKey,
        setUpdateCustOptionKey,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
// export default AppContext;
