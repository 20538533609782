// Updated By Ayush.N

// helper files
import ColorsHelper from "../../../../Helper/Colors";
import FontsHelper from "../../../../Helper/Fonts";
import CommanStyles from "../../../../Helper/CommanStyles";

const RepStyle = {
  grdMainContainer: {
    ...CommanStyles.dflex,
    flexDirection: "row-reverse",
    height: { xs: "auto", md: "100%" },
  },
  grdCon: {
    px: { xs: "1.5rem", md: "2rem" },
    mt: "0.5rem",
  },
  titleCon: {
    pl: "0.5rem",
    mb: "1rem",
    alignItems: "center",
  },
  closeCon: {
    alignItems: "flex-start",
    justifyContent: "flex-end",
    position: "relative",
  },
  closeBtn: {
    position: "absolute",
    top: "0",
    right: "0",
    p: "0.3rem",
  },
  rightGrd: {
    height: "inherit",
    ...CommanStyles.dflex,
    flexDirection: "column",
  },
  mainBox: {
    height: "100%",
    p: { xs: "0.8rem", sm: "1.3rem" },
  },
  closeBox: { width: "3rem" },
  preBtn: {
    maxHeight: "3.25rem",
    padding: "0.75rem 1rem",
    textTransform: "capitalize",
    borderRadius: "0.625rem",
    fontFamily: FontsHelper.fonts,
    fontSize: "1rem",
    mx: "0.5rem",
    backgroundColor: ColorsHelper.whiteClr,
    color: ColorsHelper.blackClr,
    "&:hover": {
      backgroundColor: ColorsHelper.whiteClr,
    },
    minWidth: "9rem",
  },
  nextBtn: {
    maxHeight: "3.25rem",
    padding: "0.75rem 1rem",
    mx: "0.5rem",
    textTransform: "capitalize",
    backgroundColor: ColorsHelper.blackClr,
    borderRadius: "0.625rem",
    fontFamily: FontsHelper.TitleFontFamily,
    fontSize: "1rem",
    color: ColorsHelper.whiteClr,
    "&:hover": {
      backgroundColor: ColorsHelper.blackClr,
    },
    minWidth: "9rem",
  },
  rSpBtn: {
    backgroundColor: `${ColorsHelper.white7} !important`,
  },
  rWSpBtn: {
    backgroundColor: ColorsHelper.blackClr,
  },
  headBox: {
    mt: { xs: "0", md: "0.5rem" },
    px: { xs: "0.8rem", md: "1.3rem" },
  },
  titleBox: {
    ...CommanStyles.flexRowsBetween,
    overflowX: "auto",
    overflowY: "hidden",
  },
  fieldHeadGrdCntnr: { mt: "1rem", ml: "1rem", width: "100%" },
  repDesc: {
    ...FontsHelper.font500,
    fontStyle: "normal",
    fontSize: "0.938rem",
    color: ColorsHelper.blackClr,
    cursor: "default",
    lineHeight: "1rem",
  },
  fieldHeadDivExtStl: { pl: { xs: "2rem", md: "2.5rem" } },
  stepIndicator: {
    ...CommanStyles.dflex,
    justifyContent: "flex-start",
    alignItems: "baseline",
    cursor: "default",
    ml: 1,
  },
  stepNumberBox: {
    height: "2rem",
    width: "2rem",
    backgroundColor: ColorsHelper.grey2,
    borderRadius: "0.5rem",
    ...CommanStyles.centerIt,
    mb: "0.5rem",
  },
  stepActive: {
    backgroundColor: ColorsHelper.blackClr,
    color: ColorsHelper.whiteClr,
    mb: "0.5rem",
  },
  stepText: { ...FontsHelper.font500, width: "max-content" },
  divider: {
    mx: { xs: "1.5rem", md: "2.5rem" },
    marginTop: "0.5rem",
    backgroundColor: ColorsHelper.blackClr,
    display: { xs: "none", md: "block" },
  },
  subComCon: {
    width: { xs: "100%", md: "30rem", lg: "34rem" },
    m: "auto",
    my: "1rem",
  },
  locationBox: {
    ...CommanStyles.dflex,
    flexDirection: "column",
    width: "100%",
    position: "relative",
    mt: "1.2rem",
  },
  contactBorder: {
    border: "0.063rem solid",
    borderRadius: "0.75rem",
    padding: "0.5rem",
  },
  contactListTitle: {
    fontSize: "0.813rem",
    paddingLeft: "0.5rem",
    ...FontsHelper.font500,
    cursor: "default",
  },
  contactListSubTitle: {
    fontSize: "0.938rem",
    paddingLeft: "0.5rem",
    ...FontsHelper.font600,
    cursor: "default",
  },
  fieldTitle: {
    ...FontsHelper.font500,
    pl: "0.5rem",
    mb: "0.5rem",
    cursor: "default",
  },
  startEndorsment: {
    borderRight: "0.0625rem solid " + ColorsHelper.grey3,
    height: "3rem",
    width: "4rem",
    ...CommanStyles.centerIt,
    p: "1rem",
  },
  seText: { ...FontsHelper.font500 },
  cbBox: { width: "100%", ...CommanStyles.flexStartIt, mt: "1rem" },
  rpFormBox: {
    m: "auto",
    ...CommanStyles.dflex,
    justifyContent: "center",
    alignItems: "flex-start",
    px: { xs: "1.5rem", sm: "2rem" },
    overflow: "auto",
    width: "100%",
    height: {
      xs: "calc(100vh - 19.5rem)",
      md: "calc(100vh - 11.5rem)",
      lg: "calc(100vh - 11.5rem)",
    },
  },
  rpGrdPreSB: {
    justifyContent: "center",
    backgroundColor: ColorsHelper.blackClr,
    overflow: "auto",
    height: { xs: "auto", md: "100%" },
  },
  rpPreSB: {
    ...CommanStyles.flexCenterItColumn,
    height: { xs: "calc(100vh - 4.4rem)", md: "auto" },
  },
  rpPreT: {
    ...FontsHelper.font600,
    textAlign: "center",
    color: ColorsHelper.whiteClr,
    cursor: "default",
    mt: "0.5rem",
  },
  rpPT: {
    ...FontsHelper.font600,
    fontSize: "1.5rem",
    lineHeight: "2rem",
    color: ColorsHelper.blackClr,
    cursor: "default",
  },
  rpTextTF: {
    mt: "0.22rem",
    mb: "0.3rem",
  },
  rpTogSwi: {
    width: "100%",
    display: { xs: "flex", md: "none" },
    justifyContent: "center",
    alignItems: "center",
  },
  rpColPickB: {
    borderRadius: "0.3125rem",
    width: "2.5rem",
    height: "2rem",
    cursor: "pointer",
  },
  rpFieldTopDiv: {
    width: "100%",
    mt: "1rem",
  },
  toggleBtn: {
    p: "0.5rem 1rem",
    fontSize: "1rem",
    ...FontsHelper.font500,
    color: ColorsHelper.blackClr,
    border: "0.01rem solid" + ColorsHelper.blackClr,
    textTransform: "capitalize",
    backgroundColor: ColorsHelper.whiteClr + "!important",
    "&.Mui-selected,&.Mui-selected:hover": {
      backgroundColor: ColorsHelper.blackClr + "!important",
      color: ColorsHelper.whiteClr + "!important",
    },
  },
  helperT: { ...FontsHelper.font400, ml: "0.5rem" },
  BtnBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "row",
    width: "-webkit-fill-available",
    px: { xs: "1.5rem", md: "2rem" },
  },

  // mandatory Switch style
  rpSwitch: {
    ...CommanStyles.flexContainer,
    border: "0.063rem solid" + ColorsHelper.blackClr,
    width: "5.8125rem",
    borderRadius: "0.5rem",
    pl: "0.188rem",
    pr: "0.2505rem",
    py: "0.2505rem",
  },
  rpASwi: {
    ...CommanStyles.centerIt,
    height: "1.875rem",
    width: "2.5rem",
    borderRadius: "0.313rem",
    cursor: "pointer",
    backgroundColor: ColorsHelper.blackClr,
    color: ColorsHelper.whiteClr,
  },
  rpADSwi: {
    ...CommanStyles.centerIt,
    height: "1.875rem",
    width: "2.5rem",
    borderRadius: "0.313rem",
    cursor: "pointer",
    backgroundColor: ColorsHelper.grey3,
    color: ColorsHelper.whiteClr,
    transition: "0.3s",
  },
  rpInAwi: {
    ...FontsHelper.font500,
    fontSize: "0.75rem !important",
    transition: "0.3s",
  },
  rpASwiTxt: {
    ...FontsHelper.font500,
    fontSize: "0.75rem !important",
  },
  rpInASwiTxt: {
    ...FontsHelper.font500,
    fontSize: "0.75rem !important",
    mt: "0.3125rem",
    mx: "0.625rem",
    ...CommanStyles.dblock,
    cursor: "pointer",
    color: ColorsHelper.blackClr,
  },
  spColMenu: {
    mt: 4,
    div: {
      borderRadius: "0.875rem",
      li: {
        padding: "0",
        div: {
          bgcolor: ColorsHelper.whiteClr,
        },
      },
    },
  },
  disTF: {
    mt: "0.25rem",
    ".MuiOutlinedInput-root": { pl: "0 !important" },
  },
  listCloseBox: {
    display: CommanStyles.dflex,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  headTxt: {
    ...FontsHelper.font600,
    cursor: "default",
    pl: { xs: "0", md: "0.5rem" },
    marginLeft: {xs: '3rem', sm: "0rem"},
  },
  listTitleBox: {
    display: 'flex',
    alignItems: {xs: 'start', sm: 'center' },
  },
  header: {
    px: { xs: "1rem", md: "2rem" },
    my: "1rem",
    position: "relative",
  },
  repPrevDesc: {
    ...FontsHelper.font500,
    color: ColorsHelper.whiteClr
  },
  mHeadTxt: {
    ...FontsHelper.font600,
    color: ColorsHelper.blackClr,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    mt: '1rem'
  },
  progressBar: {
    backgroundColor: ColorsHelper.white5,
    "& .MuiLinearProgress-bar": {
      backgroundColor: ColorsHelper.secondaryColor,
    }
  },
  borderBtn: {
    padding: "0.2rem 1rem",
    textTransform: "capitalize",
    backgroundColor: 'transparent',
    borderRadius: "0.35rem",
    border: '1px solid black',
    fontFamily: FontsHelper.TitleFontFamily,
    fontSize: "1rem",
    color: ColorsHelper.blackClr,
    "&:hover": {
      backgroundColor: 'transparent',
    },
  },
  colorBtn: {
    padding: "0.25rem 1rem",
    textTransform: "capitalize",
    backgroundColor: ColorsHelper.blackClr,
    borderRadius: "0.35rem",
    fontFamily: FontsHelper.TitleFontFamily,
    fontSize: "1rem",
    color: ColorsHelper.whiteClr,
    "&:hover": {
      backgroundColor: ColorsHelper.blackClr,
    },
  },
};
export default RepStyle;
