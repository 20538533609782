// Created By ND
const countryCodes = [
  {
    label: "+1",
    value: "1",
    country_code_initials: "ca",
    name: "Canada",
    image: "/images/countryFlages/CA_+1.svg",
  },
  {
    label: "+93",
    value: "93",
    country_code_initials: "af",
    name: "Afghanistan",
    image: "/images/countryFlages/AF_+93.svg",
  },
  {
    label: "+355",
    value: "355",
    country_code_initials: "al",
    name: "Albania",
    image: "/images/countryFlages/AL_+355.svg",
  },
  {
    label: "+213",
    value: "213",
    country_code_initials: "dz",
    name: "Algeria",
    image: "/images/countryFlages/DZ_+213.svg",
  },
  {
    label: "+1684",
    value: "1684",
    country_code_initials: "as",
    name: "American Samoa",
    image: "/images/countryFlages/AS_+1684.svg",
  },
  {
    label: "+376",
    value: "376",
    country_code_initials: "ad",
    name: "Andorra",
    image: "/images/countryFlages/AD_+376.svg",
  },
  {
    label: "+244",
    value: "244",
    country_code_initials: "ao",
    name: "Angola",
    image: "/images/countryFlages/AO_+244.svg",
  },
  {
    label: "+1264",
    value: "1264",
    country_code_initials: "ai",
    name: "Anguilla",
    image: "/images/countryFlages/AI_+1264.svg",
  },
  {
    label: "+672",
    value: "672",
    country_code_initials: "aq",
    name: "Antarctica",
    image: "/images/countryFlages/AQ_+672.svg",
  },
  {
    label: "+1268",
    value: "1268",
    country_code_initials: "ag",
    name: "Antigua and Barbuda",
    image: "/images/countryFlages/AG_+1268.svg",
  },
  {
    label: "+54",
    value: "54",
    country_code_initials: "ar",
    name: "Argentina",
    image: "/images/countryFlages/AR_+54.svg",
  },
  {
    label: "+374",
    value: "374",
    country_code_initials: "am",
    name: "Armenia",
    image: "/images/countryFlages/AM_+374.svg",
  },
  {
    label: "+297",
    value: "297",
    country_code_initials: "aw",
    name: "Aruba",
    image: "/images/countryFlages/AW_+297.svg",
  },
  {
    label: "+61",
    value: "61",
    country_code_initials: "au",
    name: "Australia",
    image: "/images/countryFlages/AU_+61.svg",
  },
  {
    label: "+61",
    value: "61",
    country_code_initials: "cx",
    name: "Christmas Island",
    image: "/images/countryFlages/CX_+61.svg",
  },
  {
    label: "+61",
    value: "61",
    country_code_initials: "cc",
    name: "Cocos (Keeling) Islands",
    image: "/images/countryFlages/CC_+61.svg",
  },
  {
    label: "+43",
    value: "43",
    country_code_initials: "at",
    name: "Austria",
    image: "/images/countryFlages/AT_+43.svg",
  },
  {
    label: "+994",
    value: "994",
    country_code_initials: "az",
    name: "Azerbaijan",
    image: "/images/countryFlages/AZ_+994.svg",
  },
  {
    label: "+1242",
    value: "1242",
    country_code_initials: "bs",
    name: "Bahamas",
    image: "/images/countryFlages/BS_+1242.svg",
  },
  {
    label: "+973",
    value: "973",
    country_code_initials: "bh",
    name: "Bahrain",
    image: "/images/countryFlages/BH_+973.svg",
  },
  {
    label: "+880",
    value: "880",
    country_code_initials: "bd",
    name: "Bangladesh",
    image: "/images/countryFlages/BD_+880.svg",
  },
  {
    label: "+1246",
    value: "1246",
    country_code_initials: "bb",
    name: "Barbados",
    image: "/images/countryFlages/BB_+1246.svg",
  },
  {
    label: "+375",
    value: "375",
    country_code_initials: "by",
    name: "Belarus",
    image: "/images/countryFlages/BY_+375.svg",
  },
  {
    label: "+32",
    value: "32",
    country_code_initials: "be",
    name: "Belgium",
    image: "/images/countryFlages/BE_+32.svg",
  },
  {
    label: "+501",
    value: "501",
    country_code_initials: "bz",
    name: "Belize",
    image: "/images/countryFlages/BZ_+501.svg",
  },
  {
    label: "+229",
    value: "229",
    country_code_initials: "bj",
    name: "Benin",
    image: "/images/countryFlages/BJ_+229.svg",
  },
  {
    label: "+1441",
    value: "1441",
    country_code_initials: "bm",
    name: "Bermuda",
    image: "/images/countryFlages/BM_+1441.svg",
  },
  {
    label: "+975",
    value: "975",
    country_code_initials: "bt",
    name: "Bhutan",
    image: "/images/countryFlages/BT_+975.svg",
  },
  {
    label: "+591",
    value: "591",
    country_code_initials: "bo",
    name: "Bolivia",
    image: "/images/countryFlages/BO_+591.svg",
  },
  {
    label: "+387",
    value: "387",
    country_code_initials: "ba",
    name: "Bosnia and Herzegovina",
    image: "/images/countryFlages/BA_+387.svg",
  },
  {
    label: "+267",
    value: "267",
    country_code_initials: "bw",
    name: "Botswana",
    image: "/images/countryFlages/BW_+267.svg",
  },
  {
    label: "+55",
    value: "55",
    country_code_initials: "br",
    name: "Brazil",
    image: "/images/countryFlages/BR_+55.svg",
  },
  {
    label: "+246",
    value: "246",
    country_code_initials: "io",
    name: "British Indian Ocean Territory",
    image: "/images/countryFlages/IO_+246.svg",
  },
  {
    label: "+1284",
    value: "1284",
    country_code_initials: "vg",
    name: "Virgin Islands (British)",
    image: "/images/countryFlages/VG_+1284.svg",
  },
  {
    label: "+673",
    value: "673",
    country_code_initials: "bn",
    name: "Brunei Darussalam",
    image: "/images/countryFlages/BN_+673.svg",
  },
  {
    label: "+359",
    value: "359",
    country_code_initials: "bg",
    name: "Bulgaria",
    image: "/images/countryFlages/BG_+359.svg",
  },
  {
    label: "+226",
    value: "226",
    country_code_initials: "bf",
    name: "Burkina Faso",
    image: "/images/countryFlages/BF_+226.svg",
  },
  {
    label: "+257",
    value: "257",
    country_code_initials: "bi",
    name: "Burundi",
    image: "/images/countryFlages/BI_+257.svg",
  },
  {
    label: "+855",
    value: "855",
    country_code_initials: "kh",
    name: "Cambodia",
    image: "/images/countryFlages/KH_+855.svg",
  },
  {
    label: "+237",
    value: "237",
    country_code_initials: "cm",
    name: "Cameroon",
    image: "/images/countryFlages/CM_+237.svg",
  },
  {
    label: "+238",
    value: "238",
    country_code_initials: "cv",
    name: "Cabo Verde",
    image: "/images/countryFlages/CV_+238.svg",
  },
  {
    label: "+345",
    value: "345",
    country_code_initials: "ky",
    name: "Cayman Islands",
    image: "/images/countryFlages/KY_+345.svg",
  },
  {
    label: "+236",
    value: "236",
    country_code_initials: "cf",
    name: "Central African Republic",
    image: "/images/countryFlages/CF_+236.svg",
  },
  {
    label: "+235",
    value: "235",
    country_code_initials: "td",
    name: "Chad",
    image: "/images/countryFlages/TD_+235.svg",
  },
  {
    label: "+56",
    value: "56",
    country_code_initials: "cl",
    name: "Chile",
    image: "/images/countryFlages/CL_+56.svg",
  },
  {
    label: "+86",
    value: "86",
    country_code_initials: "cn",
    name: "China",
    image: "/images/countryFlages/CN_+86.svg",
  },
  {
    label: "+57",
    value: "57",
    country_code_initials: "co",
    name: "Colombia",
    image: "/images/countryFlages/CO_+57.svg",
  },
  {
    label: "+269",
    value: "269",
    country_code_initials: "km",
    name: "Comoros",
    image: "/images/countryFlages/KM_+269.svg",
  },
  {
    label: "+682",
    value: "682",
    country_code_initials: "ck",
    name: "Cook Islands",
    image: "/images/countryFlages/CK_+682.svg",
  },
  {
    label: "+506",
    value: "506",
    country_code_initials: "cr",
    name: "Costa Rica",
    image: "/images/countryFlages/CR_+506.svg",
  },
  {
    label: "+242",
    value: "242",
    country_code_initials: "cg",
    name: "Congo",
    image: "/images/countryFlages/CG_+242.svg",
  },
  {
    label: "+385",
    value: "385",
    country_code_initials: "hr",
    name: "Croatia",
  },
  {
    label: "+53",
    value: "53",
    country_code_initials: "cu",
    name: "Cuba",
    image: "/images/countryFlages/HR_+385.svg",
  },
  {
    label: "+357",
    value: "357",
    country_code_initials: "cy",
    name: "Cyprus",
    image: "/images/countryFlages/CY_+357.svg",
  },
  {
    label: "+420",
    value: "420",
    country_code_initials: "cz",
    name: "Czechia",
    image: "/images/countryFlages/CZ_+420.svg",
  },
  {
    label: "+243",
    value: "243",
    country_code_initials: "cd",
    name: "Congo, Democratic Republic of the",
    image: "/images/countryFlages/CD_+243.svg",
  },
  {
    label: "+45",
    value: "45",
    country_code_initials: "dk",
    name: "Denmark",
    image: "/images/countryFlages/DK_+45.svg",
  },
  {
    label: "+253",
    value: "253",
    country_code_initials: "dj",
    name: "Djibouti",
    image: "/images/countryFlages/DJ_+253.svg",
  },
  {
    label: "+1767",
    value: "1767",
    country_code_initials: "dm",
    name: "Dominica",
    image: "/images/countryFlages/DM_+1767.svg",
  },
  {
    label: "+1849",
    value: "1849",
    country_code_initials: "do",
    name: "Dominican Republic",
    image: "/images/countryFlages/DO_+1849.svg",
  },
  {
    label: "+670",
    value: "670",
    country_code_initials: "tl",
    name: "Timor-Leste",
    image: "/images/countryFlages/TL_+670.svg",
  },
  {
    label: "+593",
    value: "593",
    country_code_initials: "ec",
    name: "Ecuador",
    image: "/images/countryFlages/EC_+593.svg",
  },
  {
    label: "+20",
    value: "20",
    country_code_initials: "eg",
    name: "Egypt",
    image: "/images/countryFlages/EG_+20.svg",
  },
  {
    label: "+503",
    value: "503",
    country_code_initials: "sv",
    name: "El Salvador",
    image: "/images/countryFlages/SV_+503.svg",
  },
  {
    label: "+240",
    value: "240",
    country_code_initials: "gq",
    name: "Equatorial Guinea",
    image: "/images/countryFlages/GQ_+240.svg",
  },
  {
    label: "+291",
    value: "291",
    country_code_initials: "er",
    name: "Eritrea",
    image: "/images/countryFlages/ER_+291.svg",
  },
  {
    label: "+372",
    value: "372",
    country_code_initials: "ee",
    name: "Estonia",
    image: "/images/countryFlages/EE_+372.svg",
  },
  {
    label: "+251",
    value: "251",
    country_code_initials: "et",
    name: "Ethiopia",
    image: "/images/countryFlages/ET_+251.svg",
  },
  {
    label: "+500",
    value: "500",
    country_code_initials: "fk",
    name: "Falkland Islands (Malvinas)",
    image: "/images/countryFlages/FK_+500.svg",
  },
  {
    label: "+298",
    value: "298",
    country_code_initials: "fo",
    name: "Faroe Islands",
    image: "/images/countryFlages/FO_+298.svg",
  },
  {
    label: "+679",
    value: "679",
    country_code_initials: "fj",
    name: "Fiji",
    image: "/images/countryFlages/FJ_+679.svg",
  },
  {
    label: "+358",
    value: "358",
    country_code_initials: "fi",
    name: "Finland",
    image: "/images/countryFlages/FI_+358.svg",
  },
  {
    label: "+33",
    value: "33",
    country_code_initials: "fr",
    name: "France",
    image: "/images/countryFlages/FR_+33.svg",
  },
  {
    label: "+594",
    value: "594",
    country_code_initials: "gf",
    name: "French Guiana",
    image: "/images/countryFlages/GF_+594.svg",
  },
  {
    label: "+689",
    value: "689",
    country_code_initials: "pf",
    name: "French Polynesia",
    image: "/images/countryFlages/PF_+689.svg",
  },
  {
    label: "+241",
    value: "241",
    country_code_initials: "ga",
    name: "Gabon",
    image: "/images/countryFlages/GA_+241.svg",
  },
  {
    label: "+220",
    value: "220",
    country_code_initials: "gm",
    name: "Gambia",
    image: "/images/countryFlages/GM_+220.svg",
  },
  {
    label: "+995",
    value: "995",
    country_code_initials: "ge",
    name: "Georgia",
    image: "/images/countryFlages/GE_+995.svg",
  },
  {
    label: "+49",
    value: "49",
    country_code_initials: "de",
    name: "Germany",
    image: "/images/countryFlages/DE_+49.svg",
  },
  {
    label: "+233",
    value: "233",
    country_code_initials: "gh",
    name: "Ghana",
    image: "/images/countryFlages/GH_+233.svg",
  },
  {
    label: "+350",
    value: "350",
    country_code_initials: "gi",
    name: "Gibraltar",
    image: "/images/countryFlages/GI_+350.svg",
  },
  {
    label: "+30",
    value: "30",
    country_code_initials: "gr",
    name: "Greece",
    image: "/images/countryFlages/GR_+30.svg",
  },
  {
    label: "+299",
    value: "299",
    country_code_initials: "gl",
    name: "Greenland",
    image: "/images/countryFlages/GL_+299.svg",
  },
  {
    label: "+1473",
    value: "1473",
    country_code_initials: "gd",
    name: "Grenada",
    image: "/images/countryFlages/GD_+1473.svg",
  },
  {
    label: "+590",
    value: "590",
    country_code_initials: "bl",
    name: "Saint Barthélemy",
    image: "/images/countryFlages/BL_+590.svg",
  },
  {
    label: "+1671",
    value: "1671",
    country_code_initials: "gu",
    name: "Guam",
    image: "/images/countryFlages/GU_+1671.svg",
  },
  {
    label: "+502",
    value: "502",
    country_code_initials: "gt",
    name: "Guatemala",
    image: "/images/countryFlages/GT_+502.svg",
  },
  {
    label: "+44",
    value: "44",
    country_code_initials: "gg",
    name: "Guernsey",
    image: "/images/countryFlages/GG_+44.svg",
  },
  {
    label: "+224",
    value: "224",
    country_code_initials: "gn",
    name: "Guinea",
    image: "/images/countryFlages/GN_+224.svg",
  },
  {
    label: "+245",
    value: "245",
    country_code_initials: "gw",
    name: "Guinea-Bissau",
    image: "/images/countryFlages/GW_+245.svg",
  },
  {
    label: "+592",
    value: "592",
    country_code_initials: "gy",
    name: "Guyana",
    image: "/images/countryFlages/GY_+595.svg",
  },
  {
    label: "+509",
    value: "509",
    country_code_initials: "ht",
    name: "Haiti",
    image: "/images/countryFlages/HT_+509.svg",
  },
  {
    label: "+379",
    value: "379",
    country_code_initials: "va",
    name: "Holy See",
    image: "/images/countryFlages/VA_+379.svg",
  },
  {
    label: "+504",
    value: "504",
    country_code_initials: "hn",
    name: "Honduras",
    image: "/images/countryFlages/HN_+504.svg",
  },
  {
    label: "+852",
    value: "852",
    country_code_initials: "hk",
    name: "Hong Kong",
    image: "/images/countryFlages/HK_+852.svg",
  },
  {
    label: "+36",
    value: "36",
    country_code_initials: "hu",
    name: "Hungary",
    image: "/images/countryFlages/HU_+36.svg",
  },
  {
    label: "+354",
    value: "354",
    country_code_initials: "is",
    name: "Iceland",
    image: "/images/countryFlages/IS_+354.svg",
  },
  {
    label: "+91",
    value: "91",
    country_code_initials: "in",
    name: "India",
    image: "/images/countryFlages/IN_+91.svg",
  },
  {
    label: "+62",
    value: "62",
    country_code_initials: "id",
    name: "Indonesia",
    image: "/images/countryFlages/ID_+62.svg",
  },
  {
    label: "+98",
    value: "98",
    country_code_initials: "ir",
    name: "Iran (Islamic Republic of)",
    image: "/images/countryFlages/IR_+98.svg",
  },
  {
    label: "+964",
    value: "964",
    country_code_initials: "iq",
    name: "Iraq",
    image: "/images/countryFlages/IQ_+964.svg",
  },
  {
    label: "+353",
    value: "353",
    country_code_initials: "ie",
    name: "Ireland",
    image: "/images/countryFlages/IE_+353.svg",
  },
  {
    label: "+972",
    value: "972",
    country_code_initials: "il",
    name: "Israel",
    image: "/images/countryFlages/IL_+972.svg",
  },
  {
    label: "+39",
    value: "39",
    country_code_initials: "it",
    name: "Italy",
    image: "/images/countryFlages/IT_+39.svg",
  },
  {
    label: "+225",
    value: "225",
    country_code_initials: "ci",
    name: "Côte d'Ivoire",
    image: "/images/countryFlages/CI_+225.svg",
  },
  {
    label: "+1876",
    value: "1876",
    country_code_initials: "jm",
    name: "Jamaica",
    image: "/images/countryFlages/JM_+1876.svg",
  },
  {
    label: "+81",
    value: "81",
    country_code_initials: "jp",
    name: "Japan",
    image: "/images/countryFlages/JP_+81.svg",
  },
  {
    label: "+962",
    value: "962",
    country_code_initials: "jo",
    name: "Jordan",
    image: "/images/countryFlages/JO_+962.svg",
  },
  {
    label: "+7",
    value: "7",
    country_code_initials: "kz",
    name: "Kazakhstan",
    image: "/images/countryFlages/KZ_+77.svg",
  },
  {
    label: "+254",
    value: "254",
    country_code_initials: "ke",
    name: "Kenya",
    image: "/images/countryFlages/KE_+254.svg",
  },
  {
    label: "+686",
    value: "686",
    country_code_initials: "ki",
    name: "Kiribati",
    image: "/images/countryFlages/KI_+686.svg",
  },
  {
    label: "+965",
    value: "965",
    country_code_initials: "kw",
    name: "Kuwait",
    image: "/images/countryFlages/KW_+965.svg",
  },
  {
    label: "+996",
    value: "996",
    country_code_initials: "kg",
    name: "Kyrgyzstan",
    image: "/images/countryFlages/KG_+996.svg",
  },
  {
    label: "+850",
    value: "850",
    country_code_initials: "kp",
    name: "Korea (Democratic People's Republic of)",
    image: "/images/countryFlages/KP_+850.svg",
  },
  {
    label: "+82",
    value: "82",
    country_code_initials: "kr",
    name: "Korea, Republic of",
    image: "/images/countryFlages/KR_+82.svg",
  },
  {
    label: "+856",
    value: "856",
    country_code_initials: "la",
    name: "Lao People's Democratic Republic",
    image: "/images/countryFlages/LA_+856.svg",
  },
  {
    label: "+371",
    value: "371",
    country_code_initials: "lv",
    name: "Latvia",
    image: "/images/countryFlages/LV_+371.svg",
  },
  {
    label: "+961",
    value: "961",
    country_code_initials: "lb",
    name: "Lebanon",
    image: "/images/countryFlages/LB_+961.svg",
  },
  {
    label: "+266",
    value: "266",
    country_code_initials: "ls",
    name: "Lesotho",
    image: "/images/countryFlages/LS_+266.svg",
  },
  {
    label: "+231",
    value: "231",
    country_code_initials: "lr",
    name: "Liberia",
    image: "/images/countryFlages/LR_+231.svg",
  },
  {
    label: "+218",
    value: "218",
    country_code_initials: "ly",
    name: "Libya",
    image: "/images/countryFlages/LY_+218.svg",
  },
  {
    label: "+423",
    value: "423",
    country_code_initials: "li",
    name: "Liechtenstein",
    image: "/images/countryFlages/LI_+423.svg",
  },
  {
    label: "+370",
    value: "370",
    country_code_initials: "lt",
    name: "Lithuania",
    image: "/images/countryFlages/LT_+370.svg",
  },
  {
    label: "+352",
    value: "352",
    country_code_initials: "lu",
    name: "Luxembourg",
    image: "/images/countryFlages/LU_+352.svg",
  },
  {
    label: "+853",
    value: "853",
    country_code_initials: "mo",
    name: "Macao",
    image: "/images/countryFlages/MO_+853.svg",
  },
  {
    label: "+389",
    value: "389",
    country_code_initials: "mk",
    name: "North Macedonia",
    image: "/images/countryFlages/MK_+389.svg",
  },
  {
    label: "+261",
    value: "261",
    country_code_initials: "mg",
    name: "Madagascar",
    image: "/images/countryFlages/MG_+261.svg",
  },
  {
    label: "+265",
    value: "265",
    country_code_initials: "mw",
    name: "Malawi",
    image: "/images/countryFlages/MW_+265.svg",
  },
  {
    label: "+60",
    value: "60",
    country_code_initials: "my",
    name: "Malaysia",
    image: "/images/countryFlages/MY_+60.svg",
  },
  {
    label: "+960",
    value: "960",
    country_code_initials: "mv",
    name: "Maldives",
    image: "/images/countryFlages/MV_+960.svg",
  },
  {
    label: "+223",
    value: "223",
    country_code_initials: "ml",
    name: "Mali",
    image: "/images/countryFlages/ML_+223.svg",
  },
  {
    label: "+356",
    value: "356",
    country_code_initials: "mt",
    name: "Malta",
    image: "/images/countryFlages/MT_+356.svg",
  },
  {
    label: "+692",
    value: "692",
    country_code_initials: "mh",
    name: "Marshall Islands",
    image: "/images/countryFlages/MH_+692.svg",
  },
  {
    label: "+596",
    value: "596",
    country_code_initials: "mq",
    name: "Martinique",
    image: "/images/countryFlages/MQ_+596.svg",
  },
  {
    label: "+222",
    value: "222",
    country_code_initials: "mr",
    name: "Mauritania",
    image: "/images/countryFlages/MR_+222.svg",
  },
  {
    label: "+230",
    value: "230",
    country_code_initials: "mu",
    name: "Mauritius",
    image: "/images/countryFlages/MU_+230.svg",
  },
  {
    label: "+262",
    value: "262",
    country_code_initials: "yt",
    name: "Mayotte",
    image: "/images/countryFlages/YT_+262.svg",
  },
  {
    label: "+52",
    value: "52",
    country_code_initials: "mx",
    name: "Mexico",
    image: "/images/countryFlages/MX_+52.svg",
  },
  {
    label: "+691",
    value: "691",
    country_code_initials: "fm",
    name: "Micronesia (Federated States of)",
    image: "/images/countryFlages/FM_+691.svg",
  },
  {
    label: "+373",
    value: "373",
    country_code_initials: "md",
    name: "Moldova, Republic of",
    image: "/images/countryFlages/MD_+373.svg",
  },
  {
    label: "+377",
    value: "377",
    country_code_initials: "mc",
    name: "Monaco",
    image: "/images/countryFlages/MC_+377.svg",
  },
  {
    label: "+976",
    value: "976",
    country_code_initials: "mn",
    name: "Mongolia",
    image: "/images/countryFlages/MN_+976.svg",
  },
  {
    label: "+382",
    value: "382",
    country_code_initials: "me",
    name: "Montenegro",
    image: "/images/countryFlages/ME_+382.svg",
  },
  {
    label: "+1664",
    value: "1664",
    country_code_initials: "ms",
    name: "Montserrat",
    image: "/images/countryFlages/MS_+1664.svg",
  },
  {
    label: "+212",
    value: "212",
    country_code_initials: "ma",
    name: "Morocco",
    image: "/images/countryFlages/MA_+212.svg",
  },
  {
    label: "+258",
    value: "258",
    country_code_initials: "mz",
    name: "Mozambique",
    image: "/images/countryFlages/MZ_+258.svg",
  },
  {
    label: "+95",
    value: "95",
    country_code_initials: "mm",
    name: "Myanmar",
    image: "/images/countryFlages/MM_+95.svg",
  },
  {
    label: "+264",
    value: "264",
    country_code_initials: "na",
    name: "Namibia",
    image: "/images/countryFlages/NA_+264.svg",
  },
  {
    label: "+674",
    value: "674",
    country_code_initials: "nr",
    name: "Nauru",
    image: "/images/countryFlages/NR_+674.svg",
  },
  {
    label: "+977",
    value: "977",
    country_code_initials: "np",
    name: "Nepal",
    image: "/images/countryFlages/NP_+977.svg",
  },
  {
    label: "+31",
    value: "31",
    country_code_initials: "nl",
    name: "Netherlands",
    image: "/images/countryFlages/NL_+31.svg",
  },
  {
    label: "+687",
    value: "687",
    country_code_initials: "nc",
    name: "New Caledonia",
    image: "/images/countryFlages/NC_+687.svg",
  },
  {
    label: "+64",
    value: "64",
    country_code_initials: "nz",
    name: "New Zealand",
    image: "/images/countryFlages/NZ_+64.svg",
  },
  {
    label: "+505",
    value: "505",
    country_code_initials: "ni",
    name: "Nicaragua",
    image: "/images/countryFlages/NI_+505.svg",
  },
  {
    label: "+227",
    value: "227",
    country_code_initials: "ne",
    name: "Niger",
    image: "/images/countryFlages/NE_+227.svg",
  },
  {
    label: "+234",
    value: "234",
    country_code_initials: "ng",
    name: "Nigeria",
    image: "/images/countryFlages/NG_+234.svg",
  },
  {
    label: "+683",
    value: "683",
    country_code_initials: "nu",
    name: "Niue",
    image: "/images/countryFlages/NU_+683.svg",
  },
  {
    label: "+1670",
    value: "1670",
    country_code_initials: "mp",
    name: "Northern Mariana Islands",
    image: "/images/countryFlages/MP_+1670.svg",
  },
  {
    label: "+47",
    value: "47",
    country_code_initials: "no",
    name: "Norway",
    image: "/images/countryFlages/NO_+47.svg",
  },
  {
    label: "+968",
    value: "968",
    country_code_initials: "om",
    name: "Oman",
    image: "/images/countryFlages/OM_+968.svg",
  },
  {
    label: "+92",
    value: "92",
    country_code_initials: "pk",
    name: "Pakistan",
    image: "/images/countryFlages/PK_+92.svg",
  },
  {
    label: "+680",
    value: "680",
    country_code_initials: "pw",
    name: "Palau",
    image: "/images/countryFlages/PW_+680.svg",
  },
  {
    label: "+970",
    value: "970",
    country_code_initials: "ps",
    name: "Palestine, State of",
    image: "/images/countryFlages/PS_+970.svg",
  },
  {
    label: "+507",
    value: "507",
    country_code_initials: "pa",
    name: "Panama",
    image: "/images/countryFlages/PA_+507.svg",
  },
  {
    label: "+675",
    value: "675",
    country_code_initials: "pg",
    name: "Papua New Guinea",
    image: "/images/countryFlages/PG_+675.svg",
  },
  {
    label: "+595",
    value: "595",
    country_code_initials: "py",
    name: "Paraguay",
    image: "/images/countryFlages/PY_+595.svg",
  },
  {
    label: "+51",
    value: "51",
    country_code_initials: "pe",
    name: "Peru",
    image: "/images/countryFlages/PE_+51.svg",
  },
  {
    label: "+63",
    value: "63",
    country_code_initials: "ph",
    name: "Philippines",
    image: "/images/countryFlages/PH_+63.svg",
  },
  {
    label: "+64",
    value: "64",
    country_code_initials: "pn",
    name: "Pitcairn",
    image: "/images/countryFlages/PN_+872.svg",
  },
  {
    label: "+48",
    value: "48",
    country_code_initials: "pl",
    name: "Poland",
    image: "/images/countryFlages/PL_+48.svg",
  },
  {
    label: "+351",
    value: "351",
    country_code_initials: "pt",
    name: "Portugal",
    image: "/images/countryFlages/PT_+351.svg",
  },
  {
    label: "+1939",
    value: "1939",
    country_code_initials: "pr",
    name: "Puerto Rico",
    image: "/images/countryFlages/PR_+1939.svg",
  },
  {
    label: "+974",
    value: "974",
    country_code_initials: "qa",
    name: "Qatar",
    image: "/images/countryFlages/QA_+974.svg",
  },
  {
    label: "+262",
    value: "262",
    country_code_initials: "re",
    name: "Reunion",
    image: "/images/countryFlages/RE_+262.svg",
  },
  {
    label: "+40",
    value: "40",
    country_code_initials: "ro",
    name: "Romania",
    image: "/images/countryFlages/RO_+40.svg",
  },
  {
    label: "+7",
    value: "7",
    country_code_initials: "ru",
    name: "Russian Federation",
    image: "/images/countryFlages/RU_+7.svg",
  },
  {
    label: "+250",
    value: "250",
    country_code_initials: "rw",
    name: "Rwanda",
    image: "/images/countryFlages/RW_+250.svg",
  },
  {
    label: "+290",
    value: "290",
    country_code_initials: "sh",
    name: "Saint Helena",
    image: "/images/countryFlages/SH_+290.svg",
  },
  {
    label: "+1869",
    value: "1869",
    country_code_initials: "kn",
    name: "Saint Kitts and Nevis",
    image: "/images/countryFlages/KN_+1869.svg",
  },
  {
    label: "+1758",
    value: "1758",
    country_code_initials: "lc",
    name: "Saint Lucia",
    image: "/images/countryFlages/LC_+1758.svg",
  },
  {
    label: "+508",
    value: "508",
    country_code_initials: "pm",
    name: "Saint Pierre and Miquelon",
    image: "/images/countryFlages/PM_+508.svg",
  },
  {
    label: "+1784",
    value: "1784",
    country_code_initials: "vc",
    name: "Saint Vincent and the Grenadines",
    image: "/images/countryFlages/VC_+1784.svg",
  },
  {
    label: "+685",
    value: "685",
    country_code_initials: "ws",
    name: "Samoa",
    image: "/images/countryFlages/WS_+685.svg",
  },
  {
    label: "+378",
    value: "378",
    country_code_initials: "sm",
    name: "San Marino",
    image: "/images/countryFlages/SM_+378.svg",
  },
  {
    label: "+239",
    value: "239",
    country_code_initials: "st",
    name: "Sao Tome and Principe",
    image: "/images/countryFlages/ST_+239.svg",
  },
  {
    label: "+966",
    value: "966",
    country_code_initials: "sa",
    name: "Saudi Arabia",
    image: "/images/countryFlages/SA_+966.svg",
  },
  {
    label: "+221",
    value: "221",
    country_code_initials: "sn",
    name: "Senegal",
    image: "/images/countryFlages/SN_+221.svg",
  },
  {
    label: "+381",
    value: "381",
    country_code_initials: "rs",
    name: "Serbia",
    image: "/images/countryFlages/RS_+381.svg",
  },
  {
    label: "+248",
    value: "248",
    country_code_initials: "sc",
    name: "Seychelles",
    image: "/images/countryFlages/SC_+248.svg",
  },
  {
    label: "+232",
    value: "232",
    country_code_initials: "sl",
    name: "Sierra Leone",
    image: "/images/countryFlages/SL_+232.svg",
  },
  {
    label: "+65",
    value: "65",
    country_code_initials: "sg",
    name: "Singapore",
    image: "/images/countryFlages/SG_+65.svg",
  },
  {
    label: "+421",
    value: "421",
    country_code_initials: "sk",
    name: "Slovakia",
    image: "/images/countryFlages/SK_+421.svg",
  },
  {
    label: "+386",
    value: "386",
    country_code_initials: "si",
    name: "Slovenia",
    image: "/images/countryFlages/SI_+386.svg",
  },
  {
    label: "+677",
    value: "677",
    country_code_initials: "sb",
    name: "Solomon Islands",
    image: "/images/countryFlages/SB_+677.svg",
  },
  {
    label: "+252",
    value: "252",
    country_code_initials: "so",
    name: "Somalia",
    image: "/images/countryFlages/SO_+252.svg",
  },
  {
    label: "+27",
    value: "27",
    country_code_initials: "za",
    name: "South Africa",
    image: "/images/countryFlages/ZA_+27.svg",
  },
  {
    label: "+211",
    value: "211",
    country_code_initials: "ss",
    name: "South Sudan",
    image: "/images/countryFlages/SS_+211.svg",
  },
  {
    label: "+34",
    value: "34",
    country_code_initials: "es",
    name: "Spain",
    image: "/images/countryFlages/ES_+34.svg",
  },
  {
    label: "+94",
    value: "94",
    country_code_initials: "lk",
    name: "Sri Lanka",
    image: "/images/countryFlages/LK_+94.svg",
  },
  {
    label: "+249",
    value: "249",
    country_code_initials: "sd",
    name: "Sudan",
    image: "/images/countryFlages/SD_+249.svg",
  },
  {
    label: "+597",
    value: "597",
    country_code_initials: "sr",
    name: "Suriname",
    image: "/images/countryFlages/SR_+597.svg",
  },
  {
    label: "+47",
    value: "47",
    country_code_initials: "sj",
    name: "Svalbard and Jan Mayen",
    image: "/images/countryFlages/SJ_+47.svg",
  },
  {
    label: "+268",
    value: "268",
    country_code_initials: "sz",
    name: "Eswatini",
    image: "/images/countryFlages/SZ_+268.svg",
  },
  {
    label: "+46",
    value: "46",
    country_code_initials: "se",
    name: "Sweden",
    image: "/images/countryFlages/SE_+46.svg",
  },
  {
    label: "+41",
    value: "41",
    country_code_initials: "sh",
    name: "Saint Helena",
    image: "/images/countryFlages/SH_+290.svg",
  },
  {
    label: "+963",
    value: "963",
    country_code_initials: "sy",
    name: "Syrian Arab Republic",
    image: "/images/countryFlages/SY_+963.svg",
  },
  {
    label: "+886",
    value: "886",
    country_code_initials: "tw",
    name: "Taiwan",
    image: "/images/countryFlages/TW_+886.svg",
  },
  {
    label: "+992",
    value: "992",
    country_code_initials: "tj",
    name: "Tajikistan",
    image: "/images/countryFlages/TJ_+992.svg",
  },
  {
    label: "+255",
    value: "255",
    country_code_initials: "tz",
    name: "Tanzania, United Republic of",
    image: "/images/countryFlages/TZ_+255.svg",
  },
  {
    label: "+66",
    value: "66",
    country_code_initials: "th",
    name: "Thailand",
    image: "/images/countryFlages/TH_+66.svg",
  },
  {
    label: "+228",
    value: "228",
    country_code_initials: "tg",
    name: "Togo",
    image: "/images/countryFlages/TG_+228.svg",
  },
  {
    label: "+690",
    value: "690",
    country_code_initials: "tk",
    name: "Tokelau",
    image: "/images/countryFlages/TK_+690.svg",
  },
  {
    label: "+676",
    value: "676",
    country_code_initials: "to",
    name: "Tonga",
    image: "/images/countryFlages/TO_+676.svg",
  },
  {
    label: "+1868",
    value: "1868",
    country_code_initials: "tt",
    name: "Trinidad and Tobago",
    image: "/images/countryFlages/TT_+1868.svg",
  },
  {
    label: "+216",
    value: "216",
    country_code_initials: "tn",
    name: "Tunisia",
    image: "/images/countryFlages/TN_+216.svg",
  },
  {
    label: "+90",
    value: "90",
    country_code_initials: "tr",
    name: "Turkey",
    image: "/images/countryFlages/TR_+90.svg",
  },
  {
    label: "+993",
    value: "993",
    country_code_initials: "tm",
    name: "Turkmenistan",
    image: "/images/countryFlages/TM_+993.svg",
  },
  {
    label: "+1649",
    value: "1649",
    country_code_initials: "tc",
    name: "Turks and Caicos Islands",
    image: "/images/countryFlages/TC_+1649.svg",
  },
  {
    label: "+688",
    value: "688",
    country_code_initials: "tv",
    name: "Tuvalu",
    image: "/images/countryFlages/TV_+688.svg",
  },
  {
    label: "+256",
    value: "256",
    country_code_initials: "ug",
    name: "Uganda",
    image: "/images/countryFlages/UG_+256.svg",
  },
  {
    label: "+380",
    value: "380",
    country_code_initials: "ua",
    name: "Ukraine",
    image: "/images/countryFlages/UA_+380.svg",
  },
  {
    label: "+971",
    value: "971",
    country_code_initials: "ae",
    name: "United Arab Emirates",
    image: "/images/countryFlages/AE_+971.svg",
  },
  {
    label: "+1",
    value: "1",
    country_code_initials: "us",
    name: "United States of America",
    image: "/images/countryFlages/US_+1.svg",
  },
  {
    label: "+598",
    value: "598",
    country_code_initials: "uy",
    name: "Uruguay",
    image: "/images/countryFlages/UY_+598.svg",
  },
  {
    label: "+998",
    value: "998",
    country_code_initials: "uz",
    name: "Uzbekistan",
    image: "/images/countryFlages/UZ_+998.svg",
  },
  {
    label: "+678",
    value: "678",
    country_code_initials: "vu",
    name: "Vanuatu",
    image: "/images/countryFlages/VU_+678.svg",
  },
  {
    label: "+58",
    value: "58",
    country_code_initials: "ve",
    name: "Venezuela",
    image: "/images/countryFlages/VE_+58.svg",
  },
  {
    label: "+84",
    value: "84",
    country_code_initials: "vn",
    name: "Viet Nam",
    image: "/images/countryFlages/VN_+84.svg",
  },
  {
    label: "+1340",
    value: "1340",
    country_code_initials: "vi",
    name: "Virgin Islands (U.S.)",
    image: "/images/countryFlages/VI_+1340.svg",
  },
  {
    label: "+44",
    value: "44",
    country_code_initials: "gb",
    name: "United Kingdom of Great Britain and Northern Ireland",
  },
  {
    label: "+681",
    value: "681",
    country_code_initials: "wf",
    name: "Wallis and Futuna",
    image: "/images/countryFlages/WF_+681.svg",
  },
  {
    label: "+967",
    value: "967",
    country_code_initials: "ye",
    name: "Yemen",
    image: "/images/countryFlages/YE_+967.svg",
  },
  {
    label: "+260",
    value: "260",
    country_code_initials: "zm",
    name: "Zambia",
    image: "/images/countryFlages/ZM_+260.svg",
  },
  {
    label: "+263",
    value: "263",
    country_code_initials: "zw",
    name: "Zimbabwe",
    image: "/images/countryFlages/ZW_+263.svg",
  },
];
export default countryCodes;
