const countryCodes = [
  {
    key: "CA",
    value: "1",
    label: "+1",
    image: "/images/countryFlages/CA_+1.svg",
  },
  {
    key: "US",
    value: "1",
    label: "+1",
    image: "/images/countryFlages/US_+1.svg",
  },
  {
    key: "GB",
    value: "44",
    label: "+44",
    image: "/images/countryFlages/GB_+44.svg",
  },
  {
    key: "GG",
    value: "44",
    label: "+44",
    image: "/images/countryFlages/GG_+44.svg",
  },
  {
    key: "IM",
    value: "44",
    label: "+44",
    image: "/images/countryFlages/IM_+44.svg",
  },
  {
    key: "JE",
    value: "44",
    label: "+44",
    image: "/images/countryFlages/JE_+44.svg",
  },
  {
    key: "AF",
    value: "93",
    label: "+93",
    image: "/images/countryFlages/AF_+93.svg",
  },
  {
    key: "KY",
    value: "345",
    label: "+345",
    image: "/images/countryFlages/KY_+345.svg",
  },
  {
    key: "NC",
    value: "687",
    label: "+687",
    image: "/images/countryFlages/NC_+687.svg",
  },
  {
    key: "AX",
    value: "358",
    label: "+358",
    image: "/images/countryFlages/AX_+358.svg",
  },
  {
    key: "AL",
    value: "355",
    label: "+355",
    image: "/images/countryFlages/AL_+355.svg",
  },
  {
    key: "DZ",
    value: "213",
    label: "+213",
    image: "/images/countryFlages/DZ_+213.svg",
  },
  {
    key: "AS",
    value: "1684",
    label: "+1684",
    image: "/images/countryFlages/AS_+1684.svg",
  },
  {
    key: "AD",
    value: "376",
    label: "+376",
    image: "/images/countryFlages/AD_+376.svg",
  },
  {
    key: "AO",
    value: "244",
    label: "+244",
    image: "/images/countryFlages/AO_+244.svg",
  },
  {
    key: "AI",
    value: "1264",
    label: "+1264",
    image: "/images/countryFlages/AI_+1264.svg",
  },
  {
    key: "AQ",
    value: "672",
    label: "+672",
    image: "/images/countryFlages/AQ_+672.svg",
  },
  {
    key: "AG",
    value: "1268",
    label: "+1268",
    image: "/images/countryFlages/AG_+1268.svg",
  },
  {
    key: "AR",
    value: "54",
    label: "+54",
    image: "/images/countryFlages/AR_+54.svg",
  },
  {
    key: "AM",
    value: "374",
    label: "+374",
    image: "/images/countryFlages/AM_+374.svg",
  },
  {
    key: "AW",
    value: "297",
    label: "+297",
    image: "/images/countryFlages/AW_+297.svg",
  },
  {
    key: "AU",
    value: "61",
    label: "+61",
    image: "/images/countryFlages/AU_+61.svg",
  },
  {
    key: "AT",
    value: "43",
    label: "+43",
    image: "/images/countryFlages/AT_+43.svg",
  },
  {
    key: "AZ",
    value: "994",
    label: "+994",
    image: "/images/countryFlages/AZ_+994.svg",
  },
  {
    key: "BS",
    value: "1242",
    label: "+1242",
    image: "/images/countryFlages/BS_+1242.svg",
  },
  {
    key: "BH",
    value: "973",
    label: "+973",
    image: "/images/countryFlages/BH_+973.svg",
  },
  {
    key: "BD",
    value: "880",
    label: "+880",
    image: "/images/countryFlages/BD_+880.svg",
  },
  {
    key: "BB",
    value: "1246",
    label: "+1246",
    image: "/images/countryFlages/BB_+1246.svg",
  },
  {
    key: "BY",
    value: "375",
    label: "+375",
    image: "/images/countryFlages/BY_+375.svg",
  },
  {
    key: "BE",
    value: "32",
    label: "+32",
    image: "/images/countryFlages/BE_+32.svg",
  },
  {
    key: "BZ",
    value: "501",
    label: "+501",
    image: "/images/countryFlages/BZ_+501.svg",
  },
  {
    key: "BJ",
    value: "229",
    label: "+229",
    image: "/images/countryFlages/BJ_+229.svg",
  },
  {
    key: "BM",
    value: "1441",
    label: "+1441",
    image: "/images/countryFlages/BM_+1441.svg",
  },
  {
    key: "BT",
    value: "975",
    label: "+975",
    image: "/images/countryFlages/BT_+975.svg",
  },
  {
    key: "BO",
    value: "591",
    label: "+591",
    image: "/images/countryFlages/BO_+591.svg",
  },
  {
    key: "BA",
    value: "387",
    label: "+387",
    image: "/images/countryFlages/BA_+387.svg",
  },
  {
    key: "BW",
    value: "267",
    label: "+267",
    image: "/images/countryFlages/BW_+267.svg",
  },
  {
    key: "BR",
    value: "55",
    label: "+55",
    image: "/images/countryFlages/BR_+55.svg",
  },
  {
    key: "IO",
    value: "246",
    label: "+246",
    image: "/images/countryFlages/IO_+246.svg",
  },
  {
    key: "BN",
    value: "673",
    label: "+673",
    image: "/images/countryFlages/BN_+673.svg",
  },
  {
    key: "BG",
    value: "359",
    label: "+359",
    image: "/images/countryFlages/BG_+359.svg",
  },
  {
    key: "BF",
    value: "226",
    label: "+226",
    image: "/images/countryFlages/BF_+226.svg",
  },
  {
    key: "BI",
    value: "257",
    label: "+257",
    image: "/images/countryFlages/BI_+257.svg",
  },
  {
    key: "KH",
    value: "855",
    label: "+855",
    image: "/images/countryFlages/KH_+855.svg",
  },
  {
    key: "CM",
    value: "237",
    label: "+237",
    image: "/images/countryFlages/CM_+237.svg",
  },
  {
    key: "CV",
    value: "238",
    label: "+238",
    image: "/images/countryFlages/CV_+238.svg",
  },
  {
    key: "CF",
    value: "236",
    label: "+236",
    image: "/images/countryFlages/CF_+236.svg",
  },
  {
    key: "TD",
    value: "235",
    label: "+235",
    image: "/images/countryFlages/TD_+235.svg",
  },
  {
    key: "CL",
    value: "56",
    label: "+56",
    image: "/images/countryFlages/CL_+56.svg",
  },
  {
    key: "CN",
    value: "86",
    label: "+86",
    image: "/images/countryFlages/CN_+86.svg",
  },
  {
    key: "CX",
    value: "61",
    label: "+61",
    image: "/images/countryFlages/CX_+61.svg",
  },
  {
    key: "CC",
    value: "61",
    label: "+61",
    image: "/images/countryFlages/CC_+61.svg",
  },
  {
    key: "CO",
    value: "57",
    label: "+57",
    image: "/images/countryFlages/CO_+57.svg",
  },
  {
    key: "KM",
    value: "269",
    label: "+269",
    image: "/images/countryFlages/KM_+269.svg",
  },
  {
    key: "CG",
    value: "242",
    label: "+242",
    image: "/images/countryFlages/CG_+242.svg",
  },
  {
    key: "CD",
    value: "243",
    label: "+243",
    image: "/images/countryFlages/CD_+243.svg",
  },
  {
    key: "CK",
    value: "682",
    label: "+682",
    image: "/images/countryFlages/CK_+682.svg",
  },
  {
    key: "CR",
    value: "506",
    label: "+506",
    image: "/images/countryFlages/CR_+506.svg",
  },
  {
    key: "CI",
    value: "225",
    label: "+225",
    image: "/images/countryFlages/CI_+225.svg",
  },
  {
    key: "HR",
    value: "385",
    label: "+385",
    image: "/images/countryFlages/HR_+385.svg",
  },
  {
    key: "CU",
    value: "53",
    label: "+53",
    image: "/images/countryFlages/CU_+53.svg",
  },
  {
    key: "CY",
    value: "357",
    label: "+357",
    image: "/images/countryFlages/CY_+357.svg",
  },
  {
    key: "CZ",
    value: "420",
    label: "+420",
    image: "/images/countryFlages/CZ_+420.svg",
  },
  {
    key: "DK",
    value: "45",
    label: "+45",
    image: "/images/countryFlages/DK_+45.svg",
  },
  {
    key: "DJ",
    value: "253",
    label: "+253",
    image: "/images/countryFlages/DJ_+253.svg",
  },
  {
    key: "DM",
    value: "1767",
    label: "+1767",
    image: "/images/countryFlages/DM_+1767.svg",
  },
  {
    key: "DO",
    value: "1849",
    label: "+1849",
    image: "/images/countryFlages/DO_+1849.svg",
  },
  {
    key: "EC",
    value: "593",
    label: "+593",
    image: "/images/countryFlages/EC_+593.svg",
  },
  {
    key: "EG",
    value: "20",
    label: "+20",
    image: "/images/countryFlages/EG_+20.svg",
  },
  {
    key: "SV",
    value: "503",
    label: "+503",
    image: "/images/countryFlages/SV_+503.svg",
  },
  {
    key: "GQ",
    value: "240",
    label: "+240",
    image: "/images/countryFlages/GQ_+240.svg",
  },
  {
    key: "ER",
    value: "291",
    label: "+291",
    image: "/images/countryFlages/ER_+291.svg",
  },
  {
    key: "EE",
    value: "372",
    label: "+372",
    image: "/images/countryFlages/EE_+372.svg",
  },
  {
    key: "ET",
    value: "251",
    label: "+251",
    image: "/images/countryFlages/ET_+251.svg",
  },
  {
    key: "FK",
    value: "500",
    label: "+500",
    image: "/images/countryFlages/FK_+500.svg",
  },
  {
    key: "FO",
    value: "298",
    label: "+298",
    image: "/images/countryFlages/FO_+298.svg",
  },
  {
    key: "FJ",
    value: "679",
    label: "+679",
    image: "/images/countryFlages/FJ_+679.svg",
  },
  {
    key: "FI",
    value: "358",
    label: "+358",
    image: "/images/countryFlages/FI_+358.svg",
  },
  {
    key: "FR",
    value: "33",
    label: "+33",
    image: "/images/countryFlages/FR_+33.svg",
  },
  {
    key: "GF",
    value: "594",
    label: "+594",
    image: "/images/countryFlages/GF_+594.svg",
  },
  {
    key: "PF",
    value: "689",
    label: "+689",
    image: "/images/countryFlages/PF_+689.svg",
  },
  {
    key: "GA",
    value: "241",
    label: "+241",
    image: "/images/countryFlages/GA_+241.svg",
  },
  {
    key: "GM",
    value: "220",
    label: "+220",
    image: "/images/countryFlages/GM_+220.svg",
  },
  {
    key: "GE",
    value: "995",
    label: "+995",
    image: "/images/countryFlages/GE_+995.svg",
  },
  {
    key: "DE",
    value: "49",
    label: "+49",
    image: "/images/countryFlages/DE_+49.svg",
  },
  {
    key: "GH",
    value: "233",
    label: "+233",
    image: "/images/countryFlages/GH_+233.svg",
  },
  {
    key: "GI",
    value: "350",
    label: "+350",
    image: "/images/countryFlages/GI_+350.svg",
  },
  {
    key: "GR",
    value: "30",
    label: "+30",
    image: "/images/countryFlages/GR_+30.svg",
  },
  {
    key: "GL",
    value: "299",
    label: "+299",
    image: "/images/countryFlages/GL_+299.svg",
  },
  {
    key: "GD",
    value: "1473",
    label: "+1473",
    image: "/images/countryFlages/GD_+1473.svg",
  },
  {
    key: "GP",
    value: "590",
    label: "+590",
    image: "/images/countryFlages/GP_+590.svg",
  },
  {
    key: "GU",
    value: "1671",
    label: "+1671",
    image: "/images/countryFlages/GU_+1671.svg",
  },
  {
    key: "GT",
    value: "502",
    label: "+502",
    image: "/images/countryFlages/GT_+502.svg",
  },

  {
    key: "GN",
    value: "224",
    label: "+224",
    image: "/images/countryFlages/GN_+224.svg",
  },
  {
    key: "GW",
    value: "245",
    label: "+245",
    image: "/images/countryFlages/GW_+245.svg",
  },
  {
    key: "GY",
    value: "595",
    label: "+595",
    image: "/images/countryFlages/GY_+595.svg",
  },
  {
    key: "HT",
    value: "509",
    label: "+509",
    image: "/images/countryFlages/HT_+509.svg",
  },
  {
    key: "VA",
    value: "379",
    label: "+379",
    image: "/images/countryFlages/VA_+379.svg",
  },
  {
    key: "HN",
    value: "504",
    label: "+504",
    image: "/images/countryFlages/HN_+504.svg",
  },
  {
    key: "HK",
    value: "852",
    label: "+852",
    image: "/images/countryFlages/HK_+852.svg",
  },
  {
    key: "HU",
    value: "36",
    label: "+36",
    image: "/images/countryFlages/HU_+36.svg",
  },
  {
    key: "IS",
    value: "354",
    label: "+354",
    image: "/images/countryFlages/IS_+354.svg",
  },
  {
    key: "IN",
    value: "91",
    label: "+91",
    image: "/images/countryFlages/IN_+91.svg",
  },
  {
    key: "ID",
    value: "62",
    label: "+62",
    image: "/images/countryFlages/ID_+62.svg",
  },
  {
    key: "IR",
    value: "98",
    label: "+98",
    image: "/images/countryFlages/IR_+98.svg",
  },
  {
    key: "IQ",
    value: "964",
    label: "+964",
    image: "/images/countryFlages/IQ_+964.svg",
  },
  {
    key: "IE",
    value: "353",
    label: "+353",
    image: "/images/countryFlages/IE_+353.svg",
  },
  {
    key: "IL",
    value: "972",
    label: "+972",
    image: "/images/countryFlages/IL_+972.svg",
  },
  {
    key: "IT",
    value: "39",
    label: "+39",
    image: "/images/countryFlages/IT_+39.svg",
  },
  {
    key: "JM",
    value: "1876",
    label: "+1876",
    image: "/images/countryFlages/JM_+1876.svg",
  },
  {
    key: "JP",
    value: "81",
    label: "+81",
    image: "/images/countryFlages/JP_+81.svg",
  },
  {
    key: "JO",
    value: "962",
    label: "+962",
    image: "/images/countryFlages/JO_+962.svg",
  },
  {
    key: "KZ",
    value: "77",
    label: "+77",
    image: "/images/countryFlages/KZ_+77.svg",
  },
  {
    key: "KE",
    value: "254",
    label: "+254",
    image: "/images/countryFlages/KE_+254.svg",
  },
  {
    key: "KI",
    value: "686",
    label: "+686",
    image: "/images/countryFlages/KI_+686.svg",
  },
  {
    key: "KP",
    value: "850",
    label: "+850",
    image: "/images/countryFlages/KP_+850.svg",
  },
  {
    key: "KR",
    value: "82",
    label: "+82",
    image: "/images/countryFlages/KR_+82.svg",
  },
  {
    key: "KW",
    value: "965",
    label: "+965",
    image: "/images/countryFlages/KW_+965.svg",
  },
  {
    key: "KG",
    value: "996",
    label: "+996",
    image: "/images/countryFlages/KG_+996.svg",
  },
  {
    key: "LA",
    value: "856",
    label: "+856",
    image: "/images/countryFlages/LA_+856.svg",
  },
  {
    key: "LV",
    value: "371",
    label: "+371",
    image: "/images/countryFlages/LV_+371.svg",
  },
  {
    key: "LB",
    value: "961",
    label: "+961",
    image: "/images/countryFlages/LB_+961.svg",
  },
  {
    key: "LS",
    value: "266",
    label: "+266",
    image: "/images/countryFlages/LS_+266.svg",
  },
  {
    key: "LR",
    value: "231",
    label: "+231",
    image: "/images/countryFlages/LR_+231.svg",
  },
  {
    key: "LY",
    value: "218",
    label: "+218",
    image: "/images/countryFlages/LY_+218.svg",
  },
  {
    key: "LI",
    value: "423",
    label: "+423",
    image: "/images/countryFlages/LI_+423.svg",
  },
  {
    key: "LT",
    value: "370",
    label: "+370",
    image: "/images/countryFlages/LT_+370.svg",
  },
  {
    key: "LU",
    value: "352",
    label: "+352",
    image: "/images/countryFlages/LU_+352.svg",
  },
  {
    key: "MO",
    value: "853",
    label: "+853",
    image: "/images/countryFlages/MO_+853.svg",
  },
  {
    key: "MK",
    value: "389",
    label: "+389",
    image: "/images/countryFlages/MK_+389.svg",
  },
  {
    key: "MG",
    value: "261",
    label: "+261",
    image: "/images/countryFlages/MG_+261.svg",
  },
  {
    key: "MW",
    value: "265",
    label: "+265",
    image: "/images/countryFlages/MW_+265.svg",
  },
  {
    key: "MY",
    value: "60",
    label: "+60",
    image: "/images/countryFlages/MY_+60.svg",
  },
  {
    key: "MV",
    value: "960",
    label: "+960",
    image: "/images/countryFlages/MV_+960.svg",
  },
  {
    key: "ML",
    value: "223",
    label: "+223",
    image: "/images/countryFlages/ML_+223.svg",
  },
  {
    key: "MT",
    value: "356",
    label: "+356",
    image: "/images/countryFlages/MT_+356.svg",
  },
  {
    key: "MH",
    value: "692",
    label: "+692",
    image: "/images/countryFlages/MH_+692.svg",
  },
  {
    key: "MQ",
    value: "596",
    label: "+596",
    image: "/images/countryFlages/MQ_+596.svg",
  },
  {
    key: "MR",
    value: "222",
    label: "+222",
    image: "/images/countryFlages/MR_+222.svg",
  },
  {
    key: "MU",
    value: "230",
    label: "+230",
    image: "/images/countryFlages/MU_+230.svg",
  },
  {
    key: "YT",
    value: "262",
    label: "+262",
    image: "/images/countryFlages/YT_+262.svg",
  },
  {
    key: "MX",
    value: "52",
    label: "+52",
    image: "/images/countryFlages/MX_+52.svg",
  },
  {
    key: "FM",
    value: "691",
    label: "+691",
    image: "/images/countryFlages/FM_+691.svg",
  },
  {
    key: "MD",
    value: "373",
    label: "+373",
    image: "/images/countryFlages/MD_+373.svg",
  },
  {
    key: "MC",
    value: "377",
    label: "+377",
    image: "/images/countryFlages/MC_+377.svg",
  },
  {
    key: "MN",
    value: "976",
    label: "+976",
    image: "/images/countryFlages/MN_+976.svg",
  },
  {
    key: "ME",
    value: "382",
    label: "+382",
    image: "/images/countryFlages/ME_+382.svg",
  },
  {
    key: "MS",
    value: "1664",
    label: "+1664",
    image: "/images/countryFlages/MS_+1664.svg",
  },
  {
    key: "MA",
    value: "212",
    label: "+212",
    image: "/images/countryFlages/MA_+212.svg",
  },
  {
    key: "MZ",
    value: "258",
    label: "+258",
    image: "/images/countryFlages/MZ_+258.svg",
  },
  {
    key: "MM",
    value: "95",
    label: "+95",
    image: "/images/countryFlages/MM_+95.svg",
  },
  {
    key: "NA",
    value: "264",
    label: "+264",
    image: "/images/countryFlages/NA_+264.svg",
  },
  {
    key: "NR",
    value: "674",
    label: "+674",
    image: "/images/countryFlages/NR_+674.svg",
  },
  {
    key: "NP",
    value: "977",
    label: "+977",
    image: "/images/countryFlages/NP_+977.svg",
  },
  {
    key: "NL",
    value: "31",
    label: "+31",
    image: "/images/countryFlages/NL_+31.svg",
  },
  {
    key: "NZ",
    value: "64",
    label: "+64",
    image: "/images/countryFlages/NZ_+64.svg",
  },
  {
    key: "NI",
    value: "505",
    label: "+505",
    image: "/images/countryFlages/NI_+505.svg",
  },
  {
    key: "NE",
    value: "227",
    label: "+227",
    image: "/images/countryFlages/NE_+227.svg",
  },
  {
    key: "NG",
    value: "234",
    label: "+234",
    image: "/images/countryFlages/NG_+234.svg",
  },
  {
    key: "NU",
    value: "683",
    label: "+683",
    image: "/images/countryFlages/NU_+683.svg",
  },
  {
    key: "NF",
    value: "672",
    label: "+672",
    image: "/images/countryFlages/NF_+672.svg",
  },
  {
    key: "MP",
    value: "1670",
    label: "+1670",
    image: "/images/countryFlages/MP_+1670.svg",
  },
  {
    key: "NO",
    value: "47",
    label: "+47",
    image: "/images/countryFlages/NO_+47.svg",
  },
  {
    key: "OM",
    value: "968",
    label: "+968",
    image: "/images/countryFlages/OM_+968.svg",
  },
  {
    key: "PK",
    value: "92",
    label: "+92",
    image: "/images/countryFlages/PK_+92.svg",
  },
  {
    key: "PW",
    value: "680",
    label: "+680",
    image: "/images/countryFlages/PW_+680.svg",
  },
  {
    key: "PS",
    value: "970",
    label: "+970",
    image: "/images/countryFlages/PS_+970.svg",
  },
  {
    key: "PA",
    value: "507",
    label: "+507",
    image: "/images/countryFlages/PA_+507.svg",
  },
  {
    key: "PG",
    value: "675",
    label: "+675",
    image: "/images/countryFlages/PG_+675.svg",
  },
  {
    key: "PY",
    value: "595",
    label: "+595",
    image: "/images/countryFlages/PY_+595.svg",
  },
  {
    key: "PE",
    value: "51",
    label: "+51",
    image: "/images/countryFlages/PE_+51.svg",
  },
  {
    key: "PH",
    value: "63",
    label: "+63",
    image: "/images/countryFlages/PH_+63.svg",
  },
  {
    key: "PN",
    value: "872",
    label: "+872",
    image: "/images/countryFlages/PN_+872.svg",
  },
  {
    key: "PL",
    value: "48",
    label: "+48",
    image: "/images/countryFlages/PL_+48.svg",
  },
  {
    key: "PT",
    value: "351",
    label: "+351",
    image: "/images/countryFlages/PT_+351.svg",
  },
  {
    key: "PR",
    value: "1939",
    label: "+1939",
    image: "/images/countryFlages/PR_+1939.svg",
  },
  {
    key: "QA",
    value: "974",
    label: "+974",
    image: "/images/countryFlages/QA_+974.svg",
  },
  {
    key: "RO",
    value: "40",
    label: "+40",
    image: "/images/countryFlages/RO_+40.svg",
  },
  {
    key: "RU",
    value: "7",
    label: "+7",
    image: "/images/countryFlages/RU_+7.svg",
  },
  {
    key: "RW",
    value: "250",
    label: "+250",
    image: "/images/countryFlages/RW_+250.svg",
  },
  {
    key: "RE",
    value: "262",
    label: "+262",
    image: "/images/countryFlages/RE_+262.svg",
  },
  {
    key: "BL",
    value: "590",
    label: "+590",
    image: "/images/countryFlages/BL_+590.svg",
  },
  {
    key: "SH",
    value: "290",
    label: "+290",
    image: "/images/countryFlages/SH_+290.svg",
  },
  {
    key: "KN",
    value: "1869",
    label: "+1869",
    image: "/images/countryFlages/KN_+1869.svg",
  },
  {
    key: "LC",
    value: "1758",
    label: "+1758",
    image: "/images/countryFlages/LC_+1758.svg",
  },
  {
    key: "MF",
    value: "590",
    label: "+590",
    image: "/images/countryFlages/MF_+590.svg",
  },
  {
    key: "PM",
    value: "508",
    label: "+508",
    image: "/images/countryFlages/PM_+508.svg",
  },
  {
    key: "VC",
    value: "1784",
    label: "+1784",
    image: "/images/countryFlages/VC_+1784.svg",
  },
  {
    key: "WS",
    value: "685",
    label: "+685",
    image: "/images/countryFlages/WS_+685.svg",
  },
  {
    key: "SM",
    value: "378",
    label: "+378",
    image: "/images/countryFlages/SM_+378.svg",
  },
  {
    key: "ST",
    value: "239",
    label: "+239",
    image: "/images/countryFlages/ST_+239.svg",
  },
  {
    key: "SA",
    value: "966",
    label: "+966",
    image: "/images/countryFlages/SA_+966.svg",
  },
  {
    key: "SN",
    value: "221",
    label: "+221",
    image: "/images/countryFlages/SN_+221.svg",
  },
  {
    key: "RS",
    value: "381",
    label: "+381",
    image: "/images/countryFlages/RS_+381.svg",
  },
  {
    key: "SC",
    value: "248",
    label: "+248",
    image: "/images/countryFlages/SC_+248.svg",
  },
  {
    key: "SL",
    value: "232",
    label: "+232",
    image: "/images/countryFlages/SL_+232.svg",
  },
  {
    key: "SG",
    value: "65",
    label: "+65",
    image: "/images/countryFlages/SG_+65.svg",
  },
  {
    key: "SK",
    value: "421",
    label: "+421",
    image: "/images/countryFlages/SK_+421.svg",
  },
  {
    key: "SI",
    value: "386",
    label: "+386",
    image: "/images/countryFlages/SI_+386.svg",
  },
  {
    key: "SB",
    value: "677",
    label: "+677",
    image: "/images/countryFlages/SB_+677.svg",
  },
  {
    key: "SO",
    value: "252",
    label: "+252",
    image: "/images/countryFlages/SO_+252.svg",
  },
  {
    key: "ZA",
    value: "27",
    label: "+27",
    image: "/images/countryFlages/ZA_+27.svg",
  },
  {
    key: "SS",
    value: "211",
    label: "+211",
    image: "/images/countryFlages/SS_+211.svg",
  },
  {
    key: "GS",
    value: "500",
    label: "+500",
    image: "/images/countryFlages/GS_+500.svg",
  },
  {
    key: "ES",
    value: "34",
    label: "+34",
    image: "/images/countryFlages/ES_+34.svg",
  },
  {
    key: "LK",
    value: "94",
    label: "+94",
    image: "/images/countryFlages/LK_+94.svg",
  },
  {
    key: "SD",
    value: "249",
    label: "+249",
    image: "/images/countryFlages/SD_+249.svg",
  },
  {
    key: "SR",
    value: "597",
    label: "+597",
    image: "/images/countryFlages/SR_+597.svg",
  },
  {
    key: "SJ",
    value: "47",
    label: "+47",
    image: "/images/countryFlages/SJ_+47.svg",
  },
  {
    key: "SZ",
    value: "268",
    label: "+268",
    image: "/images/countryFlages/SZ_+268.svg",
  },
  {
    key: "SE",
    value: "46",
    label: "+46",
    image: "/images/countryFlages/SE_+46.svg",
  },
  {
    key: "CH",
    value: "41",
    label: "+41",
    image: "/images/countryFlages/CH_+41.svg",
  },
  {
    key: "SY",
    value: "963",
    label: "+963",
    image: "/images/countryFlages/SY_+963.svg",
  },
  {
    key: "TW",
    value: "886",
    label: "+886",
    image: "/images/countryFlages/TW_+886.svg",
  },
  {
    key: "TJ",
    value: "992",
    label: "+992",
    image: "/images/countryFlages/TJ_+992.svg",
  },
  {
    key: "TZ",
    value: "255",
    label: "+255",
    image: "/images/countryFlages/TZ_+255.svg",
  },
  {
    key: "TH",
    value: "66",
    label: "+66",
    image: "/images/countryFlages/TH_+66.svg",
  },
  {
    key: "TL",
    value: "670",
    label: "+670",
    image: "/images/countryFlages/TL_+670.svg",
  },
  {
    key: "TG",
    value: "228",
    label: "+228",
    image: "/images/countryFlages/TG_+228.svg",
  },
  {
    key: "TK",
    value: "690",
    label: "+690",
    image: "/images/countryFlages/TK_+690.svg",
  },
  {
    key: "TO",
    value: "676",
    label: "+676",
    image: "/images/countryFlages/TO_+676.svg",
  },
  {
    key: "TT",
    value: "1868",
    label: "+1868",
    image: "/images/countryFlages/TT_+1868.svg",
  },
  {
    key: "TN",
    value: "216",
    label: "+216",
    image: "/images/countryFlages/TN_+216.svg",
  },
  {
    key: "TR",
    value: "90",
    label: "+90",
    image: "/images/countryFlages/TR_+90.svg",
  },
  {
    key: "TM",
    value: "993",
    label: "+993",
    image: "/images/countryFlages/TM_+993.svg",
  },
  {
    key: "TC",
    value: "1649",
    label: "+1649",
    image: "/images/countryFlages/TC_+1649.svg",
  },
  {
    key: "TV",
    value: "688",
    label: "+688",
    image: "/images/countryFlages/TV_+688.svg",
  },
  {
    key: "UG",
    value: "256",
    label: "+256",
    image: "/images/countryFlages/UG_+256.svg",
  },
  {
    key: "UA",
    value: "380",
    label: "+380",
    image: "/images/countryFlages/UA_+380.svg",
  },
  {
    key: "AE",
    value: "971",
    label: "+971",
    image: "/images/countryFlages/AE_+971.svg",
  },
  {
    key: "UY",
    value: "598",
    label: "+598",
    image: "/images/countryFlages/UY_+598.svg",
  },
  {
    key: "UZ",
    value: "998",
    label: "+998",
    image: "/images/countryFlages/UZ_+998.svg",
  },
  {
    key: "VU",
    value: "678",
    label: "+678",
    image: "/images/countryFlages/VU_+678.svg",
  },
  {
    key: "VE",
    value: "58",
    label: "+58",
    image: "/images/countryFlages/VE_+58.svg",
  },
  {
    key: "VN",
    value: "84",
    label: "+84",
    image: "/images/countryFlages/VN_+84.svg",
  },
  {
    key: "VG",
    value: "1284",
    label: "+1284",
    image: "/images/countryFlages/VG_+1284.svg",
  },
  {
    key: "VI",
    value: "1340",
    label: "+1340",
    image: "/images/countryFlages/VI_+1340.svg",
  },
  {
    key: "WF",
    value: "681",
    label: "+681",
    image: "/images/countryFlages/WF_+681.svg",
  },
  {
    key: "YE",
    value: "967",
    label: "+967",
    image: "/images/countryFlages/YE_+967.svg",
  },
  {
    key: "ZM",
    value: "260",
    label: "+260",
    image: "/images/countryFlages/ZM_+260.svg",
  },
  {
    key: "ZW",
    value: "263",
    label: "+263",
    image: "/images/countryFlages/ZW_+263.svg",
  },
  {
    key: "NA",
    value: "599",
    label: "+599",
    image: "/images/countryFlages/NA_+599.svg",
  },
];

export default countryCodes;
