// Created By ND
//All Default Text:
const ConstantsHelper = {
  // passRegxStr: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  passRegxStr: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
  linkRegxStr:
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  emailIdRegex:
    /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/i,
  nameRegex: /^[a-zA-Z]+[ ]{0,1}[a-zA-Z ]*$/,
  gstNoRegex:
    /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/,
  cardHolderNameRegex: /^(?![0-9]*$)[a-zA-Z0-9]+[ ]{0,1}[a-zA-Z0-9 ]*$/,
  // locationNameRegex: /^([A-Za-z0-9_./&,\s]*)$/,
  locationNameRegex: /^([\p{L}\p{M}A-Za-z0-9_./&,\s]*)$/u,
  mobileNoRegex: /^(?!0+$)[0-9\(\)-]{10,}$/,
  mobileNRegex: /^(?!0+$)[0-9\(\)-]{9,}$/,
  googleMapsApiKey: "AIzaSyAg1DPUqnLyuFzwZBshW75XAq-8hIHH66g",
  googleAuthClientId:
    "121688494411-81vjgetc6sgcqlea25ntcd4a90virclu.apps.googleusercontent.com",
  faceBookAuthAppId: "914599946171188",
  dummySelectFiller: "¿",
  currencySign: "$",
  searchTitle: "search",
  srchplchTxt: "Search Here",
  noData: "No Data Found!",
  allTitle: "All",
  selAllTitle: "Select All",
  orTitle: "OR",
  addTitle: "Add",
  editTitle: "Edit",
  updateStatus: "Update Status",
  changeTitle: "Change",
  deleteTitle: "Delete",
  deleteUGPTitle: "Remove User From Group",
  multipleDelete: "Multiple Delete",
  saveTitle: "Save",
  updateTitle: "Update",
  viewTitle: "View",
  closeTitle: "Close",
  redemMeTitle: "Redeem Me",
  downloadTitle: "Download",
  checkAllTitle: "Check All",
  okTitle: "Ok",
  okayTitle: "Okay",
  yesTitle: "Yes",
  YESTitle: "YES",
  noTitle: "No",
  NOTitle: "NO",
  cancelTitle: "Cancel",
  submitTitle: "Submit",
  removeTitle: "Remove",
  applyTitle: "Apply",
  activeTitle: "Active",
  inActiveTitle: "InActive",
  deActiveTitle: "De-Active",
  sendTitle: "Send",
  qrTitle: "QR",
  timelineTitle: "TimeLine",
  subscribeTitle: "Subscribe",
  unsubscribeTitle: "Unsubscribe",
  errorOccurdMsg: "Error Occured",
  userErrorMsg: "Sorry, An Error Occured !!!",
  noDataFoundMsg: "No Data Found !!!",
  fetchErrorMsg: "Data Fetch Error !!!",
  continueTitle: "Continue",
  yesterdayTitle: "Yesterday",
  CSVTitle: "Export As CSV",
  ExcelTitle: "Export As Excel",
  backTitle: "Back",
  releaseRefreshTitle: "Release to refresh",
  pullRefreshTitle: "Pull down to refresh",
  loadT: "Loading...",
  noPermission: "You don't have permission to perform this action!!",
  invalidForm: "Please check form properly, some details invalid in form?",
  wifiCapT: "WIFI",
  cfT: "Custom Forms",
  impT: "Import",
  toTitle: "to",
  EntrItmN: "Enter item name",
  EntrDisc: "Enter discount",
  agrT: "I agree with",

  // signin/ signup
  singUpForm: "Create Your Account",
  signUpSubTitle: "Login now to access the latest insights",
  alreadyRegTitle: "Already have a Menuonline Account? ",
  sendOtpTitle: "Send OTP",
  signInTitle: "Sign In",
  reservedTitle: "Copyrights ©2023 Menuonline. All rights reserved.",
  termsTitle: "Terms of Service",
  policyTitle: "Privacy Policy",
  digitCodeTitle: "Kindly enter 4 digit code which sent on ",
  notReceiveTitle: "I didn't receive a verification code!",
  resendTitle: " Please resend",
  signUpTitle: "Sign Up",
  temsTitle: "Terms & Conditions",
  termsSubTitle: "Terms & Conditions",
  businessTypeTitle: "Business Type",
  businessTypeSubTitle:
    "Kindly select one of the business type which describe your buiness better!",
  salonTitle: "Salon",
  restarantTitle: "Restaurant",
  pizzaStoreTitle: "Pizza Store",
  foodTruckTitle: "Food Truck",
  checkDomainTitle: "Check Domain Name",
  startExploringTitle: "Start Exploring",
  signInForm: "Login to your account",
  signInSubTitle: "Login now to access the latest insights",
  rememberTitle: "Remember Me",
  otpTitle: "OTP",
  passwordTitle: "Password",
  forgotTitle: "Forgot Password?",
  notRegTitle: "Don’t have an account? ",
  createAccTitle: "Sign up now",
  createFormTitle: "Create Form",
  firstNameTitle: "First name",
  lastNameTitle: "Last name",
  emailTitle: "Email",
  mobileNumberTitle: "Mobile Number",
  birthdayTitle: "Birthday",
  genderTitle: "Gender",
  addressTitle: "Address",
  shortAnswerTitle: "Short answer",
  longAnswerTitle: "Long answer",
  singleAnswerTitle: "Single answer",
  singleCheckboxTitle: "Single checkbox",
  multipleChoiceTitle: "Multiple choice",
  dropDownTitle: "Drop-down",
  yesNoTitle: "Yes or No",
  descriptionTextTitle: "Description text",
  fileUpload: "File upload",
  resetPassForm: "Reset Your Password",
  resetPassSubTitle:
    "Quickly reset your password, and get access to your account.",
  rememberPassTitle: "Remembered password?",
  resetPassTitle: "Reset Password",
  reqFullNameTitle: "Full Name is required",
  reqFNameTitle: "First name is required",
  validFNameTitle: "Enter valid first name",
  validFullNameTitle: "Enter valid full name",
  reqBlockTime: "Block Time is required",
  reqLNameTitle: "Last name is required",
  validLNameTitle: "Enter valid last name",
  validMoNoTitle: "Enter valid mobile number",
  reqMoNoTitle: "Mobile number is required",
  reqAlMoNoTitle: "Mobile number is already required",
  validOtpTitle: "Enter valid otp",
  validEmailTitle: "Enter a valid email",
  reqEmailTitle: "Email is required",
  reqDescTitle: "Description is required",
  reqDesc500Chr: "Please enter at least 500 characters",
  reqDesc600Chr: "Please enter Maximum 600 characters",
  domainT: "Domain Name",

  reqPassTitle: "Password is required",
  reqPassRegxTitle:
    "Must contain a minimum of 8 characters, at least one uppercase character, one lowercase character, one number, and one special case character",
  reqTermsTitle: "Please agree to terms & conditions",
  reqNoteTitle: "Note is required",
  reqBirthdayTitle: "Birthday is required",
  noAccessT:
    "You have a no access right now. please contact your administrator",

  // sub user login
  pinTitle: "Pin",
  validPinTitle: "Enter valid pin",
  reqPinTitle: "Login With Your 4 Digit Pin",
  suLoginSubTitle: "Login now to access the latest insignts",

  // main screen
  morT: "Good Morning",
  afternT: "Good Afternooon",
  evenT: "Good Evening",
  BillT: "Billing",
  widT: "List of Widgets",
  delwidT: "This widget will be deleted permanetly.",
  delMwidT: "This widgets will be deleted permanetly.",
  creWid: "Create Widget",
  SiUpWidT: "Web E-signup",
  signWidT: "E-signup",
  signWidDesc:
    "Build a widget for signing up, creating landing pages, and generating coupons from  your website or social media",
  qrWidT: "Smart QR",
  qrWidDesc:
    "After creating a group, you can generate and select a smart QR code, enabling customers to opt in instantly into a group",
  imgWidT: "Opt in number",
  imgWidDesc: `After creating a group, Image widget enabling customers to opt in using text and receive promotions, for example, text "pizza" to 555-555-5555`,
  userFL: "List of Clients",
  digiSignT: "Digital Signage",
  schDT: "Scheduledown",
  suppT: "Support",
  marketT: "Marketing",
  loyalProT: "Loyalty Punch Card",
  repuT: "Get More Reviews",
  repuTltMin: "REP",
  instMenu: "Instant Menu",
  getMoreReviews: "Get More Reviews",

  // side bar
  menuT: "MENU",
  menuItemT: "MENU ITEM",
  mngCustT: "Manage Customization",
  dBT: "Dashboard",
  bST: "Blast Campaign",
  bCSTitle: "Blast Campaigns",
  bSHSubTitle: "Launch blast campaigns to reach your all clients in few clicks",
  bSSubTitle: "Choose from readymade templates or make your own",
  sMsgITitle: "SMS/MMS Summary",
  aRT: "Auto Responders",
  aRHT: "Auto Responder",
  aRSubT: "Easy to use event-based promotions for your clients",
  toolsTitle: "Tools",
  clientsT: "Clients",
  clientT: "Clients Data",
  groupsT: "Groups Data",
  anT: "Analytics",
  busST: "Business Settings",
  accT: "Account",
  payT: "Payments",
  InvoiceT: "Invoices",
  helpCT: "Help Center",
  MeetRec: "Meeting Records",
  ReSel: "Reseller",
  logOutT: "Logout",
  switchToHubT: "Switch to Hub",
  sWifiT: "Guest Smart WiFi",
  menuOnT: "Menuonline",
  menuOnTitl: "Menuonline",
  scheduleT: "Schedule Access",
  goodDT: "Good day",
  managerT: "Manager",
  cashT: "Cashier",
  waitT: "Waiter",
  smartQR: "Smart QR",
  imageError1MB: "Please insert image that is less than 1mb",
  imageError: "Please insert image that is less than 2.5mb",
  imageError3Mb: "Please insert image that is less than 3mb",
  imageInVldError: "File is invalid.",
  deleteAllClientsMsg: "Are you sure you want to delete all clients? They cannot be retrieved.",
  deleteAllClientsTxt: "Delete All Clients",

  //Blast Campaign
  mktBSTitle: "Blast SMS",
  step1: "Customize Message",
  step2: "Choose Audience",
  step3: "Schedule message",
  step4: "Send a message",
  step5: "Manage message",
  bSTabT: "Blast Campaign Summary",
  bSTitle: "Choose a Campaign Type",
  SMSTitle: "SMS/MMS",
  WPTitle: "Whatsapp",
  envCap: "Offer exclusive discounts and promotions to entice your customers",
  // envCap: " Send bulk coupon to your client/s",
  cmtCaption: "Reach out to customer through text and multimedia messages",
  // cmtCaption: "Send bulk SMS/MMS to your client/s",
  WPCaption:
    "Connect with patrons using WhatsApp for seamless and direct communication",
  // WPCaption: "Send bulk WhatsApp messages to your client/s",
  msgPrevTitle: " Message Preview",
  couPrevTitle: " Coupon Preview",
  blastSMS: "SMS/MMS Campaign",
  bsPMsg: "In Queue",
  bSSMsgC: "Failed",
  bSFMsgCount: "Delivered",
  previous: "Previous",
  nextST: "Next",
  nextS: "Next Step",
  reqTempNameTitle: "Template name is required",
  reqTemplateTxTitle: "Template text is required",
  addTempTitle: "Add Template",
  linkDesc:
    "Add a link to your choice so client can book with you via facebook,Google or your own website.",
  audienceTitle: "Audience",
  clientG1T: "New Clients",
  clientG2T: "Recent Clients",
  clientG3T: "Loyal Clients",
  clientG4T: "Lapsed Clients",
  clientG5T: "Clients by Connection Date",
  onWhichDay: "On Which Day",
  onDay: "On Day",
  immediateDesc:
    "Your message will be sent immediately, please click Next to begin.",
  neverDesc:
    "This campaign is scheduled to run forever until you end it manually.",
  endDesc: "The campaign will end on the date you choose from here.",
  successMessage: "campaign saved successfully!",
  sendSuccessMessage: "campaign sent successfully!",
  msgNameFieldTitle: "Campaign Title",
  validCTErrT: "Enter valid Campaign title",
  msgTypeTitle: "Message Type",
  smsDesc: "1 Credit per SMS",
  mmsDesc: "3 Credits per MMS",
  clientGTO1: "Day",
  clientGTO2: "Hours",
  clientGTO3: "Minutes",
  clientGTO4: "Week",
  TimeOption1: "Day",
  TimeOption2: "Hours",
  TimeOption3: "Minutes",
  cGrpDay1: "90  Days",
  cGrpDay2: "60  Days",
  cGrpDay3: "30  Days",
  firstT: "First",
  secondT: "Second",
  thirdT: "Third",
  fourthT: "Fourth",
  fifthT: "Fifth",
  deleteBST: "This campaign will be deleted permanetly.",
  deleteMBST: "This campaigns will be deleted permanetly.",
  cFT1: "Phone",
  cFT2: "Contact first name",
  cFT3: "Contact last name",
  cFT4: "Contact email",
  cFT5: "Note",
  cFT6: "Birthday",
  cFT7: "Company name",
  cFMetaTag1: "%%phone%%",
  cFMetaTag2: "%%firstname%%",
  cFMetaTag3: "%%lastname%%",
  cFMetaTag4: "%%email%%",
  cFMetaTag5: "%%note%%",
  cFMetaTag6: "%%birthday%%",
  cFMetaTag7: "%%company_name%%",
  cFName1: "phone",
  cFName2: "firstname",
  cFName3: "lastname",
  cFName4: "email",
  cFName5: "note",
  cFName6: "birthday",
  cFName7: "company_name",
  audType1: "AllClients",
  audType2: "ClientGroups",
  audType3: "CustomerGroups",
  audTypeT1: "All Clients",
  audTypeT2: "Client Groups",
  audTypeT3: "Customer Groups",
  clientG1: "New Clients",
  clientG2: "Recent Clients",
  clientG3: "Loyal Clients",
  clientG4: "Lapsed Clients",
  clientG5: "Clients by Connection Date",
  endT1: "Never",
  endT2: "Ending on",
  sendT1: "Immediately",
  sendT2: "Scheduled",
  sendType3: "Recurring",
  dailyT: "Daily",
  weeklyT: "Weekly",
  monthlyT: "Monthly",
  yearlyT: "Yearly",
  eachT: "Each",
  onTheT: "On The",
  smDay1: "MON",
  smDay2: "TUE",
  smDay3: "WED",
  smDay4: "THU",
  smDay5: "FRI",
  smDay6: "SAT",
  smDay7: "SUN",
  lgDay1: "Monday",
  lgDay2: "Tuesday",
  lgDay3: "Wednesday",
  lgDay4: "Thursday",
  lgDay5: "Friday",
  lgDay6: "Saturday",
  lgDay7: "Sunday",
  msgPreT: "Dominos",
  cAEditT1: "All",
  cAEditT2: "Included",
  cAEditT3: "Excluded",
  SMS: "SMS",
  MMS: "MMS",
  usdT: "USD",
  cadT: "CAD",
  Msg: "Message",
  msg: "message",
  whichDay: "Which Days",
  endDateTx: "End Date",
  sDateErr: "Start date must be greater then or equal to today",
  eDateErr: "End date must be later than start date",
  startDateReq: "Start date is required",
  endDateReq: "End date is required",
  timeReq: "Time is required",
  clientGT1: "Clients subscribed in the last",
  clientGT2: "Clients with connection in the last",
  clientGT3: "Clients with at least",
  clientGT4: "with the last",
  clientGT5: "who did not return in the last",
  dummyPhoneNo: "+91987-654-3210",
  TestT: "Test",
  dummybirthDate: "01-01-2001",
  imageReq: "Media or url is required",
  timeTitle: "Time",
  sDateT: "Start Date",
  autoSendT: "Automatically send to clients",
  addMedia: "Add Media",
  inTitle: "In",
  cTempT: "Campaign Template",
  tempNT: "Template Name",
  TypeT: "Type",
  selTempT: "Select template type",
  smsTempT: "SMS Template",
  tempT: "Template Text",
  defaultMsg:
    "Hey %%firstname%%, Special discount on brunch! Dine with us today and get exclusive 10% discount on your bill!",
  refillT: "Refill",
  avlCredit: "Available credits",
  reqCredit: "Total credits required",
  remCredit: "Remaning Credit",
  refilCredit: "Credits to be refilled",
  clientsReq: "Select at least one client",
  subclientReq: "Selected clients are not subscriber.",
  refillDesc: "Please refill the required credits",
  urlRegex: "^((http|https)://).....+$",
  sdInValid: "Start date is invalid",
  edInValid: "End date is invalid",
  imgUrlInvalid: "Invalid image url",
  timeInvalid: "Time is invalid",
  timeAndDateInvalid: "Enter valid date and time",
  timeInvalidBfr: "Select time at least 5 minutes later from current time ",
  sodReq: "Send on day is required",
  addCard: "Add a New Card",
  smDRepTitle: "SMS/MMS Delivery Report",
  sendNowT: "Send now",
  schdNowT: "Schedule SMS",
  testSMST: "Send Test SMS",
  saveADT: "Save as Draft",
  backTET: "Back to Edit",
  bcDoneDesc:
    "Your blast text message is ready. Select your payment method and send your messages.",
  bcDoneSchDesc:
    "You can modify the campaign 30 minutes before its start time. Charges for SMS campaign will be proceed 30 minutes prior to the campaign's start time.",
  payAtT: " The payment will be completed at ",
  totlCostT: "Total Cost",
  plSelPayMT: "Please select the payment method",
  emojiT: "Emojis",
  mediaT: "Add Media",
  cfieldT: "Insert Custom Fields",
  sLinkT: "Add Shortlink",
  uTemT: "Use Template",

  //AutoResponder
  arTitle: "Autoresponder Campaigns",
  arDesc: "Enable the autoresponder and launch the personalized campaign",
  arMenusTitle1: "Birthday special",
  arMenusDesc1:
    "Automated message sent out on their birthdays with personalized greetings and tempting offers",
  arMenusEditDesc1:
    "Automated message sent out on their birthdays with personalized greetings and tempting offers",
  // arMenusDesc1: "Send personalised birthday greetings with a special offer",
  // arMenusEditDesc1:
  //   "Send personalised birthday greetings with a special offer.",
  arMenuCATitle1: "Client by Birthday",
  arMenuCADesc1: "Choose target audience",
  arMenuCABoxDesc1: "This campaign automatically sends to clients once a year ",
  arMenuUCDesc1:
    "This auto campaign sends to clients with birthdays, you can customize the targeting below.",

  arMenusTitle2: "Welcome new clients",
  arMenusDesc2:
    "Make a lasting impression by warmly greeting and welcoming first-time diners",
  // arMenusDesc2: "Greet your new customers in the most pesonalized way",
  arMenusEditDesc2:
    "This campaign will send out welcome greetings to new customers on following day of their first interaction with your business",
  arMenuCATitle2: "New Clients",
  arMenuCADesc2: "This autoresponder's audience is predefined, we've got this!",
  arMenuCABoxDesc21:
    "This autoresponder is set to send clients subscribed in the last ",
  arMenuCABoxDesc22: "  after their first interaction.",
  arMenuUCDesc2: "Welcome new clients update campaign text",

  arMenusTitle3: "Invite clients to join loyalty program",
  arMenusDesc3: "Send promotional WhatsApp messages to your clients",
  arMenusEditDesc3:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit vehicula dui sit amet ligula cursus.dolor sit amet dolor adipiscing.",
  arMenuCATitle3: "Online booking clients",
  arMenuCADesc3: "This auto campaign audience is predefined, we've got this!",
  arMenuCABoxDesc3:
    "This auto campaign automatically sends maximum once a month, to clients who never booked online, one day after they make a connection",
  arMenuUCDesc3: "Invite clients to join loyalty program update campaign text",

  arMenusTitle4: "Win back clients",
  arMenusDesc4:
    "Re-engage past customers with irresistible offers to encourage them to return",
  // arMenusDesc4: "Retain clients with special offerings",
  arMenusEditDesc4:
    "This campaign sends to clients who did not return after certain period of time.",
  arMenuCATitle4: "Lapsed Clients",
  arMenuCADesc4:
    "Customize targeting below to send out SMS/MMS to lapsed clients.",
  arMenuCABoxDesc41: "Clients with at least ",
  arMenuCABoxDesc42: " connection in the last ",
  arMenuCABoxDesc421: " in the last ",
  arMenuCABoxDesc43: " week, but did not return in last ",
  arMenuCABoxDesc431: " but did not return in last ",
  arMenuCABoxDesc44: " week",
  arMenuUCDesc4: "Win back clients update campaign text",

  arMenusTitle5: "Reward regulars",
  arMenusDesc5:
    "Show appreciation to loyal patrons with special discounts and loyalty rewards",
  // arMenusDesc5: "Send out rewards to boost the foot fall",
  arMenusEditDesc5:
    "This campaign automatically Send out rewards to boost the foot fall in your business.",
  arMenuCATitle5: "Loyal Clients",
  arMenuCADesc5:
    "This camapaign sends SMS/MMS to loyal clients, you can change the targeting below.",
  arMenuCABoxDesc51: "Clients with at least ",
  arMenuCABoxDesc52: " or more connection, within the last ",
  arMenuCABoxDesc53: " week period.",
  arMenuUCDesc5: "Reward regulars update campaign text",

  arMenusTitle6: "Get more Ratings",
  arMenusDesc6:
    "Encourage customers to leave positive online reviews to boost the restaurant's reputation",
  arMenusEditDesc6:
    "Encourage customers to leave positive online reviews to boost the restaurant's reputation",
  // arMenusDesc6: "Request ratings from your clients to boost your business",
  // arMenusEditDesc6: "Request ratings from your clients to boost your business.",
  arMenuCATitle6: "Clients",
  arMenuCADesc6:
    "This autoresponder is sent to increase your company's online rating.",
  arMenuCABoxDesc61: "Send message after ",
  arMenuCABoxDesc62: " min of the connection",
  arMenuUCDesc6: "Get more Ratings update campaign text",

  arMenusTitle7: "Thank you for visiting",
  arMenusDesc7:
    "Express gratitude to customers  for choosing your business with heartfelt thank-you messages",
  // arMenusDesc7:
  //   "Send a thank you message to your clients for visiting your place",
  arMenusEditDesc7:
    "Send a warm thank you to your clients for visiting your place.",
  arMenuCATitle7: "Clients",
  arMenuCADesc7: "This auto campaign audience is predefined, we've got this!",
  arMenuCABoxDesc71: "This auto campaign automatically sends after ",
  arMenuCABoxDesc72: " minutes of customer visit",

  arMenuUCDesc7: "Thank you for visiting update campaign text",

  defaultMsg1:
    "You are our one of the best customers to whom we love to give discount! May you be always wealthy and healthy. Happy birthday! %%firstname%%",
  defaultMsg2:
    "We hope you enjoyed your first visit, we'd love to see you again soon so we're offering a discount just for you! Click the link to book your next appointment with us and redeem your special online offer now.",
  defaultMsg3:
    "Thanks for dropping by recently, we'd love to see you again soon so we're offering a discount just for you! Click the link to book your next appointment with us and redeem your special online offer now.",
  defaultMsg4:
    "We want you back SO BAD that we’re going to give you 10% off just for returning! Want to give us a try again today?",
  defaultMsg5:
    "You’ve been a customer for long time now! Wow, time flies! We appreciate you and want to thank you for being with us.",
  defaultMsg6:
    "We hope you enjoyed your first visit, we'd love to see you again soon! [URL] Click the link to rate us on Google.",
  defaultMsg7:
    "Thank you for being our valued customer. We are so grateful for the pleasure of serving you and hope we met your expectations.",

  arEdS1Msg1: "Create personalized birthday greetings for SMS/MMS",
  arEdS1Msg2: "Create personalized greetings for the new clients",
  arEdS1Msg3:
    "To personalize the message's appearance, change the text and upload your own image.",
  arEdS1Msg4:
    "Target disengaged clients to return with a special offer, a proven tactic to lure clients back.",
  arEdS1Msg5: "Surprize top spenders with personalized and special offers.",
  arEdS1Msg6:
    "The campaign encourages your clients to rate your service online.",
  arEdS1Msg7: "Create thank you message for your clients.",

  arCustMsgNote:
    "If a user subscribes outside of the delivery window, they’ll receive your autoresponder at the next available time.",
  campaignOption1: "Edit",
  campaignOption2: "Overview",
  campaignOption3: "Preview",
  campaignOption4: "Send Test SMS",
  campaignOption5: "Reset Campaign",
  campaignOption6: "Permissions",
  sendSMS: " Send SMS",
  cancel: "Cancel",
  send: "Send",
  sendOnThisDayTitle: "Choose day(s) to send message",
  bcaOption1: "On the day of birthday",
  bcaOption2: "3 days before  birthday",
  bcaOption3: "One week before birthday",
  bcaOption4: "Two weeks before birthday",
  bcaSOption1: "on the day of birthday",
  bcaSOption3: "one week before birthday",
  bcaSOption4: "two weeks before birthday",
  msgWndTitle: "Select Message Window",
  birthdaySpecialCaption: "Sends to clients 3 days before their birthday",
  connection: "Connection",
  optionsTitle: "Select",
  campaignDetailsTitle: "Campaign details",
  sendToTitle: "Send to",
  linkClickedTitle: "Link clicked",
  historyTitle: "History",
  autoSummaryTitle: "Auto Responder Summary",
  anyAmount: "any amount",
  modifyTitle: "Modify",
  almostDone: "Almost done!",
  updateCampaignDesc:
    "Your autoresponder is complete, once enabled, it will send messages to clients on an ongoing basis. This campaign can be easily modified or paused at any time.",
  updateCampaign: "Update",
  unpTitle: "Update and Publish",
  resetTitle: "Reset",
  daysTitle: "Days",
  sucessMessageTitle: "You have successfully",
  updatedSmartCampaignTitle: "updated the smart campaign",
  ycTitle: "Your campaign",
  psTitle: "published successfully",
  resetCampaignTitle:
    "It will premanently delete your camapign's custom text,image, discount offers & targeted audience!",
  typeHereTitle: "Type here...",
  advSchTitle: "Advance Scheduling",
  attachCoupon: "Would you like to attach a coupon?",
  attachSms: "Send a welcome message to customer after they signup",
  sendAfter: "Send message after",
  afterCon: "of the connection",
  min: "Minutes",
  discountReq: "Discount is required",
  discTypeReq: "Discount type is required",
  couponTxtReq: "Coupon text is required",
  cpnBtnTxtReq: "Coupon button text is required",
  finePTReq: "Fine print text is required",
  cpnBtnTxtLReq: "Maximum 19 characters are allowed",
  discountMaxChar: "Maximum 12 characters are allowed",
  mobile: "Mobile",
  web: "Web",
  ncCountReq: "This field is required",
  rcCountReq: "Clients with connection in the last is required",
  lcConCountReq: "Connection is required",
  minReq: "Minutes required",
  lapcConCountReq: " Clients with at least connection is required",
  lapcLastMinReq: " With the last minutes is required",
  notRetInReq: "Who did not return in the last minutes is required",
  groupReq: "Please select the customer group",
  lastWeekReq: "last week is required",
  sendMsgAftReq: "Send message after is required",
  aftMinConReq: "Minutes required",
  notRetInVal: "Not return in last minutes must be less than last minutes.",
  notRetReq: "Not return in last minutes is required",
  notRetWeekReq: "Not return in last week is required",
  notRetInWeekVal: "Not return in last week must be less than last week.",
  vsop1: "15 min",
  vsop2: "30 min",
  vsop3: "45 min",
  vsop4: "60 min",
  vsop5: "90 min",
  vsop6: "120 min",
  tcTitle: "Total Clients",
  tcDesc: "Your message will be sent to ",
  cpDefText: "Exclusive offer for you",
  cpBDefText: "Redeem Now",
  finePrintTxt: "Please redeem with cashier",
  discAmtErr: "Discount amount must be less then 5000",
  discPerErr: "Discount percentage must be less then or equal to 100",
  validDayErr: "Day value is too large enter valid days",
  validHoursErr: "Hours value is too large enter valid hours",
  validMonthErr: "Minutes value is too large enter valid minutes",
  validWeekErr: "Week value is too large enter valid week",
  validCon: "Connections value is too large enter valid connection",
  validMinErr: "Minutes value is too large enter valid minutes",
  lengthErrMsg: "The message lenght should be less then 160 Charactor.",
  dummyText:
    "Lorem Ipsum is lorem hrig, lorem ipsum is fraets.Lorem Ipsum is lorem hrig, lorem ipsum is fraets.Lorem Ipsum is lorem hrig, lorem ipsum is fraetsLorem Ipsum is lorem hrig, lorem ipsum is fraets.Lorem Ipsum is lorem hrig, lorem ipsum is fraets.Lorem Ipsum is lorem hrig, lorem ipsum is fraets.",
  redeemedT: "Redeemed",
  noAddCURLT: "Can't add coupon URL.Message's maximum length is 160",
  permReqT: "Permissions is required",
  UCamT: "Update Campaign",

  // custom form
  cFLT: "List of Forms",
  cFT: "Custom Forms",
  cFDesc:
    "Create tailored forms to collect specific feedback and information from customers efficiently",
  cFSecT: "Add a Custom Section",
  cFSecEditT: "Edit a Custom Section",
  cDFieldEditT: "Edit Client Details Section",
  cDField: "Client Details Fields",
  cFLTC: "All contacts that submit the form will be added to selected group",
  cFST: "Automatically push contacts into a group",
  ConLT: "Contact List",
  cDetC: "Choose the fields to be  displayed on client side",
  FormNameT: "Form Name",
  AddTiT: "Add Title",
  FNReqT: "Form name is required",
  validFNT: "Enter valid form name",
  validSTT: "Enter valid section title",
  validFiNT: "Enter valid first name",
  validLNT: "Enter valid last name",
  CLReqT: "Contact list is required",
  SecReqT: "Section title is required",
  SecDesReqT: "Section description is required",
  ACliDetSecT: "Add Client Details Section",
  AddNewAns: "Add new answer",
  delCFT: "This custom form will be deleted permanetly.",
  delMCFT: "This custom forms will be deleted permanetly.",
  newAnsT: "New answer ",
  cDetReq: "Client details fields is required",
  formPre: "Form Preview",
  maleT: "Male",
  maleVal: "male",
  feT: "Female",
  feVal: "female",
  othT: "Others",
  othVal: "others",
  queReq: "Question is required",
  ansReq: "Answer is required",
  newAns: "New answer",
  newQue: "New question",
  isReq: " is required",
  fillCF: "Filled Custom Form Details",
  copyURL: "Copy Link",
  buildT: "Builder",
  twoByTwoTitle: "2/2",
  mobNoMandT: "Mobile number is mandatory",
  submitSuccessT: "form submited successfully",
  createdSuccT: "form created successfully",
  updatedSuccT: "form updated successfully",
  demoPOT: "It is for demo purpose only",
  maxLimitT:
    "Enter '1024' for speed to designate it as UNLIMITED. This value removes any caps, allowing maximum throughput",

  // Widgets
  WebSignUpT: "E-signup",
  widgetT: "Web E-signup",
  widS1T: "Form Details",
  widS2T: "Form Builder",
  widS3T: "Form Confirmation",
  contactList: "Contact List",
  sendMsglabel: "Send free compliance message immediately upon sign-up",
  dontSendMsglabel: "Don’t send compliance message upon sign-up",
  highlyRecom: "Highly recommended",
  notRecom: "Not recommended",
  sendMsgDesc:
    "All contacts that submit the form will be added to selected lists",
  complMSGT:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit vehicula dui sit amet ligula cursus.dolor sit amet dolor adipiscing.",
  confFL:
    "This confirmation will be shown after your contact submits the Web Sign-up Form",
  confT: "Confirmation text",
  settings: "Settings",
  compliance: "Compliance",
  noti: "Notifications",
  showmsgprev: "Show message preview",
  hidemsgPrev: "Hide Preview",
  locPermTitle: "Location Permission",
  secTitle: "Section Title",
  secDesc: "Section Description",
  addDescT: "Add Description",
  confTextReq: "Confirmation text is required",
  oneByTwoTitle: "1/2",

  //web sign up QR
  join: "Join",
  generated: "Generated",
  QR: "QR",
  groupRequired: "Group is required",
  qrDownloadSTitle: "QR Code downloaded successfully",
  mobRequiredL: "Mobile no is required",
  mobValidL: "Please check valid mobile no",
  joinTitle: "Join",
  selGrpT: "Select Group",

  // web sign up image
  ImgTitle: "Image",
  ImgeInT: "Text:",
  ImgInJoin: " to join out list",
  ImgCT: "Copy",
  ImgReq: "Generated image not found!!",
  OrT: "OR",
  embL: "Embeded Link",
  webSignUpImageInnerContent:
    "By submitting this form and signing up via text, you consent to receive marketing text messages (such as promotion codes and reminders). Message and data rates may apply. Message frequency varies. You can unsubscribe at any time by replying STOP",
  imgDwnldT: "Image downloaded successfully",
  emdLinkCPT: "Embeded link copied successfully",

  // Clients
  addConT: "Add Contact",
  addNCli: "Add a new client",
  upCli: "Update Client",
  cliLT: "Client List",
  AllContT: "All Contacts",
  UnsubscrT: "Unsubscribed",
  UnassiT: "Unassigned",
  SubBykeyT: "Subs by Keyword",
  dropBT: "Drop file here or click to upload",
  reqDateT: "Date is required",
  dateGtErr: "Date must be later than or equal to today's date",
  reqTimeT: "Time is required",
  vTimeTx: "Select valid time",
  vDateT: "Select valid date",
  reqGenT: "Gender is required",
  unsubConfMsg: "Would you like to mark this customer as an unsubscriber?",
  subConfMsg: "Would you like to mark this customer as a subscriber?",
  impConT: "Import Contacts",
  csvUpT: "CSV Contacts file to upload *",
  csvFileDesc:
    "Please upload csv file only - csv file columns - First name, Last name, Email id, Mobile no, Gender, DOB(MM/DD/YYYY) -  no spaces, dashes, or parentheses in Mobile no",
  toDownT: "To download demo file click here",
  conHeadT: "Yes, this file contains headers",
  optIn100PT: "Yes, these imported contacts are 100% opt-in",
  counCInT: "Include country code in the number",
  usExp: "US Example : 12025248725",
  ukExp: "UK Example : 447481340516",
  nextTitle: "Next",
  optInReq: "Imported contacts must be 100% opt-in",
  reqTitle: "Required",
  invalT: "Invalid",
  conFReq: "Contacts file is required",
  selGenT: "Select Gender",

  // groups
  StaT: "Statistics",
  gT: "Groups",
  cGrT: "Create Group",
  AllgT: "All Groups",
  FavT: "Favourite",
  MostActT: "Most Active",
  grT: "Group Name",
  keywT: "Keywords",
  exSubT: "Message for existing subscriber",
  ArchT: "Archived",
  welSmsMms: "Send welcome SMS/MMS?",
  gNotiSms: "Notify me about each new contact through SMS",
  gNotiEmail: "Notify me about each new contact through Email",
  DisT: "Disclaimer Text: ",
  gMendMsg: "STOP to end. HELP for help. SMS & data rates may apply",
  reqGName: "Group name is required",
  validGN: "Enter valid group name",
  reqKeyT: "Keywords are required",
  reqMemMsg: "Subscriber message is required",
  vPhoNoT: "Enter valid Phone Number",
  reqPhoNoT: "Phone Number is required",
  reqContactNo: "Contact Number is required",
  reqMsgT: "Message is required",
  reqMsgNT: "Campaign title is required",
  reqLinkT: "Link is required",
  delGT: "This group will be deleted permanetly.",
  delMGT: "This groups will be deleted permanetly.",
  gMemberMsg: "It seems like you already part of our group!!",
  gdefMsg:
    "We are online now! Check out our service menu and book your next appointment today.",
  defMemMsg: "Type your message here",
  addCustToGrpT: "Add Client to Group",

  // client
  clientListTitle: "List of Clients",
  validFNameT: "Enter valid first name",
  validLNameT: "Enter valid last name",

  //for business settings
  UAccIT: "User Acount Information",
  busNT: "Business Name",
  busNumT: "Business Number",
  busAddT: "Business Address",
  busMailT: "Business Email",
  busTimeZT: "Time zone",
  reqLogoT: "Logo is required",
  vBusNT: "Enter valid business name",
  reqBusNT: "Business name is required",
  vBusNoT: "Enter valid business Number",
  reqBusNoT: "Business number is required",
  reqBusAddT: "Business address is required",
  reqDomainN: "Domain name is required",
  reqAddT: "Address is required",
  reqTimeZone: "Time zone is required",
  basInfoT: "Basic Info of Your Business",
  aboutBusT: "About Your Business",
  logoT: "Logo",
  busN: "This number is used for signin",
  genInfoCT: "General Information",

  // payments
  billD: "Billing Details",
  payMeths: "Payment Methods",
  payMeth: "Payment method",
  billPer: "Billing period",
  locationT: "Location",
  paidBy: "Paid by",
  enterBillingD: "Enter your billing details",
  setPayTitle: "Set up payments",
  invoiceDmTitle:
    "Your billing details will be displayed on your monthly invoice from menuonline.",
  creCT: "Your All Credit Cards",
  creCST:
    "Fastest check out with secure and reliable platform, Trusted by large group of business",
  setDeT: "Set as Default",
  addNCT: "Add New Card",
  addCCT: "Add Credit Card",
  entCT: "Enter your card Information",
  editCCT: "Edit Credit Card",
  modCT: "Modify your card Information",
  reqAccTyT: "Account type is required",
  reqGSTNoT: "GST number is required",
  toCred: "Total Credits",
  validGSTNOT: "Enter valid GST number",
  transDetail: "Transaction Details",
  payNowT: "Pay Now",
  paySuccessT: "Payment is successfull",
  accTypeT: "Account Type",
  selAccTypeT: "Select account type",
  gstNumT: "GST Number",
  invoiceTit: "Invoice",
  invoiceToT: "Invoice To :",
  issuDatT: "Issue Date",
  dueDateT: "Due Date",
  amtDueT: "Amount Due",
  chargT: "Charges",
  subTot: "Sub Total",
  hsT: "HST",
  grdTot: "Grand Total",
  genInvoiceT: "Invoice Generated on",
  invoceeDescT: "If you have gone through your bill and still have question",

  // credit card
  reqAccNoT: "Account number is required",
  reqCardNT: "Name on card is required",
  reqExpDT: "Expiry date is required",
  validExpDT: "Enter valid expiry date",
  reqCvvT: "CVV is required",
  validReqCvvT: "Enter valid cvv",
  validNameT: "Enter valid name",
  cardNumT: "Card Number",
  cardPlaceT: "xxxx-xxxx-xxxx-xxxx",
  nameCardt: "Name on Card",
  expireT: "Expiry",
  expirePlaceT: "MM/YY",
  cvvT: "CVV",

  // plans
  pT: "Current Plan",
  planT: "plan",
  curPT: "You have subscribed the ",
  noPlan: "Please upgrade to enjoy all features",
  rPBT: "RECOMMENDED",
  moT: "/mo",
  CurPProC: " Days remaining until your plan requires update",
  upgradeT: "Upgrade",
  canSubT: "Unsubscribe",
  upgSucessT: "The plan upgraded successfully!",
  cancelSubConfT: "Are you sure you want to cancel subscription?",
  manageScrT: "Manage Your screens",
  scrUHT: "Screens you have",
  addMoreScrT: "Add more Screens",
  addScrT: "Addition Screen",
  perscrT: "$ per screen",
  quntiT: "quantity",
  andBoxT: "Android Box",
  perBoxT: "$ per box",
  shipT: "Shipping Charges",
  uptoT: "upto 4 boxes ",
  chargeNowT: "You will get charge now",
  updatedRecurT: "Your updated reoccuring will be",
  curRecurT: "Current reoccuring : ",
  ofT: "of",

  // purchase number
  pNoT: "Purchase Number",

  // locations
  locationTitle: "Location",
  locationSubTitle: "Location List",
  addLocationTitle: "Add Location",
  addSubLocationTitle: "Add Your Location",
  publishLocT: "Publish Location",
  LocationTitle: "Add New Location",
  LocationDetails: "Location Details",
  EditLocationTitle: "Edit Location",
  ComingSoonTitle: "Coming Soon",
  reqLocNameTitle: "Location name is required",
  reqPhoneTitle: "Phone is required",
  openingHourTitle: "Opening Hours",
  reqLocationTitle: "Location is required",
  previousTitle: "Previous",
  businessLocTitle: "Where is your business located?",
  copyHoursForAll: "Copy these working hours to all days ?",
  ratingsTitle: "Good (02)",
  contactDetailsTitle: "Contact Deatils",
  locMailAddT: "Location Email Address",
  contactDetailsSubCaption: "Location Contact Number",
  businessTypeCaption: "Main",
  businessTypeSubCaption: "Food & Fastfoods",
  businessTypeOhterCaption: "Additional",
  dayTitle: "Day",
  isDaysTitle: "isDays",
  isClosedTitle: "Closed",
  openingHoursTitle: "Opening Time",
  closingHoursTitle: "Closing Time",
  timingCopyBtnTitle: "Yes, copy",
  defaultOpeningTime: "10:00:00",
  defaultClosingTime: "19:00:00",
  defaultLocationImage:
    "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cmVzdGF1cmFudHxlbnwwfHwwfHw%3D&w=1000&q=80",
  noReviewYet: "No Reviews Yet",
  good: "Good",
  average: "Average",
  copyTimingToAllAlertMsg: "setting all day timing same to this",
  description: "Description",
  descriptionReq: "Description is required",
  maxDescLength: "Message Should be of maximum 600 characters length",
  businessLogo: "Business logo",
  businessLogoReq: "Business logo is required",
  validLocNameT: "Enter valid location name",
  acceptTermConT: "Please accept terms and conditions",
  congrtsT: "Congratulations",
  validLocErrT: "Please select valid address",

  //marketplace
  addMarketPlace: "Add MarketPlace",
  addMarketPlaceTitle: "Add New MarketPlace",
  editMarketPlace: "Edit MarketPlace",
  marketPlaceImagesReq: "Market place images is required",
  businessImages: "Bussiness Images",
  fileExistTitle: "file already exist, please select other file",
  preview: "Preview",
  listProfile: "List Profile",
  displayData: "Display data",
  manageProfile: "Manage Profile",
  youareAlmostDone: "You're almost done",
  doneDesc: "Preview your profile and see how it would look.",
  publishTitle: "Publish",
  profileReady: "Your profile is ready!",
  profileReadyDesc:
    "Clients can now book with you online. Share the link below to get started.",
  seeYourProfile: "See your profile",
  okGotIt: "Ok, got it",
  tellyourClient: "Tell your clients you are online",
  generateQRCOde: "Generate QR code",
  dowmloadQR: "Download QR code",
  linkT: "Link",
  linkCopied: "Link copied to clipboard",
  unlist: "Unlist",
  markPlcInfoTitle:
    "Would you like to display below information on your Marketplace?",

  // change password
  changePassTitle: "Change Password",
  changePassSubTitle: "Set new password",
  reqOldPassTitle: "Old password is required",
  reqNewPassTitle: "New password is required",
  reqCPassTitle: "Confirm password is required",
  reqPassNoMatchTitle: "Passwords do not match",
  oldPassT: "Old Password",
  newPassT: "New Password",
  confirmPassT: "Confirm Password",
  callT: "Call",

  // terms and conditions
  tacReqErr: "Terms and Conditions are required",

  //sub user
  sUserLink: "Subuser Login",
  usersTitle: "Users",
  usersSubTitle: "List of all users and their details",
  userListTitle: "List of Users",
  createUserTitle: "Create User",
  editUserTitle: "Edit User",
  setPinTitle: "Set PIN",
  changePinTitle: "Change Pin",
  proPicTitle: "Profile picture",
  DropLogoTitle: "Click to upload",
  reqProImgTitle: "Profile image is required",
  reqNotesTitle: "Notes is required",
  reqRolesTitle: "Role is required",
  reqLocPermissionTitle: "Location permission is required",
  reqPlatformPermissionTitle: "Platform permission is required",
  sureTitle: "Are you sure?",
  deleteClientTitle: "This client will be deleted permanetly.",
  deleteMClientTitle: "This clients will be deleted permanetly.",
  deleteClientFGPTitle: "This client will be deleted permanetly from group.",
  deleteMClientFGPTitle: "This clients will be deleted permanetly from group.",
  deleteUserTitle: "This user will be deleted permanetly.",
  deleteMUserTitle: "This users will be deleted permanetly.",
  platformPerT: "Platform Permission",

  // wifi Module
  wifiCT: "WIFI",
  wifiDashT: "Wifi Dashboard",
  sPageT: "Splash Pages",
  colInvalT: "Select valid color code",
  welcome: "Welcome to Dominoz Pizza",
  wifiLoginDesc:
    "By connecting, you agree to receive rewards through text message as well as our Terms & Privacy Policy. Consent is not necessary, and you can opt out at any time.",
  swipeBtnT: "Swipe for free wifi",
  zipCodeReq: "Zip code is required",
  validZipCode: "Invalid zip code",
  birthdateReq: "Birthdate is required",
  validBirthdate: "Enter valid birthdate",
  formTitle: "Form",
  OrTitle: "Or",
  enjoyWifiT: "Connect and Enjoy your free",
  WifiSessT: "wifi session",

  // Wifi Hotspot
  hotT: "Hotspot",
  hotLT: "Hotspot List",
  AddNewHot: "Add New Hotspot",
  hotInfoT: "Hotspot Information",
  editDBtn: "Edit Details",
  wifiInfoMsg: "Connect and Enjoy free WiFi",
  conMsg: "Great, You have been connected to ",
  conMsg1: " WiFi",
  WifiT: " WiFi",
  logToAcc: "login to get access",
  veriCoT: "Verification Code",
  veriCoC: "Please type the verification code sent to ",
  WifiUMail: "adamo@gmail.com",
  wifiT: "Wifi",
  yourname: "What’s your name by the way?",
  yourBirth: "Can you tell us, what’s your birthdate?",
  reqDeviSSID: "Please Enter Guest Wifi Name",
  reqDevKey: "Please Enter Device Key",
  reqMaxIntAccLen: "Please Select Maximum Internet Access Length",
  macAddT: "MAC address",
  dPortT: "Device Port",
  hardT: "Hardware",
  curUpti: "Current Uptime",
  nearByDevT: "Nearby Devices",
  firT: "Firmware",
  whoAreYT: "Who are you?",
  whWegetTo: "Where do we get in touch with you?",
  yourArC: "What is your area code? ",
  conT: "Connected",
  delHotT: "This hotspot will be deleted permanetly.",
  delMHotT: "This hotspots will be deleted permanetly.",
  SpeedErr: "Speed should be at least 0.01",
  SpeedMErr:
    "Please enter a value up to 1024 for unlimited speed, or select a lower value within the supported range",
  SSIDT: "Device SSID",
  TWOSSIDT: "Device SSID Two",
  WPATWO: "Device SSID Two WPA",
  keyT: "Device Key",
  selLocT: "Select Location",
  maxLenT: "Select Maximum Internet Access Length",
  dowST: "Download Speed",
  upST: "Upload Speed",
  LocNameT: "Location name",
  netLengthT1: "15 Minutes",
  netLengthT2: "30 Minutes",
  netLengthT3: "45 Minutes",
  netLengthT4: "1 Hour",
  netLengthT5: "2 Hour",
  netLengthT6: "4 Hour",
  netLengthT7: "6 Hour",
  netLengthT8: "8 Hour",
  netLengthT9: "10 Hour",
  netLengthT10: "12 Hour",
  EmpWifiName: "Employee Wifi Name",
  EmpWifiPass: "Employee Wifi Password",
  GuestWifiName: "Guest Wifi Name",

  // SplashPage
  sPreT: "Splash Preview",
  sNewT: "Create New Splash",
  sPT: "Splash Page",
  sBT: "Splash Page Builder",
  RedirLT: "Redirect link",
  sTheClr: "Theme Color",
  sLblLog: "Enable social media options for users’ login",
  GoT: "Google",
  FaceT: "Facebook",
  ColAddInfo: "Collect Additional Information",
  IsMandT: "Is it mandatory?",
  FieT: "Field",
  NameT: "Name",
  firstNameT: "First Name",
  lastNameT: "Last Name",
  BirthD: "Birthdate",
  GenderT: "Gender",
  EmailT: "Email",
  dobT: "DOB",
  ZipCT: "Zip Code",
  ReqRedirLnk: "Redirect Link is required",
  ValRedirLnk: "Enter valid redirect link",
  ReqTheClr: "Theme Color is required",
  DiThemeClr: "Theme Color exactly 7 digit",
  ReqName: "Name is required",
  delST: "You want to delete this splash?",
  delMST: "You want to delete these splashes?",
  reqUserLogT: "User Login is required",

  // menu online side bar
  accountTitle: "Account",
  reservationTitle: "Reservation",
  reservationSuccTitle: "Great, Your Reservation Is Done",
  tableTitle: "Table",
  floorTableTitle: "Floor & Table",
  DelFloorTxt: "This floor will be deleted permanetly.",
  menuMoTitle: "Menu",
  onlineProTitle: "Online Profile",
  menuListTitle: "Menu List",

  // Reseller
  resellerPanael: "Companies",
  orderDevice: "Plans Bought",
  policies: "Agreement T&C",
  addResel: "Add Company",
  editResel: "Edit Company",
  addOrder: "Add Order",
  userAcount: "Login to user account",
  reDashboard: "Reseller Dashboard",
  backHomeTitle: "Back To Home",

  // Menu
  delMT: "This menu will be deleted permanetly.",
  delMMT: "This menus will be deleted permanetly.",
  addMT: "Add Menu",
  reqMT: "Menu Name is required",
  reqMTD: "Menu is required",
  reqLocT: "Location is required",
  reqBusinessNameT: "Business name is required",
  businessNameT: "Business Name",
  addNMT: "Add New Menu",
  menuNameT: "Menu Name",
  selLocMenuT: "Select locations for your menu",
  selMenuT: "Select menu",
  createmenubutton: "Create Menu",
  createmenuEditbutton: "Edit Menu",
  EditMenu: "Edit menu",
  RemoveMenu: "Remove menu",
  CopyMenu: "Copy menu",
  ViewMenu: "View menu",
  duplicatePro: "Duplicate",
  visibility: "Visibility",
  normalText: "Lorem Ipsum is simply dummy text of the printing...",
  hideT: "Hide",
  showT: "Show",
  availability: "Availability",
  outof: "Out of stok",

  // Add Customization
  cust: "Customization",
  crCust: "Create New Customization",
  custzName: "Customization Name",
  incT: "Included",
  minT: "Minimum",
  maxT: "Maximum",
  itemNT: "Item Name",
  itemPT: "Item Price",
  preSelT: "Pre-Select",
  custNReq: "Customization name is required",
  incReq: "Included is required",
  minmReq: "Minimum is required",
  minmValid: "Enter valid minimum value",
  maxmReq: "Maximum is required",
  maxmValid: "Enter valid maximum value",
  delCustmzT: "This customization will be deleted permanetly.",
  minItemEr: "Please match the minimum items requirement",
  maxItemEr: "Please match the maximum items requirement",
  proImgReq: "Product image is required",
  removeCust: "Do you want to delete this customization from product ?",

  // Add Item
  condPrices: "Conditions Prices",
  condPricesDesc: "Lorem Ipsum is simply dummy text of the printing...",
  cutmzDesc: "Lorem Ipsum is simply dummy text of the printing...",
  selCustT: "Select customization",
  addNew: "Add New",
  orBtn: "OR",
  selCustmT: "Please Select Customization or create new",
  visStR: "Visibility status is required",
  avlblScR: "Please select availability schedule",
  addonPR: "Please select addon products",
  upsellPR: "Please select upsell products",
  instructionTitle: "Preparation Instructions",
  markItemR: "Please select mark item products",
  tagR: "Please select tag",
  allergensR: "Please select allergens products",
  caloriesR: "Calories is required",
  prepInstR: "Preparation instructions is required",
  staffNR: "Staff notes is required",
  disv: "Discount Availability",
  disday: "By the day",
  disdate: "From the Date",
  disdate1: "To Date",
  disdate2: "From  Date",
  always: "Always",
  StartTime: "Start Time",
  EndTime: "End Time",
  cum: "Customization Name",
  dismim: "Minimum",
  dismax: "Maximum ",
  selDay: "Select Day",
  visiblityText: "Status",
  selVis: "Select Visiblity",
  hideText: "Hide",
  showText: "Show",
  availabilityschedule: "Availability Schedule",
  breakfast: "Breakfast",
  lunch: "Lunch",
  dinner: "Dinner",
  breakfastT: "( 08:00 AM To 11:00AM )",
  lunchT: "( 12:00 PM To 03:00PM )",
  dinnerT: "( 07:00 PM To 11:00PM )",
  discountR: "Discount is required",
  validDiscE: "Enter valid discount",
  plSelDayT: "Please select day",
  sTimeReq: "Start time is required",
  sTimeInValid: "Start time is invalid",
  eTimeReq: "End time is required",
  eTimeInValid: "End time is invalid",
  sDateReq: "From date is required",
  sDateInValid: "From date is invalid",
  eDateReq: "To date is required",
  eDateInValid: "To date is invalid",

  // Add Category
  cateCT: "CATEGORY",
  cateT: "Category",
  priCT: "PRICE",
  priT: "Price",
  staT: "STATUS",
  actT: "Actions",
  addCateT: "Add Category",
  reqCateT: "Category Name is required",
  reqCatStaT: "Category Status is required",
  delCateT: "This category will be deleted permanetly.",
  delMCateT: "This categories will be deleted permanetly.",
  cateNT: "Category Name",

  // Add Product
  createProT: "Create a Product",
  editProT: "Edit Product",
  basicInfoT: "Basic Information",
  basicSubT: "Lorem Ipsum is simply dummy text of the printing...",
  addProT: "Add Products",
  addProMT: "Product Menu",
  proT: "Product Name",
  reqProT: "Product Name is required",
  proPT: "Product Price",
  cImgT: "Choose Image",
  upImgT: "Upload Image",
  dropImgBoxC: "Only JPG and PNG Files with Max-File size 3MB is allowed.",
  reqDescT: "Descripition is required",
  reqCrT: "Currency is required",
  reqPrT: "Price is required",
  reqVrT: "Variant is required",
  reqVrVldT: "Enter Valid Variant ",
  reqImgT: "Image is required",
  reCateT: "Category is required",
  delProT: "This product will be deleted permanetly.",
  delVrntT: "This varition will be deleted from the Product.",
  delMProT: "This products will be deleted permanetly.",
  proPlaceHoldT: "Pizza, Burger, Baverages, etc",
  descProT: "Describe your product",
  selProCateT: "Select your product category",
  currT: "Currency",
  proPriT: "Enter product price",
  custom: "Customization",
  addup: "Add on / Upsell",
  addselectpro: " Add on Select Product",
  upselectpro: "Upsell Select Product",
  promotion: "Promotions",
  DiscType: "Discounted Type",
  nutritional: "Nutritional and Allergen Information",
  specialins: "Special Instructions",
  StaffNotes: "Staff Notes",
  checkbox2:
    "A checkbox to allow or disallow special instructions from customers for this menu item.",
  custList: "Customization List",
  cust1: "Customization1",
  cust2: "Customization 2",
  cust3: "Customization 3",
  cust4: "Customization 4",
  cust5: "Customization 5",
  checkBoxName: "Nutritional and Allergen Information",
  checkBoxDesc: "Lorem Ipsum is simply dummy text of the printing...",
  itemname: "Item Name",
  price: "Item Price",
  itemplaceh: "Enter Name",
  priceplaceh: "Enter Price",
  markItem: "Mark Item",
  allergens: "Allergens",
  calories: "Calories",
  mkNameR: "Mark item name is required",
  iconR: "Please select mark item icon",
  mkDelT: "This mark item will be deleted permanetly.",

  // variations
  variT: "Variations",
  mngVarT: "Manage Variant",
  varT: "Variant",
  varDelT: "This Variant will be deleted permanetly.",
  varNmeT: "Variation Name",
  addVarT: "Add Variation",
  serchVarT: "Search variation",
  addVarTP: "Select Variant you would like to add",
  searVariT: "Search Variations",

  // floor
  addFBtn: "Add Floor",
  reqFN: "Floor Name is required",
  AddFT: "Add Floor",
  EditFT: "Edit Floor",
  floorNameT: "Floor Name",
  StatusT: "Status",
  selFloorT: "Select Floor",

  // Table
  tableT: "Tables",
  addTBtn: "Add New Table",
  tAddT: "Add Table",
  tEditT: "Edit Table",
  tDelT: "This table will be deleted permanetly.",
  tMDelT: "This tables will be deleted permanetly.",
  tErrMsgN: "Please Enter The Table Name",
  tErrInvMsgTN: "Please Enter Valid Table Name",
  tErrSelf: "Please Select The Floor",
  tableErr: "Please Select The Table",
  tCapT: "Table Capacity",
  tOccT: "Find Your Table For Any Occasion",
  tabNameT: "Table Name",

  // Client Menu
  makeRevT: "Make a Reservation",
  perT: "For How Many Person?",
  resDelT: "This reservation will be deleted permanetly.",
  resT: "Reservations",
  resAddBtn: "Add Reservation",
  NameErrT: "Name is required",
  emailErrSub: "Email Subject is required",
  reviewT: "Reviews",
  dumMsg1: "To display customer name, use %%customer_name%% in the template",
  dumMsg2: "To display reservation time, use %%date_time%% in the template",
  dumMsg3: "To display table number, use %%table_no%% in the template",

  // Reservation
  addResT: "Add Reservation",
  editResT: "Edit Reservation",
  PerReq: "Number of person is required",
  reqBT: "Block Time is required",
  pendingT: "Pending",
  bookedT: "Booked",
  comT: "Completed",
  canT: "Cancelled",
  resSlotT: "Reservation Slot",
  blockTT: "Block Time",
  slotTimeT: "Reservation Slot Time:  ",
  tempNameReqT: "Template name is required",
  tempTypeReqT: "Template type is required",
  SMStempReqT: "SMS template is required",
  resMcompT: "This reservation will be mark as completed.",
  custNameT: "Customer Name",
  datenTimeT: "Date Time",
  tbleNoT: "Table No",
  floorT: "Floor",
  custNameNT: "customer_name",
  datenTimeNT: "date_time",
  tbleNoNT: "table_no",
  floorNT: "floor",
  custNameMT: "%%customer_name%%",
  datenTimeMT: "%%date_time%%",
  tbleNoMT: "%%table_no%%",
  floorMT: "%%floor%%",

  // Client Menu - Home
  homeTitle: "Home",
  contactTitle: "Contact Us",
  aboutTitle: "About Us",
  homeMenuTitle: "Menu",
  bookTableTitle: "Book A Table",
  bookT: "Book",
  welcomeTitle: "Welcome",
  welcomeMnuTitle: "Welcome to",
  menuOnlineRestroTitle: "Menuonline Restaurant",
  viewMenuTitle: "View Menu",
  contactUsTitle: "Contact Us",
  contactUsfrmTitleName: "Name",
  contactUsfrmTitleEmail: "Reply to email",
  contactUsfrmTitleEmailSub: "Email Subject",
  contactUsfrmTitleNote: "Note",
  phoneNumT: "Phone Number",
  companyInfo:
    "Lorem Ipsum is lorem hrig, lorem ipsum is fraets.Lorem Ipsum is lorem hrig, lorem ipsum is fraets.Lorem Ipsum is lorem hrig, lorem ipsum is fraets.",
  footerSTitle1: "About Menuonline",
  footerSTitle1Lnk1: "About Us",
  footerSTitle1Lnk2: "Features",
  footerSTitle1Lnk3: "Blog",
  footerSTitle2: "Legal",
  footerSTitle2Lnk1: "Terms and Conditions",
  footerSTitle2Lnk2: "Privacy Policy",
  footerSTitle3: "For Business",
  footerSTitle3Lnk1: "For Partners",
  footerSTitle3Lnk2: "Pricing",
  footerSTitle3Lnk3: "Support for Partners",
  footerCopyRithgTxt: "  Menuonline or its affiliates",
  resLblDate: "Select Date",
  resLblTime: "Select Time",
  resLblBlockTime: "Select Block Time",
  resLblNoPerson: "For How Many Person?",
  resLblPhone: "Contact Number",
  validcNoT: "Enter valid contact number",
  resLblName: "Full Name",
  resLblEmail: "Please provide your Email ?",
  resLblNote: "Add a reservation note",
  resLblNotes: "Notes",

  // SMS Chat
  chatMessageTitle:
    "Nam lobortis augue a enim pulvinar, Nam lobortis augue a enim pulvinar, id malesuada eros sollicitudin",
  igTitle: "IG",
  likeTitle: "Like",
  refreshTitle: "Refresh",
  deleteUserListTitle: "Delete User",
  addDUserT: "Add Deleted User",
  deleteChateTitle: "Delete Chat",
  delClientErr: "Please select client to delete",
  delChatErr: "Please select client to delete chat",
  delCont: "Delete Contact",
  delChat: "Clear Chat history",
  delContDesc: "Are you sure you want to delete this contact?",
  delChatDesc: "Are you sure you want to delete this chat?",
  noChatErr: "You don't have chat with this client",
  stNConv: "Start new conversation",
  drawerT: "Responsive drawer",
  selContactT: "Select Contact",
  typeYourMsgT: "Type your messaage here",

  //Purchase bandwidth mobile no
  listMobNoT: "List of Bandwidth Numbers",
  purchaseMobNoT: "Purchase Number",
  areaCodeReqT: "Area code is required",
  validACT: "Area code must be of 3 digit",
  getNumbersT: "Get Numbers",
  purchaseT: "Purchase",
  selNoErr: "Please select the number to purchase!",

  //dashboard
  overallT: "OVERALL",
  lifeTConT: "Lifetime Connections",
  repCustT: "Repeat Customers",
  liftTSubT: "Lifetime Subscribers",
  devUsedT: "Deviced Used",
  conMethT: "Connection Method",
  ageTitle: "Age Demographics",
  deviceT: "Device Demographics",
  genderT: "Gender Demographics",
  loginCustBDT: "Logged-in Customers by Day",
  ageT: "Age Group",
  gustT: "Guest Visits",
  allGustT: "All Guest",
  newGustT: "New Guest",
  consT: "Connections",

  //reputation
  repsT: "Get More Reviews",
  offSup: "Office Supplies",
  repsNxtV: "On your next visit",
  addRepT: "Add Get More Reviews",
  repMngT: "Get More Reviews Management",
  repCpnTxt: "Coupon Text",
  repEntrCpnTxt: "Enter Coupon Text",
  repEntrCpnTxtBtn: "Enter Coupon Button Text",
  repCpnTxtBtn: "Coupon Button Text",
  repMngNxtV: "on your next visit?",
  repMngWldL: "Would you like to get",
  repDis: "Discount",
  repDisTyp: "Discount Type",
  repADesc:
    "Reputation help you increase google rating by asking your customers for feedback",
  repNameT: "Name your reputation",
  crtRepT: "Create Reputation",
  feedBackT: "Feedback",
  confLocT: "Confirm Location",
  businLocT: "Bussiness Location",
  addInfoT: "Additional Information",
  repNReqT: "Get More Reviews name is required",
  validRepNT: "Enter valid reputation name",
  groupT: "Group",
  groupLDescTitleT: "Automatically push contacts into a group",
  groupLDescT:
    "All customers who fill out the form will be added to selected group.",
  que1: "Was the business clean?",
  que2: "Was your food good?",
  que3: "Was our service friendly?",
  que4: "Was our service fast?",
  addAdditionalT: "Would you like to add any additional information?",
  phNoMandT: "Phone number is mandatory!",
  qrGenT: "QR Generated",
  repFDT: "Your input will help our team serve you better!",
  letdiT: "Let's do it",
  tak15secT: "Takes 15 seconds",
  defQue:
    "Would you like to receive great offers and promotion from time to time?",
  defNQue: "Would you like someone to respond back to you?",
  rateOnGoogleDesc: "Would you be kind enough to rate us on google?",
  mayBeNT: "Maybe Next Time",
  yesSureT: "Yes, sure",
  validName: "Enter valid name",
  nameReq: "Name is required",
  backToMainT: "Back to Main Page",
  attachCopT: "Would you like to incentivize your customer?",
  thankYouT: "Thank you",
  lftyT: "We do look forward to your next visit!",
  repPreT: "Reputation Preview",
  couponT: "Coupon",
  locStepDesc: "Confirm your business location",
  createRepStepDesc: "Create a custom reputation form  by groups",
  feedbackStepDesc: "Customer feedback question set",
  addInfoStepDesc: "Customer fields and incentive settings",
  FedbkkWDT: "Feedback With Data",
  AnonyFedbkT: "Anonymous Feedback",
  tltFedbkT: "Total Feedback",
  repAnlytT: "Reputation Analytics",
  todayT: "Today",
  last7DaysT: "Last 7 Days",
  last30DaysT: "Last 30 Days",
  thisMT: "This Month",
  lastMT: "Last Month",
  custRangeT: "Custom Range",
  queWiseAnltT: "Question Wise Analytics",
  atL1QueErrT: "Please select at least one question",
  deleteRepTitle: "This reputation will be deleted permanetly.",
  incvCustT: "Yes, Incentivize my customer",
  collAddInfoT: "Yes, I would like to collect additional information",
  busLocReqT: "Bussiness location is required",
  selDarkCOlT: "Please select valid theme color",
  totRewT: "Total Review",
  totPosRewT: "Total Positive Review",
  negFeedT: "Negative Feedback",
  posFeedT: "Positive Feedback",
  fineT: "Fine Print",
  enterFineT: "Enter fine print text",
  setThemeT: "Set Theme Color",
  que1T: "Question 1",
  que2T: "Question 2",
  que3T: "Question 3",
  que4T: "Question 4",
  entMailT: "Enter your email",
  reputationQRDefTxt:
    "Scan the QR code and share your valuable feedback with us regarding your experience.",
  selUser: "Select User",
  userT: "User",
  subUReq: "Please select users",
  updateLoc: "Update Location",
  leadGenT: "Lead Generation",
  displayT: "Display",
  ratingT: "Rating",

  //wifi dashboard
  deviceUT: "Type of Device Used",
  conMT: "Connection Method",
  peakDaysT: "Peak Days",
  peakHoursT: "Peak Hours",
  guestByDayT: "Guest by Day",
  guestVisitT: "Guest Visit",
  conDurT: "Connection Duration",
  gVDataT1: "1 time",
  gVDataT2: "2 times",
  gVDataT3_5: "3-5 times",
  gVDataT6_10: "6-10 times",
  gVDataT11_25: "11-25 times",
  gVDataT26_100: "26-100 times",
  gVDataT100: "100+ times",
  dvcDataAnd: "Total Android",
  andT: "Android",
  deskT: "Desktop",
  iosT: "Ios",
  dvcDataIos: "Total Ios",
  dvcDataDesk: "Total Desktop",
  conDurD10: "<=10 Min",
  conDurD20: "11-20 Min",
  conDurD30: "21-30 Min",
  conDurD40: "31-45 Min",
  conDurD60: "46-60 Min",
  conMthdDEmail: "Email",
  conMthdDSms: "SMS",
  conMthdDGoogle: "Google",
  conMthdDFacebook: "Facebook",
  ageCat1: "<18",
  ageCat2: "18-24",
  ageCat3: "25-34",
  ageCat4: "35-44",
  ageCat5: "45-54",
  ageCat6: "55+",

  //loyalty program
  lpTitle: "Loyalty Punch Card",
  lpListTitle: "List Of Loyalty Punch Card",
  lpNTitle: "Loyalty Punch Card Name",
  enterDetailsT: "Enter Details",
  stampAllowedT: "Stamp Allowed",
  stampItemsT: "Stamp Items",
  lpnReqT: "Loyalty punch card name is required",
  validlpnT: "Enter valid loyalty punch card name",
  brandClrErr: "Brand color must be exactly 7 character",
  selDarkBrandClrT: "Please select valid barnd color",
  ybcTitle: "Your Brand Color",
  ulTitle: "Upload Logo",
  locationsT: "Locations",
  addLPT: "Create Program",
  locErr: "Please select locations",
  pvsTitle: "How many stamps can a customer earn per visit?",
  maxAPDT: "What's the maximum number of stamps a customer can earn in a day?",
  logoErr: "Logo is required",
  expTitle: "Set expiry on rewards",
  addMRTitle: "+ Add More Rewards",
  prevStep1: "Show QR To Earn Points & Enjoy Your Rewards.",
  deleteLPTitle: "This loyalty punch card will be deleted permanetly.",
  deleteUserRecordTitle: "This user record will be deleted permanetly.",
  redeemT: "Redeem",
  lpBtnTitle: "Join Loyalty Punch Card",
  lpDownloadBtnT: "Download Card",
  step0T: "Join now, delicious rewards await for loyal foodies like you.",
  step1T: "You have join",
  congrtsST: "Now you eligible for Free Pizza",
  rewardT: "Get Rewards on every time Visit",
  visitT: "Visit & Unlock",
  rewardsT: " Rewards",
  dummyAddress: "3730 Frankfort Ave ,Louisville Kentucky ,United States",
  loyaltyQRDefTxt:
    "Scan the QR code, join our loyalty punch card, and indulge in rewards.",

  // delivery redirection
  deliRed: "Delivery Redirection",
  deliRedDesc:
    "Get an in-store discount during your next visit to our location",
  deliRedTitle: "Delivery Redirection",
  addDelRed: "Add Delivery Redirection",
  prevT: "Preview",
  crtDelRedT: "Create Delivery Redirection",
  dlredNameT: "Name your delivery redirection",
  homePTextT: "Home page text",
  createdlRedDesc: "Create a custom delivery redirection form  by groups",
  deletedlRedTitle: "This delivery redirection will be deleted permanetly.",

  //Menuonline  Web
  emailRegex:
    /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/i,
  noRegex: /[^0-9]/g,
  spaceRegex: /^\s/,
  alphabetRegex: /[^ a-zA-Z]/g,
};

export default ConstantsHelper;
