// Craeted By MK
import ColorsHelper from "../../../../Helper/Colors";

const headerStyle = {
  main: {
    backgroundColor: ColorsHelper.whiteClr,
    boxShadow: "none",
    height: "5.5rem",
    display: "flex",
    justifyContent: "center",
    px: { xs: '0.5rem', sm: '3rem' },
    borderBottom: '1px solid #D4D4D4',
  },
  orangeBtn: {
    padding: "0.45rem 0.75rem",
    textTransform: "capitalize",
    background: '#F87B1E',
    borderRadius: "0.5rem",
    fontFamily: 'Basier Circle',
    fontSize: '0.938rem',
    color: ColorsHelper.whiteClr,
    "&:hover": {
      backgroundColor: '#F87B1E',
    },
  },
  borderBtn: {
    padding: "0.45rem 0.75rem",
    textTransform: "capitalize",
    background: 'transparent',
    border: '1px solid #BDC9C9',
    borderRadius: "0.5rem",
    fontFamily: 'Basier Circle',
    fontSize: '0.938rem',
    color: ColorsHelper.blackClr,
    "&:hover": {
      backgroundColor: 'transparent',
      border: '1px solid #BDC9C9'
    },
  },
};
export default headerStyle;
