// Created By Ayush.N
import { gql } from "@apollo/client";

export const GET_MENU_LIST = gql`
  query MenuList($location_id: Int!) {
    menuList(location_id: $location_id) {
      status
      message
      data {
        id
        name
        location_id
        position
        is_active
        location_name
        additional_fields
        is_data_collection
      }
    }
  }
`;

export const MENU_STATUS_UPDATE = gql`
  mutation MenuStatusChange($id: Int!, $status: Boolean!) {
    menuStatusChange(id: $id, status: $status) {
      status
      message
    }
  }
`;

export const ADD_MENU = gql`
  mutation AddMenu($name: String!, $location_id: [Int]!) {
    addMenu(name: $name, location_id: $location_id) {
      status
      message
      error {
        name
      }
    }
  }
`;

export const EDIT_MENU = gql`
  mutation EditMenu($id: Int!, $name: String!, $location_id: Int!) {
    editMenu(id: $id, name: $name, location_id: $location_id) {
      status
      message
      error {
        name
      }
    }
  }
`;

export const DELETE_MENU = gql`
  mutation DeleteMenu($id: Int!) {
    deleteMenu(id: $id) {
      status
      message
    }
  }
`;

export const CHANGE_MENU_POSITION = gql`
  mutation ChangeMenuPosition($id: [Int]!) {
    changeMenuPosition(id: $id) {
      status
      message
    }
  }
`;

export const GET_MENU_LIST_DROPDOWN = gql`
  query MenuDropdown(
    $start: Int!
    $location_id: Int!
    $limit: Int
    $search: String
  ) {
    menuDropdown(
      start: $start
      location_id: $location_id
      limit: $limit
      search: $search
    ) {
      status
      message
      data {
        id
        name
        position
        is_active
        # product_data {
        #   name
        #   id
        #   is_active
        #   position
        #   currency
        #   price
        # }
      }
    }
  }
`;

export const GET_CLIENT_HOME_MENU_LIST_DROPDOWN = gql`
  query ClientMenuDropdown(
    $start: Int!
    $location_id: Int!
    $domain_name: String!
    $limit: Int
    $search: String
  ) {
    clientMenuDropdown(
      start: $start
      location_id: $location_id
      domain_name: $domain_name
      limit: $limit
      search: $search
    ) {
      status
      message
      data {
        id
        name
        location_id
        position
        is_active
      }
    }
  }
`;

export const ADD_MENU_CUSTOMER = gql`
  mutation AddCustomer(
    $mobile_no: String!
    $country_code: Int!
    $first_name: String
    $last_name: String
    $email_id: String
    $gender: String
    $dob: String
    $location_id: Int!
    $device_type: String!
    $address: String
  ) {
    addCustomer(
      mobile_no: $mobile_no
      country_code: $country_code
      first_name: $first_name
      last_name: $last_name
      email_id: $email_id
      gender: $gender
      dob: $dob
      address: $address
      location_id: $location_id
      device_type: $device_type
    ) {
      status
      message
      error {
        first_name
        last_name
        mobile_no
        email_id
        gender
        dob
        address
      }
    }
  }
`;

export const ADD_PUBLIC_CUSTOMER = gql`
  mutation AddPublicCustomer(
    $location_id: Int!
    $domain_name: String!
    $mobile_no: String!
    $country_code: Int!
    $device_type: String!
    $first_name: String
    $last_name: String
    $email_id: String
    $gender: String
    $dob: String
    $address: String
  ) {
    addPublicCustomer(
      location_id: $location_id
      domain_name: $domain_name
      mobile_no: $mobile_no
      country_code: $country_code
      device_type: $device_type
      first_name: $first_name
      last_name: $last_name
      email_id: $email_id
      gender: $gender
      dob: $dob
      address: $address
    ) {
      status
      message
      error {
        first_name
        last_name
        country_code
        mobile_no
        email_id
        gender
        dob
        address
        domain_name
      }
    }
  }
`;

export const ADDITIONAL_FIELDS = gql`
  mutation SaveAdditionalFields($menuId: Int!, $additionalFields: String!) {
    saveAdditionalFields(
      menu_id: $menuId
      additional_fields: $additionalFields
    ) {
      status
      message
    }
  }
`;

export const MENU_FIND_BY_ID = gql`
  query MenuFindById($menuFindByIdId: Int!) {
    menuFindById(id: $menuFindByIdId) {
      status
      message
      data {
        location_id
        additional_fields
        id
        is_active
        location_name
        name
        position
        is_data_collection
      }
    }
  }
`;

export const UPDATE_MENU_COLLECTION = gql`
  mutation UpdateMenuCollection(
    $menu_id: Int!
    $is_data_collection: Boolean!
    $additional_fields: String
  ) {
    updateMenuCollection(
      menu_id: $menu_id
      is_data_collection: $is_data_collection
      additional_fields: $additional_fields
    ) {
      status
      message
    }
  }
`;
