// Created By MK

// React Imports
import React, { useState, useEffect, useContext } from 'react';

// MUI Imports
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Third Party Imports
import * as yup from 'yup'
import { useFormik } from 'formik'

// Helper Imports
import ConstantsHelper from '../../../../Helper/Constants';
import countryCodes from '../../../../Assets/CountryCodes';

// Custom Imports
// import CustomBtn from '../CustomComponents/CutomButton';
// import CustomTextfield from '../CustomComponents/CustomTextfield';
// import CustomMobileInput from '../CustomComponents/CustomMobileInput';

// Style Imports
import dialogStyle from './CustomDlg.style';
import staticIcons from '../../../../Helper/Images';
import { TextField } from '@mui/material';
import { HomeContext } from '../../Contexts';
import { LoadingButton } from '@mui/lab';
import CustomMobileInput from '../../../../Components/MenuonlineWebComps/CustomComponents/CustomMobileInput';
import { useSnackbar } from 'notistack';
import urlsHelper from '../../../../Helper/Urls';

const CustomDialog = () => {

    const { openGetFreeMenu, setOpenGetFreeMenu } = useContext(HomeContext)

    // States
    const [activeStep, setActiveStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [stdCode, setStdCode] = useState<{ value: string; label: string, key: string, image: string }>({ value: '1', label: '+1', key: 'CA', image: "/images/countryFlages/US_+1.svg" })

    const { enqueueSnackbar } = useSnackbar();

    const initialValues = {
        first_name: '',
        last_name: '',
        email: '',
        business_name: '',
        country_code: 1,
        country_code_initials: 'CA',
        mobile_no: '',
    }

    const validationSchema = yup.object().shape({
        first_name: yup.string().required('First name is required'),
        // last_name: yup.string().required('Last name is required'),
        email: yup
            .string()
            .email('Enter a valid email')
            .matches(ConstantsHelper.emailRegex, 'Enter valid email')
            .required('Email is required'),
        business_name: yup
            .string()
            .trim()
            .required(ConstantsHelper.reqBusNT)
            .matches(ConstantsHelper.locationNameRegex, ConstantsHelper.vBusNT),
        mobile_no: yup.string().trim()
            .min(13, 'Enter valid phone number')
            .max(16, 'Enter valid phone number')
            .matches(ConstantsHelper.mobileNoRegex, 'Contact must be 10 digits')
            .test("test-mobNo", 'Enter valid phone number', (val, context) => {
                if (val && val !== null && val !== undefined) {
                    const moNo: String = val ?? "";
                    const onlyNums = moNo.replace(/[^0-9]/g, "");
                    return ConstantsHelper.mobileNRegex.test(onlyNums);
                } else return false;
            })
            .required('Phone number is required'),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {

            const passData: any = {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values?.email,
                business_name: values?.business_name,
                country_code: Number(values.country_code),
                mobile_no: values?.mobile_no.replace(/\D/g, ''),
                country_code_initials: values.country_code_initials,
            };
            const formData = new FormData();
            for (const key in passData) {
                if (passData.hasOwnProperty(key)) {
                    formData.append(key, passData[key]);
                }
            }

            const saveSettings = async (formData: any) => {
                const response = await fetch(`${urlsHelper?.salesUrl}api/waitlistInquiry`, {
                    method: 'POST',
                    body: formData
                });
                if (!response.ok) {
                    return { status: false, message: 'Somthing went wrong!'}
                    // throw new Error('Network response was not ok ' + response.statusText);
                }

                return await response.json();
            };
            setLoading(true)
            const response = await saveSettings(formData);
            setLoading(false)
            if (response?.status) {
                // enqueueSnackbar(response?.message, {
                //     variant: "success",
                //   });
                setActiveStep(2)
                setTimeout(() => {
                    handleClose()
                }, 5000)
            } else {
                enqueueSnackbar(response?.message, {
                    variant: "error",
                  });
            }
        }
    })

    const handleClose = () => {
        setOpenGetFreeMenu(false);
        setActiveStep(1)
        formik.resetForm()
    };

    const Avatars = [
        staticIcons.Avatar1,
        staticIcons.Avatar2,
        staticIcons.Avatar3,
        staticIcons.Avatar4,
    ]

    return (
        <Dialog
            fullWidth
            maxWidth={'lg'}
            open={openGetFreeMenu}
            onClose={handleClose}
            PaperProps={{
                sx: dialogStyle.dlgMain,
            }}
        >
            <DialogContent sx={{ p: 0, height: '88vh' }}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={[dialogStyle.dlgIconBtn, { zIndex: 999}]}
                >
                    <Box
                        component={'img'}
                        src={staticIcons.CloseRound}
                    />
                </IconButton>
                <Grid container sx={{ height: '100%' }}>
                    <Grid item xs={12} md={6} sx={{ height: { xs: 'max-content', md: '100%' } }}>
                        <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
                            <Box component={'img'} src={staticIcons.GetFreeDlgBg} sx={{ display: { xs: 'none', md: 'flex' }, position: 'absolute', bottom: 0, left: 0 }} alt="" />
                            <Box sx={{ width: '100%', height: '100%', px: { xs: '1rem', sm: '1.5rem' }, py: { xs: '1rem', md: '1.75rem' }, }}>
                                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'start', md: 'center' } }}>
                                    <Box sx={{ display: 'flex', img: { ml: { xs: '-8px', md: '-16px' }, width: { xs: '2.5rem', md: '3.25rem' } } }}>
                                        {Avatars.map((item, index) =>
                                            <img style={{ ...(index === 0 && { marginLeft: 0 }) }} key={index} src={item} alt="" />
                                        )}
                                    </Box>
                                    <Typography sx={[dialogStyle.descTxt, {
                                        color: '#000',
                                        fontSize: { xs: "12px", md: "16px" },
                                        span: {
                                            fontSize: { xs: "16px", md: "18px" },
                                        },
                                        maxWidth: { xs: "max-content", sm: '8rem', md: "9rem" },
                                        mt: { xs: "0.5rem", md: "0rem" },
                                        ml: { xs: "0rem", md: "1rem" },
                                    }]}>
                                        <span style={{ fontWeight: 600 }}>480+</span> people already joined
                                    </Typography>

                                </Box>
                                <Typography sx={dialogStyle.titleTxt}>
                                    Join Our Waitlist!
                                </Typography>
                                <Typography sx={dialogStyle.dlgDescTxt}>
                                    Get early access to our free digital solution for restaurants—manage menus, promote offers in the marketplace, and engage customers with instant notifications.
                                </Typography>
                                <Box sx={{ width: '100%', mt: '1.5rem', minHeight: '12rem', height: 'calc(100% - 14rem)', display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems: 'center' }}>
                                    <Box
                                        component={'img'}
                                        src={staticIcons.GetFreeMenuDialogImg}
                                        sx={{ height: '90%' }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ height: { xs: 'max-content', md: '100%' }, overflow: 'auto', background: '#FFFAF3', display: 'flex', justifyContent: 'center' }}>

                        {activeStep === 1 &&
                            <Box sx={{ width: '100%', maxWidth: '28rem', height: '100%', py: { xs: '1rem', md: '1.75rem' }, px: { xs: '1rem', sm: '1.5rem' }, }}>
                                <Box
                                    component={'img'}
                                    src={staticIcons.User1}
                                    sx={{ width: { xs: '2rem', md: '3rem' }, mt: { xs: 0, md: '1rem' }, display: { xs: 'none', md: 'flex' }, }}
                                />
                                <Typography sx={dialogStyle.formText}>
                                    Get Started with your FREE Digital Menu!
                                </Typography>
                                <Typography sx={dialogStyle.dlgDescTxt}>
                                    Start your growth journey now.
                                </Typography>
                                <React.Suspense fallback={<></>}>
                                    <Grid container spacing={{ xs: 0, md: 1 }}>
                                        <Grid item xs={12} md={6}>
                                            <Typography sx={dialogStyle.fieldTitle}>First Name *</Typography>
                                            <TextField
                                                fullWidth
                                                placeholder='Enter your first name'
                                                sx={dialogStyle.textField}
                                                id={'first_name'}
                                                name='first_name'
                                                value={formik.values.first_name}
                                                onChange={(e: any) => {
                                                    e.currentTarget.value = e.currentTarget.value.replace(
                                                        /[^A-Za-z ]/g,
                                                        ""
                                                    );
                                                    formik.handleChange(e);
                                                }}
                                                error={
                                                    formik.touched.first_name &&
                                                    Boolean(formik.errors.first_name)
                                                }
                                                helperText={
                                                    formik.touched.first_name &&
                                                    formik.errors.first_name
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography sx={dialogStyle.fieldTitle}>Last Name</Typography>
                                            <TextField
                                                fullWidth
                                                placeholder='Enter your last name'
                                                sx={dialogStyle.textField}
                                                id={'last_name'}
                                                name='last_name'
                                                value={formik.values.last_name}
                                                onChange={(e: any) => {
                                                    e.currentTarget.value = e.currentTarget.value.replace(
                                                        /[^A-Za-z ]/g,
                                                        ""
                                                    );
                                                    formik.handleChange(e);
                                                }}
                                                error={
                                                    formik.touched.last_name &&
                                                    Boolean(formik.errors.last_name)
                                                }
                                                helperText={
                                                    formik.touched.last_name &&
                                                    formik.errors.last_name
                                                }
                                            />
                                        </Grid>
                                    </Grid>


                                    <Typography sx={dialogStyle.fieldTitle}>Email *</Typography>
                                    <TextField
                                        fullWidth
                                        placeholder='Enter your email'
                                        sx={dialogStyle.textField}
                                        id={'email'}
                                        name='email'
                                        value={formik.values.email}
                                        onChange={(e: any) => {
                                            if (e.currentTarget.value.includes(" ")) {
                                                e.currentTarget.value = e.currentTarget.value.replace(
                                                    /\s/g,
                                                    ""
                                                );
                                            }
                                            formik.handleChange(e);
                                        }}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                    <Typography sx={dialogStyle.fieldTitle}>Business Name *</Typography>
                                    <TextField
                                        fullWidth
                                        placeholder='Enter your business name'
                                        sx={dialogStyle.textField}
                                        id={'business_name'}
                                        name='business_name'
                                        value={formik.values.business_name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.business_name && Boolean(formik.errors.business_name)}
                                        helperText={formik.touched.business_name && formik.errors.business_name}
                                    />
                                    <Typography sx={dialogStyle.fieldTitle}>Mobile Number *</Typography>
                                    <CustomMobileInput
                                        formSx={{
                                            mb: 4
                                        }}
                                        fullWidth
                                        id={'mobile_no'}
                                        name='mobile_no'
                                        placeholder={'Enter phone number'}
                                        type='text'
                                        value={formik.values.mobile_no}
                                        onChange={(e: any) => {
                                            formik.handleChange(e);
                                        }}
                                        error={formik.touched.mobile_no && Boolean(formik.errors.mobile_no)}
                                        helperText={formik.touched.mobile_no && formik.errors.mobile_no}
                                        stdcode={stdCode}
                                        stdCodeChange={(val: { value: string; label: string; key: string, image: string }) => {
                                            formik.setFieldValue('country_code', val?.value)
                                            formik.setFieldValue('country_code_initials', val?.key)
                                            setStdCode(val)
                                        }}
                                        sx={{
                                            background: 'white',
                                            borderRadius: '0.625rem',
                                            borderColor: "#D0D5DD !important",
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#D0D5DD',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#D0D5DD',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#D0D5DD',
                                                borderWidth: 2,
                                            },
                                            input: {
                                                padding: { xs: "0.5rem 0.75rem", md: "0.75rem 1rem" },
                                            },
                                        }}
                                    />
                                </React.Suspense>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: '1rem', width: '100%' }}>
                                    <LoadingButton
                                        disabled={loading}
                                        loading={loading}
                                        sx={[dialogStyle.orangeBtn, { mb: '2rem' }]}
                                        onClick={() => {
                                            formik.handleSubmit()
                                        }}
                                    >Get My Free Menu</LoadingButton>
                                </Box>
                            </Box>
                        }
                        {activeStep === 2 && <Box sx={{ width: '100%', height: '100%', py: { xs: '1rem', md: '1.75rem' }, px: { xs: '1rem', sm: '1.5rem' }, }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: { xs: '0rem', md: '2rem' } }}>
                                <Box component={'img'} src={staticIcons.FreeMenuFormSubmit} alt="" sx={{ height: { xs: '2rem', md: '3rem' }, mr: '1rem' }} />
                                <Typography sx={dialogStyle.formText}>
                                    Congratulations! You’re in our waitlist—thanks for signing up!
                                </Typography>
                            </Box>
                            <Box component={'img'} src={staticIcons.FormSubmit} alt="" sx={{}} />
                        </Box>}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default CustomDialog