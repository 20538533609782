// Created By Ayush.N

// Helper Functions
import ColorsHelper from "../../../Helper/Colors";
import FontsHelper from "../../../Helper/Fonts";
import CommanStyles from "../../../Helper/CommanStyles";

const mvStyles = {
  mvContainer: {
    p: { xs: "0 0.8rem", sm: "0 1.3rem" },
    height: "calc(100vh - 7rem)",
    marginTop: "1.3rem",
    overflow: "auto",
  },
  mvItemsBx: {
    ...CommanStyles.dflex,
    minHeight: { xs: "7.188rem", lg: "8rem" },
    border: `0.0625rem solid ${ColorsHelper.blackClr}`,
    borderRadius: "1rem",
    margin: "auto",
    backgroundColor: ColorsHelper.bgWhiteClr,
    position: "relative",
    zIndex: "100",
  },
  mvItemsHoverBx: {
    backgroundColor: ColorsHelper.blackClr,
    color: ColorsHelper.whiteClr,
    ...CommanStyles.flexRowsBetween,
    width: "100%",
    margin: "auto",
    height: "2.8rem",
    position: "relative",
    top: "-0.6rem",
    px: "1rem",
    paddingTop: "0.5rem",
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
  },
  mvItemsTitleMBx: {
    ...CommanStyles.flexColunmContainer,
    justifyContent: "center",
    width: "100%",
    paddingLeft: "1.5rem",
    paddingRight: "1rem",
  },
  mvItemsTitleBx: {
    ...CommanStyles.flexRowsBetween,
    width: "100%",
  },
  mvNameTF: {
    marginTop: "0rem",
    marginBottom: "0rem",
  },
  mvItemTitle: {
    ...FontsHelper.font500,
    fontSize: "1.3rem",
    color: ColorsHelper.blackClr,
    width: "100%",
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  mvSTblBtn: {
    width: "4rem",
    padding: "0.875rem 0.1rem",
    marginLeft: "0.2rem",
    marginRight: "1.5rem",
    textTransform: "capitalize",
    borderRadius: "0.625rem",
    ...FontsHelper.font500,
    fontSize: "1rem",
    color: ColorsHelper.whiteClr,
    backgroundColor: ColorsHelper.blackClr,
    "&:hover": {
      backgroundColor: ColorsHelper.blackClr,
    },
  },
  mSpBtn: {
    backgroundColor: `${ColorsHelper.white7} !important`,
  },
  mWSpBtn: {
    backgroundColor: ColorsHelper.blackClr,
  },
  mvBlackBx: {
    ...CommanStyles.dflex,
    width: "100%",
    minHeight: { xs: "7.188rem", lg: "8rem" },
    border: `0.0625rem solid ${ColorsHelper.blackClr}`,
    borderRadius: "1rem",
    margin: "auto",
    backgroundColor: ColorsHelper.blackClr,
    cursor: "pointer",
  },
  mvNMenuTx: {
    ...FontsHelper.font500,
    fontSize: "1.3rem",
    color: ColorsHelper.whiteClr,
    marginLeft: "1rem",
  },
  mvPlusBtnBx: {
    backgroundColor: ColorsHelper.whiteClr,
    width: "2.4rem",
    borderRadius: "0.3rem",
    marginRight: "2rem",
  },
  mvPlusBtnImg: {
    width: "1.4rem",
    minWidth: "1.4rem",
    height: "1.4rem",
    minHeight: "1.4rem",
  },
  mvBtnComponent: {
    color: ColorsHelper.whiteClr,
    ...FontsHelper.font500,
    textTransform: "capitalize",
    padding: "0rem",
  },

  // Cust Form Styles
  mainBox: {
    maxWidth: "34rem",
    mx: "auto",
    position: "relative",
    cursor: "default",
    height: "100%",
  },
  paper: {
    borderRadius: "0.9375rem",
    maxWidth: "20rem",
    position: "relative",
  },
  dialogTitle: { display: "flex", justifyContent: "space-between" },
  dialogContent: { ...CommanStyles.centerIt, flexDirection: "column" },
  text: {
    textAlign: "center",
    color: ColorsHelper.blackClr,
    ...FontsHelper.font500,
    fontSize: { xs: "1rem", sm: "1.2rem", md: "1rem" },
  },
  giftCard: { width: "auto", height: "auto" },
  btnBox: { display: "flex", gap: 3 },
  skipBtn: {
    textTransform: "capitalize",
    width: "6rem",
    borderRadius: "0.5rem",
    fontSize: "0.9rem",
    color: ColorsHelper.blackClr,
    border: `0.0625rem solid ${ColorsHelper.grey14} !important`,
    fontWeight: FontsHelper.font600,
  },
  giftBtn: {
    width: "9rem",
    borderRadius: "0.5rem",
    fontSize: "0.8rem",
    color: ColorsHelper.whiteClr,
    bgcolor: ColorsHelper.blackClr,
    "&:hover": {
      bgcolor: ColorsHelper.blackClr,
    },
    fontWeight: FontsHelper.font500,
    textTransform: "capitalize",
  },
  collectTxt: { fontSize: "1rem", fontWeight: FontsHelper.font700 },
  content: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    p: "1rem",
  },
  textField: { mt: 0, ".MuiInputBase-root": { height: "2.8125rem" } },
  submitBtn: {
    padding: "0.425rem 0.8rem",
    textTransform: "capitalize",
    width: "6rem",
    borderRadius: "0.5rem",
    fontSize: "0.9rem",
    color: ColorsHelper.whiteClr,
    bgcolor: ColorsHelper.blackClr,
    "&:hover": {
      bgcolor: ColorsHelper.blackClr,
    },
    fontFamily: FontsHelper.TitleFontFamily,
  },
  paperSx: {
    borderRadius: "0.9375rem",
    width: "23rem",
    height: "26rem",
  },
  msgBox: {
    flexDirection: "column",
    ...CommanStyles.centerIt,
    width: "100%",
    py: "1rem",
  },
  congImg: { height: "17.5rem", width: "20rem" },
  congTxt: {
    textAlign: "center",
    mt: 2,
    px: { xs: "2rem", sm: "3rem" },
    fontSize: "0.8rem",
    fontFamily: FontsHelper.TitleFontFamily,
  },

  // Information Settings Styles
  paperStyle: { borderRadius: "0.9375rem", width: "38rem", height: "37rem" },
  infoTxt: { fontSize: "1rem", fontWeight: FontsHelper.font700 },
  contentStyle: { ...CommanStyles.centerIt },
  infoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    mx: "5rem",
  },
  additionalTxt: {
    color: ColorsHelper.blackClr,
    ...FontsHelper.font800,
    fontSize: { xs: "0.92rem", sm: "1.1rem" },
    mb: 1,
  },
  container: { mt: "1rem", alignItems: "center", width: "100%" },
  cancelBtn: {
    padding: "0.425rem 0.8rem",
    textTransform: "capitalize",
    width: "6rem",
    borderRadius: "0.5rem",
    fontSize: "0.9rem",
    color: ColorsHelper.blackClr,
    bgcolor: ColorsHelper.whiteClr,
    fontFamily: FontsHelper.TitleFontFamily,
    "&:hover": {
      bgcolor: ColorsHelper.whiteClr,
    },
  },
  qrPaper: {
    borderRadius: "0.9375rem",
    width: "28rem",
    height: { xs: "33rem", sm: "30rem" },
  },
  qrBox: {
    justifyContent: "space-between",
    ...CommanStyles.flexAlignContainer,
    width: "100%",
  },
  qrContainer: {
    ...CommanStyles.centerIt,
    p: "2rem",
    width: "100%",
    border: `0.0625rem solid ${ColorsHelper.grey10}`,
    borderRadius: 2,
    mt: "2rem",
    mb: "2rem",
  },
  borderBox: { height: "10.625rem", width: "10rem" },
  previewBox: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    gap: 1,
    alignItems: "end",
  },
  previewBtn: {
    padding: "0.425rem 0.8rem",
    textTransform: "capitalize",
    width: "6.5rem",
    borderRadius: "0.5rem",
    fontSize: "0.9rem",
    color: ColorsHelper.blackClr,
    border: `0.0625rem solid ${ColorsHelper.blackClr} !important`,
    height: "2.6rem",
    mt: "0.3rem",
    mb: "0.425rem",
    fontWeight: FontsHelper.font600,
  },
  qrTxt: { color: ColorsHelper.blackClr, ...FontsHelper.font600 },
};

export default mvStyles;
