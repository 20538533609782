// Created By ND
import { makeVar, InMemoryCache } from "@apollo/client";

export const sbLocObj = makeVar<{
  location_id: number | null;
  location_name: string | null;
}>({ location_id: null, location_name: null });

export const sbLocCount = makeVar<{
  count: number;
  locData: object | null
}>({ count: 0, locData: null });

export const sideBarMenuVar = makeVar<{
  sidebar_menu: object | null;
}>({ sidebar_menu: null });

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        sbLocObjData: {
          read() {
            return sbLocObj();
          },
        },
        sbLocCountData: {
          read() {
            return sbLocCount();
          },
        },
        sideBarData: {
          read() {
            return sideBarMenuVar();
          },
        },
        //   paymentHistory: {
        //     // Don't cache separate results based on
        //     // any of this field's arguments.
        //     keyArgs: false,
        //     // Concatenate the incoming list items with
        //     // the existing list items.
        //     merge(existing = [], incoming) {
        //         return [...existing, ...incoming.data];
        //     },
        // }
      },
    },
  },
});